import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getEmployeeById } from "../../../store/actions/emp-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import moment from "moment";
import { Link } from "react-router-dom";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";

class ViewEmployee extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        status: "",
        dateCreated: "",
        role: "",
        language: "",
      },
      success: false,
    };
  }
  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    this.props.checkUserPermission("3");

    var post = this.props.employeesState.data.filter(
      (x) => x.Id === this.props.match.params.id
    );
    if (post.length !== 0 && this.props.employeesState.success) {
      this.setState({
        ...this.state,
        data: {
          ...post[0],
          language: post[0].language
            ? post[0].language.split(",").join(", ")
            : "",
        },
        success: true,
      });
    } else {
      this.props.getEmployeeById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (this.props.employeeState !== prevProps.employeeState) {
      if (
        this.props.employeeState.success &&
        this.props.employeeState.status === 1
      ) {
        this.setState({
          ...this.state,
          data: {
            ...this.props.employeeState.data[0],
            language: this.props.employeeState.data[0].language
              ? this.props.employeeState.data[0].language.split(",").join(", ")
              : "",
          },
          success: true,
        });
      } else if (this.props.employeeState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getEmployeeById(this.props.match.params.id);
      } else {
        // window.localStorage.removeItem("login");
        // this.props.history.push("/");
        // this.props.setStatus(false);
      }
    }
  }

  render() {
    const {
      username,
      firstName,
      lastName,
      email,
      phone,
      status,
      dateCreated,
      role,
      language,
    } = this.state.data;
    return (
      <Fragment>
        {!this.state.success ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-sm-12 mb-3 mb-sm-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/",
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/employees/all",
                      }}
                    >
                      Employees
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active p-0"
                    aria-current="page"
                  >
                    View Employee
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-12 mb-3">
              <h3>View Employee</h3>
            </div>

            <div className="col-md-6">
              <p className="text-wrap">
                <b>First Name: </b>
                {firstName}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Last Name: </b>
                {lastName}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Username: </b>
                {username}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Email: </b>
                {email}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Phone Number: </b>
                {phone}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Date Created: </b>
                {moment(dateCreated).format("DD-MM-YYYY")}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Status: </b>
                {status === "0" ? "Inactive" : "Active"}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>User Role: </b>
                {role === "Super Admin"
                  ? "Super Admin"
                  : role === "Admin"
                  ? "Admin"
                  : role === "Editor"
                  ? "Editor"
                  : role === "Fact Check"
                  ? "Fact Check"
                  : null}
              </p>
            </div>
            {role === "Editor" && (
              <div className="col-md-6">
                <p className="text-wrap">
                  <b>Languages: </b>
                  {language}
                </p>
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employeesState: state.employeeStore.employees,
    employeeState: state.employeeStore.employee,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEmployeeById: (data) => dispatch(getEmployeeById(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewEmployee)
);
