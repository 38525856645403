import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getAllUsers,
  deleteUser,
} from "../../store/actions/users-state-actions";
import { refreshToken } from "../../store/actions/app-state-actions";
import {
  editUser,
  manageUserStatus,
} from "./../../store/actions/users-state-actions";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import moment from "moment";
import { checkUserPermission } from "../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";

class ViewAllUsers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dateFrom: "",
        dateTo: "",
      },
      userList: [],
      success: false,
      open: false,
      id: 0,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      isLoading: true,
      pageSize: 10,
      responseMessage: "",
      showResponse: false,

      statusDetails: {
        row: {},
        userId: 0,
        status: 0,
      },
      modalLoading: false,
      modalSuccess: false,
      modalError: false,
      modalMessage: "",
      openModal: false,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("8");
    if (
      this.props.usersState.success &&
      this.props.usersState.data.length > 0
    ) {
      this.setState({
        ...this.state,
        userList: this.props.usersState.data,
        success: true,
        isLoading: false,
      });
    } else {
      this.props.getAllUsers(0, 10000);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (this.props.usersState !== prevProps.usersState) {
      if (this.props.usersState.success && this.props.usersState.token === 1) {
        // if (this.props.usersState.status) {
        //   this.props.getAllUsers(this.props.usersState.data.length, 50);
        // }
        this.setState({
          ...this.state,
          userList: this.props.usersState.data,
          success: true,
          isLoading: false,
        });
      } else if (this.props.usersState.token === -1) {
        this.props.refreshToken();
      }
    }

    if (this.props.deleteState !== prevProps.deleteState) {
      if (
        this.props.deleteState.success &&
        this.props.deleteState.status === 1
      ) {
        this.setState({
          ...this.state,
          success: true,
          isLoading: true,
        });
        this.showSuccess("User has been deleted successfully.");

        this.props.getAllUsers(0, 10000);
      } else if (this.props.deleteState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.deleteState.isError) {
        this.showError("Failed to delete selected user!");
      }
    }

    if (this.props.editState !== prevProps.editState) {
      if (this.props.editState.success && this.props.editState.status === 1) {
        this.showSuccess("User access has been updated successfully.");
      } else if (this.props.editState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.editState.isError) {
        this.showError("Failed to update user access status!");
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success && this.props.appState.status === 1) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );

        if (this.props.usersState.token === -1) {
          this.props.getAllUsers(0, 10000);
        }

        if (this.props.deleteState.status === -1) {
          this.handleDelete();
        }
      } else if (
        this.props.appState.success &&
        this.props.appState.status === -1
      ) {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleDelete = () => {
    this.setState({ ...this.state, modalLoading: true }, () => {
      var obj = {
        id: this.state.id,
      };
      this.props.deleteUser(obj);
    });
  };

  handleEditRedirect = (id) => {
    this.props.history.push(`/users/e/` + id);
  };

  handleRedirect = (id) => {
    this.props.history.push(`/users/` + id);
  };

  handlePageSize = (value) => {
    this.setState({ ...this.state, pageSize: value });
  };

  getRows = () => {
    var arr =
      this.state.success &&
      this.state.userList &&
      this.state.userList.map((item, index) => {
        item.id = index + 1;
        return item;
      });
    return arr;
  };

  handleCloseModal = () => {
    this.setState({
      ...this.state,
      open: false,
      openModal: false,
      statusDetails: {
        userId: 0,
        status: 0,
      },
    });
  };

  handleOpenModal = (id) => {
    this.setState({ ...this.state, open: true, id: id });
  };

  showSuccess(msg) {
    this.setState(
      {
        ...this.state,
        validMsg: msg,
        isValid: true,
        spinner: false,
        modalLoading: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          this.handleCloseModal();
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
        modalLoading: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  }

  filterFirstName = (item) => {
    if (!item.FirstName) {
      return "";
    }
    if (this.state.data.firstName !== "0" && this.state.data.firstName !== "") {
      return item.FirstName.toLowerCase().includes(
        this.state.data.firstName.toLowerCase()
      );
    } else {
      return item;
    }
  };

  filterLastName = (item) => {
    if (!item.LastName) {
      return "";
    }
    if (this.state.data.lastName !== "0" && this.state.data.lastName !== "") {
      return item.LastName.toLowerCase().includes(
        this.state.data.lastName.toLowerCase()
      );
    } else {
      return item;
    }
  };

  filterEmail = (item) => {
    if (this.state.data.email !== "0" && this.state.data.email !== "") {
      return item.Email.toLowerCase().includes(
        this.state.data.email.toLowerCase()
      );
    } else {
      return item;
    }
  };

  filterPhone = (item) => {
    if (this.state.data.phone !== "0" && this.state.data.phone !== "") {
      return item.Phone.toLowerCase().includes(
        this.state.data.phone.toLowerCase()
      );
    } else {
      return item;
    }
  };

  filterDate = (item) => {
    if (this.state.data.dateFrom !== "" && this.state.data.dateTo !== "") {
      return (
        moment(item.DateCreated).utc().format("YYYY-MM-DD") >=
          moment(this.state.data.dateFrom).utc().format("YYYY-MM-DD") &&
        moment(item.DateCreated).utc().format("YYYY-MM-DD") <=
          moment(this.state.data.dateTo).utc().format("YYYY-MM-DD")
      );
    } else {
      return item;
    }
  };

  handleReset = () => {
    this.setState({
      data: {
        ...this.state.data,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dateFrom: "",
        dateTo: "",
      },
      userList: this.props.usersState.data,
      search: false,
    });
  };

  checkDateRange = () => {
    let today = new Date();
    if (
      this.state.data.dateFrom.length !== 0 &&
      this.state.data.dateTo.length !== 0
    ) {
      if (
        moment(this.state.data.dateTo).utc().format("YYYY-MM-DD") <
        moment(this.state.data.dateFrom).utc().format("YYYY-MM-DD")
      ) {
        return false;
      }
    }
    return true;
  };

  checkDates = () => {
    if (this.state.data.dateFrom === "" && this.state.data.dateTo !== "") {
      return false;
    }

    if (this.state.data.dateFrom !== "" && this.state.data.dateTo === "") {
      return false;
    }

    return true;
  };

  handleSubmit = () => {
    if (this.checkSearchParameters()) {
      if (!this.checkDates()) {
        this.setState(
          {
            responseMessage:
              "You need to insert value in both (Date From) and (Date To) fields to search records by date range.",
            showResponse: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                responseMessage: "",
                showResponse: false,
              });
            }, 2500);
          }
        );
        return false;
      }
      if (!this.checkDateRange()) {
        this.setState(
          {
            responseMessage:
              "(Date From) value cannot be greater than (Date To) value.",
            showResponse: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                responseMessage: "",
                showResponse: false,
              });
            }, 2500);
          }
        );
        return false;
      }

      var items = this.props.usersState.data
        .filter(this.filterFirstName)
        .filter(this.filterLastName)
        .filter(this.filterEmail)
        .filter(this.filterPhone)
        .filter(this.filterDate);

      this.setState({ ...this.state, userList: items });
    }
  };

  checkSearchParameters() {
    for (var key in this.state.data) {
      if (
        this.state.data[key] !== "" &&
        this.state.data[key] !== "0" &&
        this.state.data[key] !== null
      ) {
        return true;
      }
    }
    return false;
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [id]: value,
      },
    });
  };

  handleUpdate = (user) => {
    const newUser = { ...user };
    if (newUser.Status === "1") {
      newUser.Status = "0";
    } else {
      newUser.Status = "1";
    }

    const userList = [...this.state.userList];
    const index = this.state.userList.indexOf(user);
    userList[index] = newUser;
    this.setState({ userList });

    // this.props.editUser({
    //   dateCreated: newUser.DateCreated,
    //   email: newUser.Email,
    //   firstName: newUser.FirstName,
    //   lastName: newUser.LastName,
    //   phone: newUser.Phone,
    //   status: newUser.Status,
    //   userId: newUser.UserId,
    //   username: "",
    //   id: newUser.id,
    // });
  };

  banOrUnbanUser = () => {
    this.setState({
      ...this.state,
      modalLoading: true,
    });

    var formData = {
      userId: this.state.statusDetails.userId,
      status: this.state.statusDetails.status == "0" ? 1 : 0,
    };

    this.props.manageUser(formData).then(() => {
      if (this.props.manageState.success) {
        this.setState({
          ...this.state,
          modalLoading: false,
          modalSuccess: true,
          modalError: false,
          modalMessage:
            this.state.statusDetails.status == "0"
              ? "User unbanned successfully."
              : "User banned successfully.",
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            modalLoading: false,
            modalSuccess: false,
            modalError: false,
            modalMessage: "",
            openModal: false,
          });

          this.handleUpdate(this.state.statusDetails.row);
        }, 2500);
      } else {
        this.setState({
          ...this.state,
          modalLoading: false,
          modalSuccess: false,
          modalError: true,
          modalMessage:
            this.state.statusDetails.status == "0"
              ? "Failde to unbanned user."
              : "Failde to banned user.",
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            modalLoading: false,
            modalSuccess: false,
            modalError: false,
            modalMessage: "",
          });
        }, 2500);
      }
    });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 80 },
      {
        field: "FirstName",
        headerName: "First Name",
        width: 150,
        sortable: true,
      },
      {
        field: "LastName",
        headerName: "Last Name",
        width: 150,
        sortable: true,
      },
      {
        field: "Email",
        headerName: "Email",
        sortable: true,
        width: 180,
      },
      {
        field: "Phone",
        headerName: "Phone",
        sortable: true,
        width: 110,
      },
      {
        field: "DateCreated",
        headerName: "Date Created",
        width: 145,
        sortable: true,
        valueFormatter: (params) => {
          return moment(params.row.DateCreated).format("DD-MM-YYYY");
        },
      },
      {
        field: "action",
        headerName: "Action",
        sortable: true,
        width: 350,

        renderCell: (params) => (
          <Fragment>
            {params.row.Status === "1" ? (
              <button
                type="button"
                className="btn btn-warning mr-2 btn-block"
                onClick={() =>
                  this.setState({
                    ...this.state,
                    openModal: true,
                    statusDetails: {
                      row: params.row,
                      userId: params.row.UserId,
                      status: params.row.Status,
                    },
                  })
                }
              >
                Ban
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary mr-2 btn-block"
                onClick={() =>
                  this.setState({
                    ...this.state,
                    openModal: true,
                    statusDetails: {
                      row: params.row,
                      userId: params.row.UserId,
                      status: params.row.Status,
                    },
                  })
                }
              >
                Unban
              </button>
            )}

            <button
              type="button"
              className="btn btn-dark mr-2"
              onClick={(e) => this.handleRedirect(params.row.UserId)}
            >
              View
            </button>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={(e) => this.handleEditRedirect(params.row.UserId)}
            >
              Update
            </button>
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleOpenModal(params.row.UserId)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
      },
    ];
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Users
                </li>
              </ol>
            </nav>
          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  value={this.state.data.firstName}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  placeholder="First Name"
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  value={this.state.data.lastName}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  placeholder="Last Name"
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={this.state.data.email}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  placeholder="Email"
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={this.state.data.phone}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  placeholder="Phone"
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="title">Date From</label>
                <input
                  type="date"
                  className="form-control"
                  id="dateFrom"
                  value={this.state.data.dateFrom}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="title">Date To</label>
                <input
                  type="date"
                  className="form-control"
                  id="dateTo"
                  value={this.state.data.dateTo}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>
              <div className="col-12 mt-3 flex-row d-flex justify-content-end">
                <button
                  type="button"
                  className="btn primary_btn mr-2"
                  onClick={() => this.handleSubmit()}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => this.handleReset()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 d-flex justify-content-center">
            <div className="mt-3 text-center">
              {this.state.showResponse ? (
                <div className="alert alert-danger mt-3 mb-3" role="alert">
                  {this.state.responseMessage}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12 mt-4 mb-4">
            <h3>View All Users</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.state.spinner ? (
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div style={{ width: "100%" }}>
                    {this.state.isLoading ? (
                      <div className="row d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                          style={{ width: "3rem", height: "3rem" }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <DataGrid
                        rows={this.getRows() || []}
                        columns={columns}
                        disableColumnMenu
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(newPageSize) =>
                          this.handlePageSize(newPageSize)
                        }
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        disableSelectionOnClick={true}
                        autoHeight
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.openModal}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h4>
                      Are you sure that you want to{" "}
                      {this.state.statusDetails.status == "1" ? "ban" : "unban"}{" "}
                      this user?
                    </h4>
                  </div>
                  <div className="col-12 my-2">
                    {this.state.modalLoading ? (
                      <div className="row my-3">
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.modalError ? (
                      <div
                        className="alert alert-danger w-100 text-center"
                        role="alert"
                      >
                        {this.state.modalMessage}
                      </div>
                    ) : null}
                    {this.state.modalSuccess ? (
                      <div
                        className="alert alert-success w-100 text-center"
                        role="alert"
                      >
                        {this.state.modalMessage}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 d-flex justify-content-end my-2">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.banOrUnbanUser()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleCloseModal()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h4>Are you sure that you want to delete this user?</h4>
                  </div>
                  <div className="col-12 my-2">
                    {this.state.modalLoading ? (
                      <div className="row my-3">
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.isError ? (
                      <div
                        className="alert alert-danger w-100 text-center"
                        role="alert"
                      >
                        {this.state.errorMsg}
                      </div>
                    ) : null}
                    {this.state.isValid ? (
                      <div
                        className="alert alert-success w-100 text-center"
                        role="alert"
                      >
                        {this.state.validMsg}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 d-flex justify-content-end my-2">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleDelete()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleCloseModal()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.appStore.refreshToken,
    deleteState: state.usersStore.deleteUser,
    usersState: state.usersStore.users,
    editState: state.usersStore.editUser,
    manageState: state.usersStore.userStatus,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  manageUser: (data) => dispatch(manageUserStatus(data)),
  getAllUsers: (from, to) => dispatch(getAllUsers(from, to)),
  deleteUser: (id) => dispatch(deleteUser(id)),
  editUser: (data) => dispatch(editUser(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewAllUsers)
);
