const checkMinLength = (value, length) => value.length === length;

// const checkMaxLength = (value, length) => value.length > length;

// const validEmailRegex = RegExp(
//   /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
// );
// const checkNumber = RegExp("[0-9]");

var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const isErrorObjectEmpty = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => {
    if (val.length > 0) {
      valid = false;
    }
  });
  return valid;
};

export const validateInputs = (id, value, _errors) => {
  let errors = _errors;
  if (id === "title") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Title cannot be empty!";
    } 
    // else if (format.test(value)) {
    //   errors[id] = "Title cannot contain any special characters!";
    // }
     else {
      errors[id] = "";
    }
  }

  if (id === "category") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Category cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  // if (id === "author") {
  //   if (checkMinLength(value, 0)) {
  //     errors[id] = "Author cannot be empty!";
  //   } else {
  //     errors[id] = "";
  //   }
  // }

  if (id === "contents") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Article contents cannot be empty!";
    } else if (value.includes("<p></p>")) {
      errors[id] = "Article contents cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "thumbnail") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Image required for the article!";
    } else {
      errors[id] = "";
    }
  }

  // Edit
  if (id === "Title") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Title cannot be empty!";
    }
    //  else if (format.test(value)) {
    //   errors[id] = "Title cannot contain any special characters!";
    // }
     else {
      errors[id] = "";
    }
  }

  if (id === "Category") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Category cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  // if (id === "Author") {
  //   if (checkMinLength(value, 0)) {
  //     errors[id] = "Author cannot be empty!";
  //   } else {
  //     errors[id] = "";
  //   }
  // }

  if (id === "Article") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Article contents cannot be empty!";
    } else if (value === "<p></p>") {
      errors[id] = "Article contents cannot be empty!";
    } else if (value === "<p></p>\n") {
      errors[id] = "Article contents cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Thumbnail") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Image required for the article!";
    } else {
      errors[id] = "";
    }
  }
  return errors;
};
