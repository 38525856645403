import React, { PureComponent, Fragment } from "react";
import "./index.css";

import Citizen from "../../img/logo02.png";
import {
  loginUser,
  initialPaswordChange,
  forgotPasswordChange,
  mailSend,
} from "../../store/actions/app-state-actions";

import { getAllUserPermissions } from "../../store/actions/users-state-actions";

import { connect } from "react-redux";
class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      validMsg: "",
      isValid: false,
      spinner: false,
      errorMsg: "",
      isError: false,
      isInitial: false,
      isLogin: true,
      isForgot: false,
      isPasswordChange: 0,
      initialChange: {
        userId: 0,
        newPassword: "",
      },
      userRoleId: 0,
      userPermissions: [],
      permissions: [],
    };

    this.saveInitialPassword = this.saveInitialPassword.bind(this);
    this.saveForgotPassword = this.saveForgotPassword.bind(this);
    this.sendVerificationMail = this.sendVerificationMail.bind(this);
  }

  componentDidMount() {
    this.props.getAllUserPermissions().then(() => {
      if (this.props.userPermissionState.success) {
        this.setState({
          ...this.state,
          userPermissions:
            this.props.userPermissionState.data.length !== 0
              ? this.props.userPermissionState.data
              : [],
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.loginState !== prevProps.loginState) {
      if (this.props.loginState.success && !this.props.loginState.isLoading) {
        window.localStorage.setItem(
          "userId",
          this.props.loginState.data.userId
        );

        this.setState(
          {
            ...this.state,
            isPasswordChange: parseInt(
              this.props.loginState.data.isPasswordChange
            ),
          },
          () => {
            this.showSuccess();
          }
        );
      } else if (
        this.props.loginState.isError &&
        !this.props.loginState.isLoading
      ) {
        this.setState({ ...this.state, spinner: false });
        this.showError(this.props.loginState.message);
      }
    }
  }

  saveSession = () => {
    window.localStorage.setItem(
      "accessToken",
      this.props.loginState.data.accessToken
    );
    window.localStorage.setItem(
      "refreshToken",
      this.props.loginState.data.refreshToken
    );
    window.localStorage.setItem("userId", this.props.loginState.data.userId);
    window.localStorage.setItem(
      "name",
      this.props.loginState.data.firstName +
        " " +
        this.props.loginState.data.lastName
    );
    window.localStorage.setItem("role", this.props.loginState.data.role);
    window.localStorage.setItem("email", this.props.loginState.data.email);
    window.localStorage.setItem(
      "languages",
      JSON.stringify(this.props.loginState.data.language)
    );

    var role = this.props.loginState.data.role;

    var roleId = 0;
    if (role === "Super Admin") {
      roleId = 1;
      localStorage.setItem("CIZUPRID", 1);
    }
    if (role === "Admin") {
      roleId = 2;
      localStorage.setItem("CIZUPRID", 2);
    }
    if (role === "Editor") {
      roleId = 3;
      localStorage.setItem("CIZUPRID", 3);
    }
    if (role === "Fact Check") {
      roleId = 4;
      localStorage.setItem("CIZUPRID", 4);
    }

    this.setPermissions(roleId);
  };

  setPermissions(role) {
    var result = {};

    if (role !== "SuperAdmin") {
      var rolePermissions = this.state.userPermissions.find(
        (item) => parseInt(item.role_id) === parseInt(role)
      );

      var permisionList =
        rolePermissions !== undefined
          ? rolePermissions.permission.split(",")
          : [];
      if (permisionList.length !== 0) {
        permisionList = permisionList.map((el) => {
          return el.trim();
        });
        localStorage.setItem("CIZUP", JSON.stringify(permisionList));
        result = { permissions: permisionList, isNull: false };
      }
      //  else {
      //   localStorage.removeItem("CIZUP");
      //   result = { permissions: [], isNull: true };
      // }
    } else {
      var arr = [];
      localStorage.setItem("CIZUP", JSON.stringify(arr));
      result = { permissions: arr, isNull: false };
    }

    this.setState({
      ...this.state,
      permissions: result.permissions,
      isNull: result.isNull,
    });

    // window.localStorage.setItem("CIZUP", JSON.stringify(result));

    // return result;
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  handleInitialChange = (e) => {
    const { value } = e.target;
    this.setState({
      ...this.state,
      initialChange: {
        ...this.state.initialChange,
        newPassword: value,
      },
    });
  };

  handleClick = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (this.state.email.length === 0 || this.state.password.length === 0) {
      this.showError("Required fields are empty!");
    } else if (!pattern.test(this.state.email)) {
      this.showError("Invalid email address!");
    } else {
      this.setState({ ...this.state, spinner: true });
      this.props.loginUser({
        email: this.state.email,
        password: this.state.password,
      });
    }
  };

  handleRegister = () => {
    this.props.history.push("/register");
  };

  showSuccess = () => {
    this.setState(
      {
        ...this.state,
        validMsg: "",
        isValid: false,
        spinner: false,
      },
      () => {
        if (this.state.isPasswordChange === 1) {
          this.saveSession();
          this.props.setStatus(true);
          this.props.history.push("/");
        } else {
          this.setState({
            ...this.state,
            isLogin: false,
            isInitial: true,
            initialChange: {
              ...this.state.initialChange,
              userId: this.props.loginState.data.userId,
            },
          });
        }
      }
    );
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  };

  validatePassword(value) {
    var error = "";
    const validPasswordRegex = RegExp(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
    );
    if (value === "") {
      error = "New Password is required.";
    } else if (!validPasswordRegex.test(value)) {
      error =
        "Password should be minimum eight characters, at least one upper case, one lower case, one number and one special character.";
    } else {
      error = "";
    }
    return error;
  }

  handleInitialSave = () => {
    if (this.validatePassword(this.state.initialChange.newPassword) == "") {
      this.saveInitialPassword();
    } else {
      this.showError(
        this.validatePassword(this.state.initialChange.newPassword)
      );
    }
  };

  saveInitialPassword() {
    this.setState({ ...this.state, spinner: true });
    this.props.initialPaswordChange(this.state.initialChange).then(() => {
      if (this.props.initialState.success) {
        this.setState({
          ...this.state,
          spinner: false,
          validMsg: this.props.initialState.message,
          isValid: true,
          errorMsg: "",
          isError: false,
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            spinner: false,
            validMsg: "",
            isValid: false,
            errorMsg: "",
            isError: false,
            initialChange: {
              userId: 0,
              newPassword: "",
            },
          });
          this.saveSession();
          this.props.setStatus(true);
          this.props.history.push("/");
        }, 2500);
      } else {
        this.setState({
          ...this.state,
          spinner: false,
          validMsg: "",
          isValid: false,
          errorMsg: this.props.initialState.message,
          isError: true,
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            spinner: false,
            validMsg: "",
            isValid: false,
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    });
  }

  goToForgot = () => {
    this.setState({
      ...this.state,
      email: "",
      password: "",
      isLogin: false,
      isForgot: true,
    });
  };

  goToLogin = () => {
    this.setState({
      ...this.state,
      email: "",
      password: "",
      isLogin: true,
      isForgot: false,
    });
  };

  handleForgotSave = () => {
    if (this.state.email) {
      this.saveForgotPassword();
    } else {
      this.showError("Email is required.");
    }
  };

  saveForgotPassword() {
    this.setState({ ...this.state, spinner: true });
    var formData = {
      email: this.state.email,
    };
    this.props.forgotPasswordChange(formData).then(() => {
      if (this.props.forgotState.success) {
        this.setState({
          ...this.state,
          spinner: false,
          validMsg:
            "We have sent you a verification mail. Please check your inbox.",
          isValid: true,
          errorMsg: "",
          isError: false,
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            spinner: false,
            validMsg: "",
            isValid: false,
            errorMsg: "",
            isError: false,
            email: "",
          });
        }, 2500);
      } else {
        this.setState({
          ...this.state,
          spinner: false,
          validMsg: "",
          isValid: false,
          errorMsg: this.props.forgotState.message,
          isError: true,
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            spinner: false,
            validMsg: "",
            isValid: false,
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    });
  }

  sendVerificationMail(data) {
    this.props.mailSend(data).then(() => {
      if (this.props.mailState.success) {
        this.setState({
          ...this.state,
          spinner: false,
          validMsg:
            "We have sent you a verification mail. Please check your inbox.",
          isValid: true,
          errorMsg: "",
          isError: false,
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            spinner: false,
            validMsg: "",
            isValid: false,
            errorMsg: "",
            isError: false,
            email: "",
          });
        }, 2500);
      } else {
        this.setState({
          ...this.state,
          spinner: false,
          validMsg: "",
          isValid: false,
          errorMsg:
            "We couldn't send you a verification mail. Please try again later.",
          isError: true,
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            spinner: false,
            validMsg: "",
            isValid: false,
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    });
  }

  render() {
    return (
      <Fragment>
        <div className="login-wrapper">
          <div className="row d-flex align-items-center w-100">
            <div className="col-12 col-sm-8 text-center d-flex justify-content-center align-items-center mb-3">
              {/* <p className="login-heading">Welcome to Citizen.lk Website</p> */}
              <img className="citizen-logo" src={Citizen} alt="logo" />
            </div>
            <div className="col-12 col-sm-4">
              <div className="card login-card">
                {this.state.isLogin && (
                  <div className="card-body">
                    <p className="login-text-01">Sign In</p>
                    <p className="login-text-02">
                      Please enter your email and password below to login.
                    </p>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            autocomplete="off"
                            type="text"
                            className="form-control"
                            id="email"
                            aria-describedby="helpId"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            autocomplete="off"
                            type="password"
                            className="form-control"
                            id="password"
                            aria-describedby="helpId"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 d-flex flex-row justify-content-end">
                        <p className="forgot-text" onClick={this.goToForgot}>
                          Forgot Password?
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3">
                        {this.state.isError ? (
                          <div className="alert alert-danger" role="alert">
                            {this.state.errorMsg}
                          </div>
                        ) : this.state.spinner ? (
                          <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                              <div
                                className="spinner-border text-danger"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          className="btn btn-block text-white mt-2"
                          onClick={this.handleClick}
                          style={{ backgroundColor: "#CD3235" }}
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {/* initial */}
                {this.state.isInitial && (
                  <div className="card-body">
                    <p className="login-text-01">Initial Password Change</p>
                    <p className="login-text-02">
                      Please enter your new password.
                    </p>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            aria-describedby="helpId"
                            placeholder="New Password"
                            value={this.state.initialChange.newPassword}
                            onChange={this.handleInitialChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3">
                        {this.state.isValid ? (
                          <div className="alert alert-success" role="alert">
                            {this.state.validMsg}
                          </div>
                        ) : this.state.isError ? (
                          <div className="alert alert-danger" role="alert">
                            {this.state.errorMsg}
                          </div>
                        ) : this.state.spinner ? (
                          <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                              <div
                                className="spinner-border text-danger"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          className="btn btn-block text-white mt-2"
                          onClick={this.handleInitialSave}
                          style={{ backgroundColor: "#CD3235" }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {/* forgot */}
                {this.state.isForgot && (
                  <div className="card-body">
                    <p className="login-text-01">Forgot Password</p>
                    <p className="login-text-02">
                      Please enter your email to reset your password.
                    </p>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            aria-describedby="helpId"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 d-flex flex-row justify-content-end">
                        <p className="forgot-text" onClick={this.goToLogin}>
                          Login
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3">
                        {this.state.isValid ? (
                          <div className="alert alert-success" role="alert">
                            {this.state.validMsg}
                          </div>
                        ) : this.state.isError ? (
                          <div className="alert alert-danger" role="alert">
                            {this.state.errorMsg}
                          </div>
                        ) : this.state.spinner ? (
                          <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                              <div
                                className="spinner-border text-danger"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          className="btn btn-block text-white mt-2"
                          onClick={this.handleForgotSave}
                          style={{ backgroundColor: "#CD3235" }}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginState: state.appStore.login,
    initialState: state.appStore.initial,
    forgotState: state.appStore.forgot,
    mailState: state.appStore.mail,
    userPermissionState: state.usersStore.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginUser: (data) => dispatch(loginUser(data)),
  initialPaswordChange: (data) => dispatch(initialPaswordChange(data)),
  forgotPasswordChange: (data) => dispatch(forgotPasswordChange(data)),
  mailSend: (data) => dispatch(mailSend(data)),
  getAllUserPermissions: () => dispatch(getAllUserPermissions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
