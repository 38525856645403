import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_GET_ALL_TAG,
  ACTION_ADD_TAG,
  ACTION_EDIT_TAG,
  ACTION_DELETE_TAG,
} from "../../constants/actions";

const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const getAllTags = () => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_TAG,
    data: [],
    status: false,
    token: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getAllTags(accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        token: res.data.status,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const addTag = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_ADD_TAG,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.addTag(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to save tag due to some server issue, please try again later.",

        success: false,
        status:
          err.response && err.response.data && err.response.data.status
            ? err.response.data.status
            : 0,
      };
    });
  dispatch(actionData);
};

export const editTag = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_EDIT_TAG,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.editTag(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to update tag due to some server issue, please try again later.",
        success: false,
        status:
          err.response && err.response.data && err.response.data.status
            ? err.response.data.status
            : 0,
      };
    });
  dispatch(actionData);
};

export const deleteTag = (id) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_DELETE_TAG,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.deleteTag(id, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: id,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
