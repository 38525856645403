import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addEmployee } from "../../../store/actions/emp-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import { Link } from "react-router-dom";
import * as validator from "../validations";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";

class AddEmployee extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        role: "Editor",
        language: "",
        password: "Pass123#",
        confirmPassword: "Pass123#",
        dateCreated: moment().format("YYYY-MM-DD"),
      },
      errors: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      isbtnDisable: false,
      selectedLanguage: "",
      languagesArray: [],
    };

    this.checkLanguage = this.checkLanguage.bind(this);
    this.addLanguage = this.addLanguage.bind(this);
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("3");
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (prevProps.addState !== this.props.addState) {
      if (this.props.addState.success && this.props.addState.status === 1) {
        this.showSuccess();
      } else if (
        this.props.addState.success &&
        this.props.addState.status === -1
      ) {
        this.props.refreshToken();
      }
      // else if (this.props.addState.isError) {
      //   this.showError("Failed to add new employee. Please try again.");
      // }
      else if (
        this.props.addState.isError &&
        this.props.addState.status === 0
      ) {
        this.showError(this.props.addState.message);
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.addEmployee(this.state.data);
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;

    if (id === "role") {
      this.setState({
        data: { ...this.state.data, [id]: value },
        languagesArray: [],
      });
    } else {
      this.setState({
        data: { ...this.state.data, [id]: value },
      });
    }
  };

  isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  hasSpecialChar = (s) => {
    var format = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);
    if (format.test(s)) {
      return false;
    } else {
      return true;
    }
  };

  hasWhiteSpace = (s) => {
    var reWhiteSpace = new RegExp(/\s/);

    // Check for white space
    if (reWhiteSpace.test(s)) {
      //alert("Please Check Your Fields For Spaces");
      return false;
    }

    return true;
  };

  handleAdd = () => {
    for (let [id, value] of Object.entries(this.state.data)) {
      this.validateInputAndSetState(id, value);
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    var isValid = validator.isErrorObjectEmpty(this.state.errors);

    if (isValid && this.state.data.phone.replace(/ /g, "").length !== 10) {
      this.showError("Phone number must have 10 digits!");
      isValid = false;
      return false;
    }
    if (isValid && !this.isNumeric(this.state.data.phone)) {
      this.showError("Phone number contains non-numeric values!");
      isValid = false;
      return false;
    }

    if (isValid) {
      if (
        !this.hasWhiteSpace(this.state.data.username) ||
        !this.hasSpecialChar(this.state.data.username)
      ) {
        this.showError(
          "Username cannot contain white spaces or special characters!"
        );
        return false;
      }

      if (!pattern.test(this.state.data.email)) {
        this.showError("Invalid email address!");
        return false;
      }

      if (!this.passwordStrength()) {
        this.showError(
          "Password must container minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character!"
        );
        return false;
      }

      if (
        this.state.data.role === "Editor" &&
        this.state.languagesArray.length === 0
      ) {
        this.showError("Languages cannot be empty!");
        return false;
      }

      if (this.state.data.password === this.state.data.confirmPassword) {
        this.setState(
          {
            ...this.state,
            data: {
              ...this.state.data,
              language: this.state.languagesArray.join(","),
            },
          },
          () => {
            this.props.addEmployee(this.state.data);
            this.setState({ ...this.state, spinner: true, isbtnDisable: true });
          }
        );
      } else {
        this.showError("Password did not match with confirm password.");
      }
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Employee has been added successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
            isbtnDisable: false,
          });
          this.props.history.push("/employees/all");
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
            isbtnDisable: false,
          });
        }, 3500);
      }
    );
  }

  passwordStrength = () => {
    var pattern = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );

    if (!pattern.test(this.state.data.password)) {
      return false;
    }
    return true;
  };

  addLanguage(val) {
    if (this.state.languagesArray.length > 0) {
      var isExist = this.state.languagesArray.some((a) => a === val);
      if (!isExist) {
        this.setState({
          ...this.state,
          languagesArray: this.state.languagesArray.concat(val),
          selectedLanguage: "",
        });
      }
    } else {
      this.setState({
        ...this.state,
        languagesArray: this.state.languagesArray.concat(val),
        selectedLanguage: "",
      });
    }
  }

  removeLanguage = (val) => {
    if (val) {
      this.setState({
        ...this.state,
        languagesArray: this.state.languagesArray.filter((a) => a !== val),
        selectedLanguage: "",
      });
    }
  };

  checkLanguage(val) {
    var isExist = false;

    isExist = this.state.languagesArray.some((a) => a === val);

    return isExist;
  }

  render() {
    const {
      username,
      firstName,
      lastName,
      email,
      phone,
      role,
      password,
      confirmPassword,
    } = this.state.data;
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/employees/all",
                    }}
                  >
                    Employees
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Add Employee
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <h3>Add Employee</h3>
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="username">Username *</label>
                <input
                  type="search"
                  autocomplete="false"
                  className="form-control"
                  id="username"
                  aria-describedby="helpId"
                  placeholder="Username"
                  value={username}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="firstName">First Name *</label>
                <input
                  type="search"
                  autocomplete="false"
                  className="form-control"
                  id="firstName"
                  aria-describedby="helpId"
                  placeholder="First Name"
                  value={firstName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="lastName">Last Name *</label>
                <input
                  type="search"
                  autocomplete="false"
                  className="form-control"
                  id="lastName"
                  aria-describedby="helpId"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="email">Email *</label>
                <input
                  type="search"
                  autocomplete="false"
                  className="form-control"
                  id="email"
                  aria-describedby="helpId"
                  placeholder="Email"
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="phone">Phone Number *</label>
                <input
                  type="search"
                  autocomplete="false"
                  className="form-control"
                  id="phone"
                  aria-describedby="helpId"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="phone">User Role *</label>
                <select
                  className="form-control"
                  id="role"
                  value={role}
                  onChange={this.handleChange}
                >
                  <option value="Editor">Editor</option>
                  <option value="Admin">Admin</option>
                  <option value="Super Admin">Super Admin</option>
                  <option value="Fact Check">Fact Check</option>
                </select>
              </div>
              {role === "Editor" ? (
                <div className="col-md-6 col-sm-12 mt-3">
                  <label htmlFor="phone">Languages *</label>
                  <select
                    className="form-control"
                    id="language"
                    value={this.state.selectedLanguage}
                    onChange={(e) => this.addLanguage(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select Languages
                    </option>
                    <option
                      value="Sinhala"
                      disabled={this.checkLanguage("Sinhala")}
                    >
                      Sinhala
                    </option>
                    <option
                      value="English"
                      disabled={this.checkLanguage("English")}
                    >
                      English
                    </option>
                    <option
                      value="Tamil"
                      disabled={this.checkLanguage("Tamil")}
                    >
                      Tamil
                    </option>
                  </select>
                </div>
              ) : null}
              {this.state.languagesArray.length > 0 ? (
                <div className="col-md-12 col-sm-12 mt-3">
                  {this.state.languagesArray.map((l, i) => (
                    <Chip
                      className="m-1"
                      label={l}
                      onDelete={(e) => this.removeLanguage(l)}
                      key={i}
                      style={{
                        backgroundColor: "#cd3235",
                        color: "white",
                      }}
                    />
                  ))}
                </div>
              ) : null}

              {/* <div className="col-12 col-sm-4 mt-3">
                <label htmlFor="password">Password *</label>
                <input
                  type="password"
                  autocomplete="new-password"
                  className="form-control"
                  id="password"
                  aria-describedby="helpId"
                  placeholder="Password"
                  value={password}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-4 mt-3">
                <label htmlFor="confirmPassword">Confirm Password *</label>
                <input
                  type="password"
                  autocomplete="new-password"
                  className="form-control"
                  id="confirmPassword"
                  aria-describedby="helpId"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={this.handleChange}
                />
              </div> */}
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center mt-4 mb-4">
                {this.state.spinner ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.isError ? (
                  <div
                    className="alert alert-danger w-100 text-center"
                    role="alert"
                  >
                    {this.state.errorMsg}
                  </div>
                ) : null}
                {this.state.isValid ? (
                  <div
                    className="alert alert-success w-100 text-center"
                    role="alert"
                  >
                    {this.state.validMsg}
                  </div>
                ) : null}
              </div>

              <div className="col-12 d-flex justify-content-end mt-3">
                <button
                  type="button"
                  className="btn primary_btn add-btn-size"
                  onClick={(e) => this.handleAdd()}
                  disabled={this.state.isbtnDisable}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addState: state.employeeStore.employee,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addEmployee: (data) => dispatch(addEmployee(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddEmployee)
);
