import {
  ACTION_GET_ALL_LIVE_NEWS,
  ACTION_ADD_LIVE_NEWS,
  ACTION_GET_LIVE_NEWS_BY_ID,
  ACTION_EDIT_LIVE_NEWS,
  ACTION_DELETE_LIVE_NEWS,
} from "../../constants/actions";

const initialState = {
  liveNewsList: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  liveNews: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editLiveNews: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  deleteLiveNews: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};

export default function liveNewsStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_LIVE_NEWS: {
      _state = {
        ...state,
        liveNewsList: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_ADD_LIVE_NEWS: {
      _state = {
        ...state,
        liveNews: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
        liveNewsList: {
          ...state.liveNewsList,
          success: false,
          isLoading: false,
          isError: false,
        },
      };
      break;
    }

    case ACTION_GET_LIVE_NEWS_BY_ID: {
      _state = {
        ...state,
        liveNews: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }

    case ACTION_EDIT_LIVE_NEWS: {
      _state = {
        ...state,
        editLiveNews: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        liveNewsList: {
          ...state.liveNewsList,
          success: false,
          isLoading: false,
          isError: false,
        },
      };
      break;
    }

    case ACTION_DELETE_LIVE_NEWS: {
      _state = {
        ...state,
        deleteLiveNews: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        liveNewsList: {
          ...state.liveNewsList,
          data: state.liveNewsList.data.filter((x) => x.Id !== action.data),
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
