const checkMinLength = (value, length) => value.length === length;

// const checkMaxLength = (value, length) => value.length > length;

// const validEmailRegex = RegExp(
//   /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
// );
// const checkNumber = RegExp("[0-9]");
var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const isErrorObjectEmpty = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => {
    if (val.length > 0) {
      valid = false;
    }
  });
  return valid;
};

export const validateInputs = (id, value, _errors) => {
  let errors = _errors;
  if (id === "Title") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Title cannot be empty!";
    } 
    // else if (format.test(value)) {
    //   errors[id] = "Title cannot contain any special characters!";
    // }
     else {
      errors[id] = "";
    }
  }

  if (id === "URL") {
    if (checkMinLength(value, 0)) {
      errors[id] = "URL cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  // if (id === "Author") {
  //   if (checkMinLength(value, 0)) {
  //     errors[id] = "Author number cannot be empty!";
  //   } else {
  //     errors[id] = "";
  //   }
  // }

  // if (id === "EmbedLink") {
  //   if (checkMinLength(value, 0)) {
  //     errors[id] = "Embedded link cannot be empty!";
  //   } else {
  //     errors[id] = "";
  //   }
  // }

  return errors;
};
