import {
  ACTION_GET_ALL_VIDEO,
  ACTION_ADD_VIDEO,
  ACTION_GET_VIDEO_BY_ID,
  ACTION_EDIT_VIDEO,
  ACTION_DELETE_VIDEO,
} from "../../constants/actions";

const initialState = {
  videos: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  video: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editVideo: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  deleteVideo: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};

export default function videoStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_VIDEO: {
      if (action.status) {
        _state = {
          ...state,
          videos: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      } else {
        _state = {
          ...state,
          videos: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      }
      break;
    }
    case ACTION_ADD_VIDEO: {
      _state = {
        ...state,
        video: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
        videos: {
          ...state.videos,
          success: false,
          isLoading: false,
          isError: false,
          token: 0,
          status: false,
          data: [],
        },
      };
      break;
    }

    case ACTION_GET_VIDEO_BY_ID: {
      _state = {
        ...state,
        video: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }

    case ACTION_EDIT_VIDEO: {
      _state = {
        ...state,
        editVideo: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        videos: {
          ...state.videos,
          success: false,
          isLoading: false,
          isError: false,
          token: 0,
          status: false,
          data: [],
        },
      };
      break;
    }

    case ACTION_DELETE_VIDEO: {
      _state = {
        ...state,
        deleteVideo: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        videos: {
          ...state.videos,
          data: state.videos.data.filter((x) => x.Id !== action.data),
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
