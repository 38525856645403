import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Logo from "../../img/logo.png";
import {
  getCurrentUserRole,
  getAllUserPermissions,
} from "../../store/actions/users-state-actions";

// Be sure to include styles at some point, probably during your bootstraping
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
class SideBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      userRoleId: 0,
      userPermissions: [],
      permissions: [],
    };
  }

  componentDidMount() {
    this.props.getAllUserPermissions().then(() => {
      if (this.props.userPermissionState.success) {
        this.setState({
          ...this.state,
          userPermissions:
            this.props.userPermissionState.data.length !== 0
              ? this.props.userPermissionState.data
              : [],
        });
      }
    });
    this.props.getCurrentUserRole().then(() => {
      if (this.props.UserRoleState.success) {
        var role = this.props.UserRoleState.data[0].Role;
        var roleId = 0;
        if (role === "Super Admin") {
          roleId = 1;
          localStorage.setItem("CIZUPRID", 1);
        }
        if (role === "Admin") {
          roleId = 2;
          localStorage.setItem("CIZUPRID", 2);
        }
        if (role === "Editor") {
          roleId = 3;
          localStorage.setItem("CIZUPRID", 3);
        }
        if (role === "Fact Check") {
          roleId = 4;
          localStorage.setItem("CIZUPRID", 4);
        }

        this.setState(
          {
            ...this.state,
            userRoleId: roleId,
          },
          () => {
            this.setPermissions(this.state.userRoleId);
          }
        );
      }
    });
  }

  setPermissions(role) {
    var result = {};

    if (role !== "SuperAdmin") {
      var rolePermissions = this.state.userPermissions.find(
        (item) => parseInt(item.role_id) === parseInt(role)
      );

      var permisionList =
        rolePermissions !== undefined
          ? rolePermissions.permission.split(",")
          : [];
      if (permisionList.length !== 0) {
        permisionList = permisionList.map((el) => {
          return el.trim();
        });
        localStorage.setItem("CIZUP", JSON.stringify(permisionList));
        result = { permissions: permisionList, isNull: false };
      }
      // else {
      //   localStorage.removeItem("CIZUP");
      //   result = { permissions: [], isNull: true };
      // }
    } else {
      var arr = [];
      localStorage.setItem("CIZUP", JSON.stringify(arr));
      result = { permissions: arr, isNull: false };
    }

    this.setState({
      ...this.state,
      permissions: result.permissions,
      isNull: result.isNull,
    });

    return result;
  }

  checkAccess(id) {
    var access = false;
    var permisionListLocalStorage = JSON.parse(localStorage.getItem("CIZUP"));

    if (
      permisionListLocalStorage != undefined &&
      permisionListLocalStorage != null
    ) {
      if (permisionListLocalStorage.length > 0) {
        var isExist = permisionListLocalStorage.find((item) => item === id);

        if (isExist !== undefined) {
          access = true;
        }
      } else {
        access = true;
      }
    } else {
      access = false;
    }

    return access;
  }
  render() {
    let role = window.localStorage.getItem("role")
      ? window.localStorage.getItem("role")
      : "editor";
    return (
      <Fragment>
        <Route
          render={({ location, history }) => (
            <SideNav
              onSelect={(selected) => {
                const to = "/" + selected;
                history.push(to);
              }}
              defaultExpanded
              expanded={this.state.expanded}
              onToggle={(expanded) => {
                this.setState({ expanded });
                this.props.setExpanded(expanded);
              }}
              className="side-nav"
            >
              {/* <Toggle /> */}
              <SideNav.Toggle />
              <div className="img-container">
                <img
                  className="mt-2 mb-4 admin-logo"
                  style={{
                    width: `${this.state.expanded ? "170px" : "0px"}`,
                  }}
                  src={Logo}
                  alt="log"
                />
              </div>

              <SideNav.Nav className="side-navbar">
                <NavItem eventKey="">
                  <NavIcon>
                    <i
                      className="fa fa-fw fa-home "
                      style={{ fontSize: "1.75em" }}
                    />
                  </NavIcon>
                  <NavText>Home</NavText>
                </NavItem>

                {this.checkAccess("1") && (
                  <NavItem eventKey="posts/all">
                    <NavIcon>
                      <i
                        className="fa fa-fw fa-newspaper-o mt-3"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>Posts</NavText>
                  </NavItem>
                )}

                {this.checkAccess("2") && (
                  <NavItem eventKey="videos/all">
                    <NavIcon>
                      <i
                        className="fa fa-fw fa-play  mt-3"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>Videos</NavText>
                  </NavItem>
                )}
                {this.checkAccess("3") && (
                  <NavItem eventKey="employees/all">
                    <NavIcon>
                      <i
                        className="fa fa-fw fa-user  mt-3"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>Employees</NavText>
                  </NavItem>
                )}
                {this.checkAccess("4") && (
                  <NavItem eventKey="livenews">
                    <NavIcon>
                      <i
                        className="fa fa-fw fa-dot-circle-o  mt-3"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>Live Broadcast</NavText>
                  </NavItem>
                )}
                {this.checkAccess("5") && (
                  <NavItem eventKey="live/post/all">
                    <NavIcon>
                      <i
                        className="fa fa-fw fa-file-text-o  mt-3"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>Live Posts</NavText>
                  </NavItem>
                )}

                {this.checkAccess("6") && (
                  <NavItem eventKey="factcheck/all">
                    <NavIcon>
                      <i
                        className="fa fa-fw fa-check-circle-o  mt-3"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>Fact Checks</NavText>
                  </NavItem>
                )}
                {/* <NavItem eventKey="factcheckarticle/all">
                  <NavIcon>
                    <i
                      className="fa fa-fw fa-check-circle-o"
                      style={{ fontSize: "1.75em" }}
                    />
                  </NavIcon>
                  <NavText>Fact Check Article</NavText>
                </NavItem> */}

                {this.checkAccess("7") && (
                  <NavItem eventKey="ad/all">
                    <NavIcon>
                      <i
                        className="fa fa-fw fa-external-link-square  mt-3"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>Advertisements</NavText>
                  </NavItem>
                )}
                {this.checkAccess("8") && (
                  <NavItem eventKey="users/all">
                    <NavIcon>
                      <i
                        className="fa fa-fw fa-users  mt-3"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>Users</NavText>
                  </NavItem>
                )}
                {this.checkAccess("9") && (
                  <NavItem eventKey="user-permission">
                    <NavIcon>
                      <i
                        className="fa fa-lock  mt-3"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>User Permissions</NavText>
                  </NavItem>
                )}
                {this.checkAccess("10") && (
                  <NavItem eventKey="category">
                    <NavIcon>
                      <i
                        className="fa fa-fw fa-tags  mt-3"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>Categories and Tags</NavText>
                  </NavItem>
                )}
                {this.checkAccess("12") && (
                  <NavItem eventKey="gallery-management">
                    <NavIcon>
                      <i
                        class="fa fa-picture-o  mt-3"
                        style={{ fontSize: "1.75em" }}
                      ></i>
                    </NavIcon>
                    <NavText>Gallery Management</NavText>
                  </NavItem>
                )}

                {this.checkAccess("11") && (
                  <NavItem eventKey="info/all">
                    <NavIcon>
                      <i
                        className="fa fa-fw fa-info-circle mt-2"
                        style={{ fontSize: "1.75em" }}
                      />
                    </NavIcon>
                    <NavText>Info Dashboard</NavText>
                  </NavItem>
                )}
              </SideNav.Nav>
            </SideNav>
          )}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserRoleState: state.usersStore.role,
    userPermissionState: state.usersStore.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCurrentUserRole: () => dispatch(getCurrentUserRole()),
  getAllUserPermissions: () => dispatch(getAllUserPermissions()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBar)
);
