import React, { PureComponent, Fragment } from "react";
import "./index.css";

import Citizen from "../../img/logo02.png";
import { resetPasswordChange } from "../../store/actions/app-state-actions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Verify extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      validMsg: "",
      isValid: false,
      spinner: false,
      errorMsg: "",
      isError: false,
      reset: {
        email: "",
        newPassword: "",
        confirmPassword: "",
      },
      redirect: "",
    };

    this.validatePassword = this.validatePassword.bind(this);
    this.comparePassword = this.comparePassword.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      reset: {
        ...this.state.redirect,
        email: this.props.match.params.email,
      },
    });
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      reset: { ...this.state.reset, [id]: value },
    });
  };

  validatePassword(value) {
    var error = "";
    const validPasswordRegex = RegExp(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
    );
    if (value === "") {
      error = "New Password is required.";
    } else if (!validPasswordRegex.test(value)) {
      error =
        "Password should be minimum eight characters, at least one upper case, one lower case, one number and one special character.";
    } else {
      error = "";
    }
    return error;
  }

  comparePassword() {
    var error = "";

    if (this.state.reset.confirmPassword === "") {
      error = "Confirm Password is required.";
    } else if (
      this.state.reset.newPassword != this.state.reset.confirmPassword
    ) {
      error = "New password and confirm password do not match.";
    } else {
      error = "";
    }
    return error;
  }

  handlePassword() {
    if (
      this.validatePassword(this.state.reset.newPassword) === "" &&
      this.comparePassword() === ""
    ) {
      this.resetPassword();
    } else {
      this.setState({
        ...this.state,
        validMsg: "",
        isValid: false,
        spinner: false,
        errorMsg: this.validatePassword(this.state.reset.newPassword)
          ? this.validatePassword(this.state.reset.newPassword)
          : this.comparePassword()
          ? this.comparePassword()
          : "",
        isError: true,
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          validMsg: "",
          isValid: false,
          spinner: false,
          errorMsg: "",
          isError: false,
        });
      }, 2500);
    }
  }

  resetPassword() {
    this.setState({ ...this.state, spinner: true });
    this.props.resetPasswordChange(this.state.reset).then(() => {
      if (this.props.resetState.success) {
        this.setState({
          ...this.state,
          validMsg: this.props.resetState.message,
          isValid: true,
          spinner: false,
          errorMsg: "",
          isError: false,
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            validMsg: "",
            isValid: false,
            spinner: false,
            errorMsg: "",
            isError: false,
            redirect: "/",
          });
        }, 2500);
      } else {
        this.setState({
          ...this.state,
          validMsg: "",
          isValid: false,
          spinner: false,
          errorMsg: this.props.resetState.message,
          isError: true,
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            validMsg: "",
            isValid: false,
            spinner: false,
            errorMsg: "",
            isError: false,
          });
        }, 2500);
      }
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Fragment>
        <div className="login-wrapper">
          <div className="row d-flex align-items-center w-100">
            <div className="col-12 col-sm-8 text-center d-flex justify-content-center align-items-center mb-3">
              <img className="citizen-logo" src={Citizen} alt="logo" />
            </div>
            <div className="col-12 col-sm-4">
              <div className="card login-card">
                <div className="card-body">
                  <p className="login-text-01">Password Reset</p>
                  <p className="login-text-02">
                    Please enter your new password to reset password.
                  </p>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="newPassword"
                          aria-describedby="helpId"
                          placeholder="New Password"
                          value={this.state.reset.newPassword}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          aria-describedby="helpId"
                          placeholder="Confirm Password"
                          value={this.state.reset.confirmPassword}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-3">
                      {this.state.isValid ? (
                        <div className="alert alert-success" role="alert">
                          {this.state.validMsg}
                        </div>
                      ) : this.state.isError ? (
                        <div className="alert alert-danger" role="alert">
                          {this.state.errorMsg}
                        </div>
                      ) : this.state.spinner ? (
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <button
                        type="button"
                        className="btn btn-block text-white mt-2"
                        onClick={this.handlePassword}
                        style={{ backgroundColor: "#CD3235" }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resetState: state.appStore.reset,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPasswordChange: (data) => dispatch(resetPasswordChange(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
