import React, { PureComponent, Fragment } from "react";
import {
  editLiveNews,
  getLiveNewsById,
  getAllLiveNews,
} from "../../../store/actions/live-news-action";
import { refreshToken } from "../../../store/actions/app-state-actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import moment from "moment";
import ReactPlayer from "react-player/youtube";
import { Link } from "react-router-dom";
import * as validator from "../validations";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
class EditLiveNews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Id: 0,
        Title: "",
        Author: "",
        URL: "",
        Embedlink: "",
        Status: "",
        IsSchedule: "0",
        ScheduleDateTime: "",
      },
      errors: {
        Title: "",
        Author: "",
        URL: "",
        Embedlink: "",
      },
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      redirect: "",
      isbtnDisable: false,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    this.props.checkUserPermission("4");
    var post = this.props.newsListState.data.filter(
      (x) => x.Slug === this.props.match.params.id
    );
    if (post.length !== 0 && this.props.newsListState.success) {
      this.setState({
        ...this.state,
        data: post[0],
        success: true,
      });
    } else {
      this.props.getLiveNewsById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (prevProps.editState !== this.props.editState) {
      if (this.props.editState.success && this.props.editState.status === 1) {
        this.showSuccess();
      } else if (this.props.editState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.editState.isError) {
        this.showError("Failed to update live broadcast. Please try again.");
      }
    }

    if (this.props.newsState !== prevProps.newsState) {
      if (this.props.newsState.success && this.props.newsState.status === 1) {
        this.setState({
          ...this.state,
          data: this.props.newsState.data[0],
          success: true,
        });
      } else if (this.props.newsState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        if (this.props.editState.status === -1) {
          this.handleSubmit();
        }
        if (this.props.newsState.status === -1) {
          this.props.getLiveNewsById(this.props.match.params.id);
        }
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  isUrlValid = (url) => {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      // Do anything for being valid
      // if need to change the url to embed url then use below line
      // $('#ytplayerSide').attr('src', 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0');
      return true;
    } else {
      // Do anything for not being valid
      return false;
    }
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Live broadcast has been updated successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.props.getAllLiveNews();
          this.setState({
            validMsg: "",
            isValid: false,
            redirect: "/livenews",
            isbtnDisable: false,
          });
          //   this.props.history.push("/posts/all");
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
            isbtnDisable: false,
          });
        }, 3500);
      }
    );
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "URL") {
      var embedlink = value.split("/");
      let lastElement = embedlink[embedlink.length - 1];
      var step1 = lastElement.split("=");

      if (step1[0] == "watch?v") {
        var step2 = step1[1].split("&");
        if (step2[0]) {
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              [id]: value,
              Embedlink: "https://www.youtube.com/embed/" + step2[0],
            },
          });
        } else {
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              [id]: value,
              Embedlink: "https://www.youtube.com/embed/" + step1[0],
            },
          });
        }
      } else {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            [id]: value,
            Embedlink: "https://www.youtube.com/embed/" + lastElement,
          },
        });
      }
    } else {
      this.setState({
        ...this.state,
        data: { ...this.state.data, [id]: value },
      });
    }
  };

  handleSubmit = () => {
    for (let [id, value] of Object.entries(this.state)) {
      this.validateInputAndSetState(id, value);
    }
    var isValid = validator.isErrorObjectEmpty(this.state.errors);

    if (isValid) {
      if (!this.isUrlValid(this.state.data.URL)) {
        this.showError("Invalid youtube url inserted!");
        isValid = false;
        return false;
      }

      if (!this.isUrlValid(this.state.data.Embedlink)) {
        this.showError("Invalid embedded link inserted!");
        isValid = false;
        return false;
      }
      var fromData = {
        id: this.state.data.Id,
        title: this.state.data.Title,
        slug: this.state.data.Title.replace(/ /g, "-")
          .replace(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/g, "-")
          .toLowerCase(),
        embedlink: this.state.data.Embedlink,
        url: this.state.data.URL,
        status: this.state.data.Status,
        author: this.state.data.Author,
        dateCreated: this.state.data.DateCreated,
      };
      this.props.editLiveNews(fromData);
      this.setState({ ...this.state, spinner: true, isbtnDisable: true });
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  render() {
    const { Title, Author, URL, Embedlink, Status } = this.state.data;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/livenews",
                    }}
                  >
                    Live Broadcast
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Update Live Broadcast
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-11">
            <h3>Update Live Broadcast</h3>
          </div>
          <div className="col-11">
            <div className="row">
              <div className="col-12 mb-3">
                <label htmlFor="Title">Broadcast Title *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Title"
                  aria-describedby="helpId"
                  placeholder="Broadcast Title"
                  value={Title}
                  onChange={this.handleChange}
                />
              </div>
              {/* <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Author">Author *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Author"
                  aria-describedby="helpId"
                  placeholder="Author"
                  value={Author}
                  onChange={this.handleChange}
                />
              </div> */}
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="URL">Youtube URL *</label>
                <input
                  type="text"
                  className="form-control"
                  id="URL"
                  aria-describedby="helpId"
                  placeholder="URL"
                  value={URL}
                  onChange={this.handleChange}
                />
              </div>
              {/* <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Embedlink">Youtube Embedded Link *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Embedlink"
                  aria-describedby="helpId"
                  placeholder="Link"
                  value={Embedlink}
                  onChange={this.handleChange}
                />
              </div> */}
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Status">Autoplay Video *</label>
                <select
                  className="form-control"
                  name=""
                  id="Status"
                  value={Status}
                  onChange={this.handleChange}
                >
                  <option value={"1"}>On</option>
                  <option value={"2"}>Off</option>
                </select>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="IsSchedule">Schedule *</label>
                <select
                  className="form-control"
                  name=""
                  id="IsSchedule"
                  value={this.state.data.IsSchedule}
                  onChange={this.handleChange}
                  disabled="true"
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              {this.state.data.IsSchedule == "1" ? (
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="isSchedule">Schedule DateTime *</label>
                  <input
                    className="form-control"
                    id="ScheduleDateTime"
                    type="datetime-local"
                    min={moment().format("YYYY-MM-DD HH:MM")}
                    value={moment(this.state.data.ScheduleDateTime).format(
                      "YYYY-MM-DD HH:MM"
                    )}
                    onChange={this.handleChange}
                    disabled="true"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center mt-4 mb-4">
                {this.state.spinner ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.isError ? (
                  <div
                    className="alert alert-danger w-100 text-center"
                    role="alert"
                  >
                    {this.state.errorMsg}
                  </div>
                ) : null}
                {this.state.isValid ? (
                  <div
                    className="alert alert-success w-100 text-center"
                    role="alert"
                  >
                    {this.state.validMsg}
                  </div>
                ) : null}
              </div>

              <div className="col-12 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn primary_btn add-btn-size"
                  onClick={(e) => this.handleSubmit()}
                  disabled={this.state.isbtnDisable}
                >
                  Update
                </button>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                {URL.length !== 0 && this.isUrlValid(URL) ? (
                  <ReactPlayer url={URL} controls width="100%" height="480px" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsListState: state.liveNewsStore.liveNewsList,
    newsState: state.liveNewsStore.liveNews,
    editState: state.liveNewsStore.editLiveNews,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editLiveNews: (data) => dispatch(editLiveNews(data)),
  getLiveNewsById: (data) => dispatch(getLiveNewsById(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
  getAllLiveNews: () => dispatch(getAllLiveNews()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditLiveNews)
);
