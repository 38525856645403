import React, { PureComponent, Fragment } from "react";
import { getAllAds, deleteAd } from "../../store/actions/ad-state-actions";
import { refreshToken } from "../../store/actions/app-state-actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { checkUserPermission } from "../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";

class ViewAllAd extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      adList: [],
      success: false,
      id: 0,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      open: false,
      isLoading: true,
      pageSize: 10,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("7");

    if (!this.props.adState.success && !this.props.adState.isLoading) {
      this.props.getAllAds();
    } else {
      this.setState({
        ...this.state,
        adList: this.props.adState.data,
        success: true,
        isLoading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (this.props.adState !== prevProps.adState) {
      if (this.props.adState.success && this.props.adState.token === 1) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
          success: true,
          isLoading: false,
        });
      } else if (this.props.adState.token === -1) {
        this.props.refreshToken();
      }
    }

    if (this.props.deleteState !== prevProps.deleteState) {
      if (
        this.props.deleteState.success &&
        this.props.deleteState.status === 1
      ) {
        this.setState(
          {
            ...this.state,
            adList: this.props.adState.data,
          },
          () => {
            this.showSuccess();
          }
        );
      } else if (this.props.deleteState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.deleteState.isError) {
        this.showError("Failed to delete selected video.");
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        if (this.props.deleteState.status === -1) {
          this.handleDelete();
        }

        if (this.props.adState.status === -1) {
          this.props.getAllAds();
        }
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleDelete = () => {
    this.setState({ ...this.state, spinner: true });
    var obj = {
      id: this.state.id,
    };
    this.props.deleteAd(obj);
    this.handleCloseModal();
  };

  handleEditRedirect = (id) => {
    this.props.history.push(`/ad/e/` + id);
  };

  handleAddRedirect = () => {
    this.props.history.push(`/ad/add`);
  };

  handleRedirect = (id) => {
    this.props.history.push(`/ad/` + id);
  };

  getRows = () => {
    var arr =
      this.state.success &&
      this.state.adList
        .sort(
          (a, b) =>
            new Date(b.DateExpire).getTime() - new Date(a.DateExpire).getTime()
        )
        .map((item, index) => {
          item.id = index + 1;
          return item;
        });

    return arr;
  };

  getPrevImg = (url_string) => {
    var url = new URL(url_string);
    return url.searchParams.get("v");
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Advertisement has been deleted successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          this.props.getAllAds();
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  }

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = (id) => {
    this.setState({ ...this.state, open: true, id: id });
  };

  handlePageSize = (value) => {
    this.setState({ ...this.state, pageSize: value });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 80 },
      {
        field: "Section",
        headerName: "Ad Sections",
        width: 180,
        sortable: true,
      },
      {
        field: "Count",
        headerName: "Click Count",
        sortable: true,
        width: 150,
      },
      {
        field: "DateCreated",
        headerName: "Date Created",
        sortable: true,
        width: 180,
        valueFormatter: (params) => {
          return moment(params.row.DateCreated).format("DD-MM-YYYY");
        },
      },
      {
        field: "DateExpire",
        headerName: "Expire Date",
        sortable: true,
        width: 180,
        valueFormatter: (params) => {
          return moment(params.row.DateExpire).format("DD-MM-YYYY");
        },
      },
      {
        field: "action",
        headerName: "Action",
        sortable: true,
        width: 230,

        renderCell: (params) => (
          <Fragment>
            <button
              type="button"
              className="btn btn-dark mr-2"
              onClick={(e) => this.handleRedirect(params.row.Id)}
            >
              View
            </button>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={(e) => this.handleEditRedirect(params.row.Id)}
            >
              Update
            </button>
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleOpenModal(params.row.Id)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
      },
    ];
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Advertisements
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12">
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleAddRedirect()}
              disabled={this.state.adList.length === 7}
              data-toggle="tooltip"
              data-placement="top"
              title={
                this.state.adList.length === 7
                  ? "Advertisement limit exceeded."
                  : ""
              }
            >
              Add Advertisement
            </button>
          </div>
          <div className="col-12 mt-4 mb-4">
            <h3>View All Advertisements</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ width: "100%" }}>
              {this.state.isLoading ? (
                <div className="row d-flex justify-content-center">
                  <div
                    className="spinner-border text-danger"
                    role="status"
                    style={{ width: "3rem", height: "3rem" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <DataGrid
                  rows={this.getRows() || []}
                  columns={columns}
                  disableColumnMenu
                  pageSize={this.state.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    this.handlePageSize(newPageSize)
                  }
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  disableSelectionOnClick={true}
                  autoHeight
                />
              )}
            </div>
          </div>
          <div className="col-12">
            {this.state.spinner ? (
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.isError ? (
              <div
                className="alert alert-danger w-100 text-center"
                role="alert"
              >
                {this.state.errorMsg}
              </div>
            ) : null}
            {this.state.isValid ? (
              <div
                className="alert alert-success w-100 text-center"
                role="alert"
              >
                {this.state.validMsg}
              </div>
            ) : null}
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h4>
                      Are you sure that you want to delete this advertisement?
                    </h4>
                  </div>
                  <div className="col-12 d-flex justify-content-end my-2">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleDelete()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleCloseModal()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adState: state.adStore.ads,
    deleteState: state.adStore.deleteAd,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllAds: () => dispatch(getAllAds()),
  deleteAd: (from) => dispatch(deleteAd(from)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewAllAd)
);
