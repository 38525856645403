import React, { PureComponent, Fragment } from "react";
import { Editor } from "react-draft-wysiwyg";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { addLivePost } from "../../../store/actions/live-post-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import { getAllCategory } from "../../../store/actions/category-state-action";
import { getAllTags, addTag } from "../../../store/actions/tag-state-actions";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import * as validator from "../validations";
// import MarkdownIt from "markdown-it";
// import MdEditor from "react-markdown-editor-lite";
// import style manually
// import "react-markdown-editor-lite/lib/index.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { WEB_BASE_URL, API_BASE_URL } from "../../../../src/constants/api";
import imageCompression from "browser-image-compression";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import {
  getAllGalleryImages,
  saveGalleryImage,
} from "../../../store/actions/gallery-state-actions";
import Pagination from "react-responsive-pagination";
import Resizer from "react-image-file-resizer";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";

class AddLivePost extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contents: "",
      title: "",
      author: "",
      category: "",
      tags: [],
      thumbnailImage: "",
      thumbnail: "",
      viewImage: "",
      articleImg: "",
      isSchedule: "0",
      scheduleDateTime: "",
      isFeatured: "0",
      tempThumbnailImage: "",
      language: this.props.match.params.type,
      errors: {
        title: "",
        author: "",
        contents: "",
        category: "",
      },
      imgStatus: false,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      imgName: "",
      categoryList: [],
      tagList: [],
      selectedTag: "",
      add: {
        name: "",
        type: "",
      },
      isAdd: false,
      modalIsError: false,
      modalErrorMsg: "",
      modalIsValid: false,
      modalValidMsg: "",
      modalSpinner: false,
      isLoading: true,
      isbtnDisable: false,
      imageModal: false,
      blobFile: "",
      src: "",
      croppedImageUrl: "",
      cropper: null,
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      webWorker: {
        progress: null,
        inputSize: null,
        outputSize: null,
        inputUrl: null,
        outputUrl: null,
      },
      mainThread: {
        progress: null,
        inputSize: null,
        outputSize: null,
        inputUrl: null,
        outputUrl: null,
      },
      imageGalleryModal: false,
      addImageToGallery: false,
      modalGallerySpinner: true,
      galleryImage: [],
      galleryBlobFile: "",
      gallerySrc: "",
      galleryCroppedImageUrl: "",
      galleryCropper: null,
      galleryImgName: "",
      galleryFile: "",
      gallerySpinner: false,
      galleryResponseMessage: "",
      galleryImageShowResponse: false,
      galleryIsImageError: false,
      copyImageUrl: "",
      pageNumber: 1,
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.handleCloseImageModal = this.handleCloseImageModal.bind(this);
    this.handleSaveImageModal = this.handleSaveImageModal.bind(this);
    this.handleAddImageModal = this.handleAddImageModal.bind(this);
    this.handleSaveGalleryImageModal =
      this.handleSaveGalleryImageModal.bind(this);
    this.handleGalleryImageModal = this.handleGalleryImageModal.bind(this);
    this.handleCloseGalleryImageModal =
      this.handleCloseGalleryImageModal.bind(this);
    this.getImageUrl = this.getImageUrl.bind(this);
    this.getPageCount = this.getPageCount.bind(this);
    this.getSlice = this.getSlice.bind(this);
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.getAllGalleryImages();
    this.props.checkUserPermission("5");
    if (this.props.categoryListState.success) {
      this.setState({
        categoryList: this.props.categoryListState.data,
      });
    } else {
      this.props.getAllCategory();
    }

    if (this.props.tagListState.success) {
      this.setState({
        tagList: this.props.tagListState.data,
      });
    } else {
      this.props.getAllTags();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (prevProps.tagListState !== this.props.tagListState) {
      if (
        this.props.tagListState.success &&
        this.props.tagListState.token === 1
      ) {
        this.setState({
          ...this.state,
          tagList: this.props.tagListState.data.sort(),
        });
      } else if (this.props.tagListState.token === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.categoryListState !== this.props.categoryListState) {
      if (
        this.props.categoryListState.success &&
        this.props.categoryListState.status === 1
      ) {
        this.setState({
          ...this.state,
          categoryList: this.props.categoryListState.data,
        });
      } else if (this.props.categoryListState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.postState !== this.props.postState) {
      if (this.props.postState.success && this.props.postState.status === 1) {
        this.showSuccess();
      } else if (this.props.postState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.postState.isError) {
        this.showError("Failed to add new post. Please try again.");
      }
    }
    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );

        if (this.props.categoryListState.status === -1) {
          this.props.getAllCategory();
        }

        if (this.props.tagListState.token === -1) {
          this.props.getAllTags();
        }

        if (this.props.postState.status === -1) {
          this.handleSubmit();
        }
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleCloseImageModal() {
    this.setState({
      imageModal: false,
      blobFile: "",
      src: "",
      croppedImageUrl: "",
      imgName: "",
      thumbnail: "",
    });
  }
  handleAddImageModal() {
    this.setState({ imageModal: true });
  }

  handleSaveImageModal() {
    this.setState({ imageModal: false });
    const { blobFile } = this.state;
    const newImage = new File([blobFile], blobFile.name, {
      type: blobFile.type,
    });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        thumbnail: this.state.croppedImageUrl,
        blobFile: "",
        src: "",
        croppedImageUrl: "",
      });
    };
    reader.readAsDataURL(newImage);
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  handleEditorChange(text) {
    this.setState({ ...this.state, contents: text });
  }

  handleImageUpload(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (data) => {
        resolve(data.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  handleImage = (e) => {
    let image = e.target.files[0];
    if (image) {
      const file = image.type.split("/").pop().toLowerCase();
      if (file === "jpeg" || file === "jpg" || file === "png") {
        if (image.size <= 5242880) {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            this.setState({
              ...this.state,
              src: reader.result,
              imgName: e.target.files[0].name,
              thumbnail: e.target.files[0],
            })
          );
          reader.readAsDataURL(e.target.files[0]);
          // this.getImage(e.target.files[0]).then(() => {
          //   this.setState({
          //     ...this.state,
          //     thumbnail: URL.createObjectURL(e.target.files[0]),
          //     thumbnailImage: e.target.value,
          //     imgName: e.target.files[0].name,
          //     viewImage: e.target.files[0],
          //   });
          // });
        } else {
          this.showError("The file is too large. Allowed maximum size is 5MB.");
        }
      } else {
        this.showError(
          "This file type is not allowed. Accepted file types are: JPEG, JPG or PNG."
        );
      }
    }
  };

  getCropData = () => {
    if (this.state.cropper !== undefined && this.state.cropper !== null) {
      this.setState(
        {
          ...this.state,
          croppedImageUrl: this.state.cropper.getCroppedCanvas().toDataURL(),
          // viewImage: this.state.cropper.getCroppedCanvas().toDataURL(),
        },
        () => {
          this.setBlobFile(this.state.croppedImageUrl);
        }
      );
    }
  };

  async compressImage(file, useWebWorker) {
    const targetName = useWebWorker ? "webWorker" : "mainThread";
    this.setState((prevState) => ({
      ...prevState,
      [targetName]: {
        ...prevState[targetName],
        inputSize: (file.size / 1024 / 1024).toFixed(2),
        inputUrl: URL.createObjectURL(file),
      },
    }));

    var options = {
      maxSizeMB: this.state.maxSizeMB,
      maxWidthOrHeight: this.state.maxWidthOrHeight,
      useWebWorker,
    };
    const output = await imageCompression(file, options);

    this.setState({ ...this.state, blobFile: output });
  }

  setBlobFile = (url) => {
    var min = 100;
    var max = 10000;
    var rand = min + Math.random() * (max - min);

    if (url !== "") {
      var startIndex = url.indexOf("base64,") + 7;
      var b64 = url.substr(startIndex);
      var byteCharacters = atob(b64);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], { type: "image/jpeg" });
      blob.name = "product-" + rand + ".jpeg";
      // this.compressImage(blob);
      this.resizeFile(blob);
      this.setState({ ...this.state, blobFile: blob });
    }
  };

  resizeFile = (file) => {
    try {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        "JPEG",
        50,
        0,
        (uri) => {
          this.setState({
            ...this.state,
            tempThumbnailImage: uri,
          });
        },
        "base64",
        200,
        200
      );
    } catch (err) {}
  };

  handleTags = (e) => {
    const { value } = e.target;
    if (value) {
      if (!this.state.tags.includes(value)) {
        this.setState({
          ...this.state,
          tags: this.state.tags.concat(value),
          selectedTag: "",
        });
      }
    }
  };

  handleDelete = (num) => {
    this.setState({
      ...this.setState,
      tags: this.state.tags.filter(function (item, index) {
        return index !== num;
      }),
    });
  };

  handleSubmit = () => {
    for (let [id, value] of Object.entries(this.state)) {
      this.validateInputAndSetState(id, value);
    }

    const isValid = validator.isErrorObjectEmpty(this.state.errors);
    if (isValid) {
      if (this.state.isSchedule === "1") {
        if (this.state.scheduleDateTime !== "") {
          if (
            moment(this.state.scheduleDateTime).format("YYYY-MM-DD HH:m:s") >=
            moment().format("YYYY-MM-DD HH:m:s")
          ) {
            this.saveData();
          } else {
            this.showError("Please enter a valid DateTime.");
          }
        } else {
          this.showError("Schedule DateTime cannot be empty!");
        }
      } else {
        this.saveData();
      }
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  saveData() {
    if (this.state.tags.length !== 0) {
      var language =
        this.props.match.params.type === "sinhala"
          ? "Sinhala"
          : this.props.match.params.type === "tamil"
          ? "Tamil"
          : "English";
      var userName = localStorage.getItem("name");

      var formData = {
        title: this.state.title,
        slug: this.state.title
          .replace(/ /g, "-")
          .replace(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/g, "-")
          .toLowerCase(),
        thumbnail: this.state.thumbnail,
        thumbnailImage: this.state.imgName,
        language: language,
        // thumbnail:
        //   this.state.articleImg.length !== 0
        //     ? this.state.articleImg
        //     : this.state.thumbnail,
        // thumbnailImage:
        //   this.state.imgName.length !== 0
        //     ? this.state.imgName
        //     : this.state.thumbnailImage,
        url: "url",
        author: userName,
        category: this.state.category,
        tags: this.state.tags.toString(),
        article: this.state.contents,
        isSchedule: this.state.isSchedule,
        scheduleDateTime:
          this.state.isSchedule == "1"
            ? moment(this.state.scheduleDateTime).format("YYYY-MM-DD HH:m:s")
            : null,
        dateCreated: moment().format("YYYY-MM-DD HH:m:s"),
        dateModified: moment().format("YYYY-MM-DD HH:m:s"),
        isFeatured: this.state.isFeatured,
        tempThumbnailImage: this.state.tempThumbnailImage,
      };

      this.setState(
        { ...this.state, spinner: true, isbtnDisable: true },
        () => {
          this.props.addLivePost(formData);
        }
      );
    } else {
      this.showError("Tags cannot be empty!");
    }
  }
  toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async getImage(file) {
    await this.toBase64(file).then((res) => {
      this.setState({
        ...this.state,
        articleImg: res,
      });
    });
  }

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Live post has been added successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
            isbtnDisable: false,
          });
          this.props.history.push("/live/post/all");
          // window.location.href = WEB_BASE_URL + "/live/post/all";
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
        responseMessage: msg,
        imageShowResponse: true,
        isImageError: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
            isbtnDisable: false,
            responseMessage: "",
            imageShowResponse: false,
            isImageError: false,
          });
        }, 3500);
      }
    );
  }

  handleAddTag = () => {
    if (this.state.add.name.length !== 0 && this.state.add.type.length !== 0) {
      var today = new Date();
      var formData = {
        name: this.state.add.name,
        type: this.state.add.type,
        dateCreated: moment(today).format("YYYY-MM-DD HH:m:s"),
      };
      this.setState(
        {
          ...this.state,
          modalSpinner: true,
          add: { ...this.state.add, name: "", type: "" },
        },
        () => {
          this.props.addTag(formData).then(() => {
            if (this.props.addState.success) {
              this.setState(
                { ...this.state, success: false, isLoading: true },
                () => {
                  this.props.getAllTags().then(() => {
                    if (this.props.tagListState.success) {
                      this.setState({
                        ...this.state,
                        tagList: this.props.tagListState.data.sort(),
                      });
                    }
                  });
                  this.showSuccessModal("Tag has been added successfully.");
                }
              );
            } else {
              if (
                this.props.addState.isError &&
                this.props.addState.status === 0
              ) {
                this.setState(
                  {
                    ...this.state,
                    modalErrorMsg: this.props.addState.message,
                    modalIsError: true,
                    modalSpinner: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        modalErrorMsg: "",
                        modalIsError: false,
                        modalSpinner: false,
                      });
                    }, 2000);
                  }
                );
              }
            }
          });
        }
      );
    } else {
      this.setState(
        {
          ...this.state,
          modalErrorMsg: "Tag name and tag type cannot be empty!",
          modalIsError: true,
          modalSpinner: false,
        },
        () => {
          setTimeout(() => {
            this.setState({
              modalErrorMsg: "",
              modalIsError: false,
            });
          }, 2000);
        }
      );
    }
  };

  handleAddChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, add: { ...this.state.add, [id]: value } });
  };

  handleAddModelOpen = () => {
    this.setState({ ...this.state, isAdd: true });
  };

  handleAddModelClose = () => {
    this.setState({
      ...this.state,
      add: {
        name: "",
        type: "",
      },
      isAdd: false,
    });
  };

  showSuccessModal(msg) {
    this.setState(
      {
        ...this.state,
        modalIsValid: true,
        modalValidMsg: msg,
        modalSpinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            modalValidMsg: "",
            modalIsValid: false,
            isEdit: false,
            isAdd: false,
          });
        }, 1500);
      }
    );
  }

  getAllGalleryImages = () => {
    this.props.getAllGalleryImages().then(() => {
      if (this.props.galleryState.success) {
        this.setState({
          ...this.state,
          modalGallerySpinner: false,
          galleryImage: this.props.galleryState.data,
        });
      }
    });
  };
  handleCloseGalleryImageModal() {
    this.setState({
      ...this.state,
      imageGalleryModal: false,
      copyImageUrl: "",
      gallerySpinner: false,
      galleryResponseMessage: "",
      galleryIsImageError: false,
      addImageToGallery: false,
      galleryBlobFile: "",
      gallerySrc: "",
      galleryCroppedImageUrl: "",
      galleryCropper: null,
      galleryImgName: "",
      galleryFile: "",
      gallerySpinner: false,
      galleryResponseMessage: "",
      galleryImageShowResponse: false,
      galleryIsImageError: false,
    });
  }
  handleGalleryImageModal() {
    this.setState({ imageGalleryModal: true });
  }
  handleSaveGalleryImageModal() {
    // this.setState({ addImageToGallery: false });
    const { galleryBlobFile } = this.state;
    const newImage = new File([galleryBlobFile], galleryBlobFile.name, {
      type: galleryBlobFile.type,
    });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        // viewImage: this.state.galleryCroppedImageUrl,
        galleryBlobFile: "",
        // gallerySrc: "",
        // galleryCroppedImageUrl: "",
      });
    };
    reader.readAsDataURL(newImage);

    var image = {
      imageName: this.state.galleryImgName
        .replace(/ /g, "-")
        .replace(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/g, "-")
        .toLowerCase(),
      imageFile: this.state.galleryCroppedImageUrl,
      createdBy: 1,
      updatedBy: 1,
    };
    this.setState({
      ...this.state,
      gallerySpinner: true,
    });
    this.props.saveGalleryImage(image).then(() => {
      if (this.props.saveGalleryState.success) {
        this.setState(
          {
            ...this.state,
            gallerySpinner: false,
            galleryResponseMessage: this.props.saveGalleryState.message,
            galleryIsImageError: false,
            modalGallerySpinner: true,
          },
          () => {
            this.getAllGalleryImages();
            setTimeout(() => {
              this.setState({
                ...this.state,
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryIsImageError: false,
                addImageToGallery: false,
                galleryBlobFile: "",
                gallerySrc: "",
                galleryCroppedImageUrl: "",
                galleryCropper: null,
                galleryImgName: "",
                galleryFile: "",
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryImageShowResponse: false,
                galleryIsImageError: false,
              });
            }, 2000);
          }
        );
      } else {
        this.setState(
          {
            ...this.state,
            gallerySpinner: false,
            galleryResponseMessage: this.props.saveGalleryState.message,
            galleryIsImageError: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryIsImageError: false,
              });
            }, 2000);
          }
        );
      }
    });
  }

  handleGalleryImage = (e) => {
    let image = e.target.files[0];
    if (image) {
      const file = image.type.split("/").pop().toLowerCase();
      if (file === "jpeg" || file === "jpg" || file === "png") {
        if (image.size <= 5242880) {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            this.setState({
              ...this.state,
              gallerySrc: reader.result,
              galleryImgName: e.target.files[0].name,
              galleryFile: e.target.files[0],
            })
          );
          reader.readAsDataURL(e.target.files[0]);

          // this.setState({
          //   ...this.state,
          //   thumbnail: e.target.files[0],
          //   imgName: e.target.files[0].name,
          //   viewImage: URL.createObjectURL(e.target.files[0]),
          //   thumbnailImage: e.target.value,
          // });
        } else {
          this.setState(
            {
              ...this.state,
              gallerySpinner: false,
              galleryResponseMessage:
                "The file is too large. Allowed maximum size is 5MB.",
              galleryIsImageError: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  gallerySpinner: false,
                  galleryResponseMessage: "",
                  galleryIsImageError: false,
                });
              }, 2000);
            }
          );
        }
      } else {
        this.setState(
          {
            ...this.state,
            gallerySpinner: false,
            galleryResponseMessage:
              "This file type is not allowed. Accepted file types are: JPEG, JPG or PNG.",
            galleryIsImageError: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryIsImageError: false,
              });
            }, 2000);
          }
        );
      }
    }
  };

  getCropGalleryImageData = () => {
    if (
      this.state.galleryCropper !== undefined &&
      this.state.galleryCropper !== null
    ) {
      this.setState(
        {
          ...this.state,
          galleryCroppedImageUrl: this.state.galleryCropper
            .getCroppedCanvas()
            .toDataURL(),
          // viewImage: this.state.cropper.getCroppedCanvas().toDataURL(),
        },
        () => {
          this.setBlobFile(this.state.galleryCroppedImageUrl);
        }
      );
    }
  };

  getImageUrl(item) {
    if (item) {
      // document.execCommand("copy");
      this.setState({
        ...this.state,
        copyImageUrl: item,
      });
    }
  }
  handleCopyUrl() {
    navigator.clipboard.writeText(this.state.copyImageUrl);
    this.setState({
      ...this.state,
      copyImageUrl: "",
    });
    this.handleCloseGalleryImageModal();
  }

  pageChange = (event, value) => {
    this.setState({ ...this.state, pageNumber: value });
  };

  getPageCount(length) {
    var count = 0;

    count = Math.ceil(length / 6);

    return count;
  }

  getSlice(pageNumber) {
    var slice = 0;
    var count = this.getPageCount(this.state.galleryImage.length);

    if (count !== 0 && pageNumber !== 0) {
      slice = pageNumber * 6;
    }

    return slice;
  }

  render() {
    // const mdParser = new MarkdownIt({
    //   html: true,
    //   xhtmlOut: true,
    //   breaks: true,
    //   highlight: true,
    //   langPrefix: true,
    //   linkify: true,
    //   quotes: true,
    //   typographer: true,
    // });

    // const plugins = [
    //   "header",
    //   "font-bold",
    //   "font-italic",
    //   "font-underline",
    //   "font-strikethrough",
    //   "list-unordered",
    //   "list-ordered",
    //   "block-quote",
    //   "block-wrap",
    //   "block-code-inline",
    //   "table",
    //   "image",
    //   "link",
    //   "clear",
    //   "logger",
    //   "mode-toggle",
    //   "full-screen",
    //   "tab-insert",
    // ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/live/post/all",
                    }}
                  >
                    Live Posts
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Add Live Post
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12">
            <h3>Add Live Post</h3>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 mb-2">
                <label htmlFor="title">Article Title *</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  aria-describedby="helpId"
                  placeholder="Title"
                  value={this.state.title}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <label htmlFor="category">Article Category *</label>
                <select
                  className="form-control"
                  name=""
                  id="category"
                  value={this.state.category}
                  onChange={this.handleChange}
                >
                  <option value="">Select Category...</option>
                  {this.state.categoryList
                    .filter((x) => x.type === this.props.match.params.type)
                    .map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="isSchedule">Schedule *</label>
                <select
                  className="form-control"
                  name=""
                  id="isSchedule"
                  value={this.state.isSchedule}
                  onChange={this.handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              {this.state.isSchedule == "1" ? (
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="isSchedule">Schedule DateTime *</label>
                  <input
                    className="form-control"
                    id="scheduleDateTime"
                    type="datetime-local"
                    min={moment().format("YYYY-MM-DD HH:MM")}
                    value={this.state.scheduleDateTime}
                    onChange={this.handleChange}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="isSchedule">Featured *</label>
                <select
                  className="form-control"
                  name=""
                  id="isFeatured"
                  value={this.state.isFeatured}
                  onChange={this.handleChange}
                >
                  <option value={"1"}>Yes</option>
                  <option value={"0"}>No</option>
                </select>
              </div>
              {/* <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="author">Author *</label>
                <input
                  type="text"
                  className="form-control"
                  id="author"
                  aria-describedby="helpId"
                  placeholder="Author"
                  value={this.state.author}
                  onChange={this.handleChange}
                />
              </div> */}
              <div className="col-12 col-sm-4 mb-1">
                <label htmlFor="tags">Article Tags *</label>
                <select
                  className="form-control"
                  name=""
                  id="tags"
                  value={this.state.selectedTag}
                  onChange={this.handleTags}
                >
                  <option value="">Select Tags...</option>
                  {this.state.tagList
                    .filter((x) => x.type === this.props.match.params.type)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-12 col-sm-2 mb-1">
                <div className="row d-flex justify-content-end justify-content-md-start mt-4 mx-0">
                  <button
                    type="button"
                    className="btn primary_btn mt-2"
                    onClick={(e) => this.handleAddModelOpen()}
                  >
                    Add Tag
                  </button>
                </div>
              </div>
              <div className="col-12 col-sm-6 my-2"></div>
              <div className="col-12 col-sm-6 my-2">
                {this.state.tags.length !== 0 &&
                  this.state.tags.map((item, index) => {
                    return (
                      <Chip
                        className="mr-3 mt-2"
                        label={item}
                        onDelete={(e) => this.handleDelete(index)}
                        key={index}
                        style={{
                          backgroundColor: "#cd3235",
                          color: "white",
                        }}
                      />
                    );
                  })}
              </div>

              <div className="col-12">
                <label>Article Contents *</label>
              </div>
              <div className="col-12">
                <MDEditor
                  height={500}
                  minHeights={500}
                  value={this.state.contents}
                  onChange={this.handleEditorChange}
                  previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                  }}
                  style={{
                    whiteSpace: "pre-wrap",
                    backgroundColor: "white",
                    color: "black",
                  }}
                />
                {/* <MdEditor
                  style={{ height: "500px" }}
                  renderHTML={(text) => mdParser.render(text)}
                  onChange={this.handleEditorChange}
                  onImageUpload={this.handleImageUpload}
                  canView={{ fullScreen: false }}
                  plugins={plugins}
                /> */}
              </div>
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="thumbnailImage">
                  Upload Image (Cover / Thumbnail) *
                </label>

                <button
                  type="button"
                  className="btn primary_btn ml-2 mt-2"
                  onClick={this.handleAddImageModal}
                >
                  Image Upload
                </button>
              </div>

              <div className="col-12 col-sm-6  my-2 mb-3">
                <label htmlFor="thumbnailImage">Image Gallery </label>
                <button
                  type="button"
                  className="btn primary_btn ml-2 mt-2"
                  onClick={this.handleGalleryImageModal}
                >
                  Images
                </button>
              </div>
              <div className="col-12 my-3">
                {this.state.thumbnail.length !== 0 ? (
                  <img
                    className="post-img-prev"
                    src={this.state.thumbnail}
                    alt="[Image Not Available]"
                  />
                ) : null}
              </div>
              <div className="col-12 d-flex justify-content-center mt-4 mb-4">
                {this.state.spinner ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.isError ? (
                  <div
                    className="alert alert-danger w-100 text-center"
                    role="alert"
                  >
                    {this.state.errorMsg}
                  </div>
                ) : null}
                {this.state.isValid ? (
                  <div
                    className="alert alert-success w-100 text-center"
                    role="alert"
                  >
                    {this.state.validMsg}
                  </div>
                ) : null}
              </div>
              <div className="col-12 d-flex justify-content-end mt-4">
                <button
                  type="button"
                  className="btn primary_btn add-btn-size"
                  onClick={(e) => this.handleSubmit()}
                  disabled={this.state.isbtnDisable}
                >
                  Add
                </button>
              </div>

              <div className="col-12 mt-4">
                <h4>Content Preview</h4>
                <hr />
              </div>
              <div className="col-12" style={{ wordBreak: "break-all" }}>
                <MDEditor.Markdown
                  source={this.state.contents}
                  style={{
                    whiteSpace: "pre-wrap",
                    backgroundColor: "white",
                    color: "black",
                  }}
                />
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.contents,
                  }}
                ></div> */}
              </div>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.isAdd}
          onClose={this.handleAddModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          disableBackdropClick={true}
        >
          <Fade in={this.state.isAdd}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h5>Add Tag</h5>
                  </div>
                  <div className="col-12 col-sm-6 mt-3">
                    <label htmlFor="name">Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={this.state.add.name}
                      onChange={this.handleAddChange}
                      aria-describedby="helpId"
                      placeholder="Tag Name"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3">
                    <label htmlFor="type">Type *</label>
                    <select
                      className="form-control"
                      name=""
                      id="type"
                      value={this.state.add.type}
                      onChange={this.handleAddChange}
                    >
                      <option value="">Select...</option>
                      <option value={"sinhala"}>Sinhala</option>
                      <option value={"tamil"}>Tamil</option>
                      <option value={"english"}>English</option>
                    </select>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3 mx-0">
                    {this.state.modalSpinner && (
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                    {this.state.modalIsError ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-danger w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalErrorMsg}
                        </div>
                      </div>
                    ) : null}
                    {this.state.modalIsValid ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-success w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalValidMsg}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 row d-flex justify-content-end mt-3 mx-0">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleAddTag()}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleAddModelClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        <Fragment>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.imageModal}
            onClose={this.handleCloseImageModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999999,
            }}
            disableBackdropClick={true}
          >
            <Fade in={this.state.imageModal}>
              <div className="card modal-card">
                <div className="card-body pb-0">
                  <div className="row mx-0">
                    <div className="col-10 p-0">
                      <h4> Image Upload</h4>
                    </div>
                    <div className="col-2 p-0" align="center">
                      <button
                        type="button"
                        className="close"
                        onClick={this.handleCloseImageModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>

                  <div
                    className="card-body"
                    style={{
                      overflowY: "auto",
                      overflowX: "auto",
                      height: `${this.state.src ? "460px" : "auto"}`,
                    }}
                  >
                    <div className="w-100 mx-0">
                      <input
                        className="w-100"
                        type="file"
                        accept="image/*"
                        //  value={this.state.thumbnailImage}
                        onChange={this.handleImage}
                      />
                      <p style={{ fontSize: "13px" }}>
                        Aspect ratio: 1:1
                        <small className="form-text text-secondary mb-1">
                          Max file size: 5 MB
                        </small>
                      </p>
                    </div>
                    <div className="row mt-3 mx-0">
                      <div className="col-md-6 p-1">
                        {this.state.src && (
                          <Cropper
                            style={{ height: 400, width: "100%" }}
                            zoomTo={0.1}
                            initialAspectRatio={16 / 16}
                            preview=".img-preview"
                            src={this.state.src}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            aspectRatio={16 / 16}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                              this.setState({
                                ...this.state,
                                cropper: instance,
                              });
                            }}
                            guides={true}
                          />
                        )}
                      </div>
                      <div className="col-md-6 p-1">
                        {this.state.croppedImageUrl && (
                          <img
                            alt="Crop"
                            style={{
                              maxWidth: "100%",
                              objectFit: "contain",
                            }}
                            src={this.state.croppedImageUrl}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 text-center">
                    {this.state.imageShowResponse && this.state.isImageError ? (
                      <div
                        className="alert alert-danger d-flex justify-content-center text-center mt-3 mb-3"
                        role="alert"
                      >
                        {this.state.responseMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="modal-footer p-0">
                    <div className="row d-flex justify-content-end mx-1 my-2">
                      <button
                        type="button"
                        className="btn btn-secondary mx-1"
                        onClick={this.handleCloseImageModal}
                      >
                        Close
                      </button>
                      {this.state.src && (
                        <button
                          type="button"
                          className="btn btn-success mx-1"
                          onClick={this.getCropData}
                        >
                          Crop
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-primary mx-1"
                        onClick={this.handleSaveImageModal}
                        disabled={this.state.croppedImageUrl === ""}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </Fragment>
        <Fragment>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.imageGalleryModal}
            onClose={this.handleCloseGalleryImageModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999999,
            }}
            disableBackdropClick={true}
          >
            <Fade in={this.state.imageGalleryModal}>
              <div className="card modal-card">
                <div className="card-body pb-0">
                  <div className="row mx-0">
                    <div className="col-10 p-0">
                      <h4> Gallery Management </h4>
                    </div>
                    <div className="col-2 p-0" align="center">
                      <button
                        type="button"
                        className="close"
                        onClick={this.handleCloseGalleryImageModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  {!this.state.addImageToGallery ? (
                    <Fragment>
                      <div
                        className="card-body"
                        style={{
                          overflowY: "auto",
                          overflowX: "auto",
                          height: `${this.state.src ? "460px" : "auto"}`,
                        }}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <a
                              className="btn btn-warning btn-sm lp-btn"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  addImageToGallery: true,
                                });
                              }}
                            >
                              Add Image
                            </a>
                          </div>

                          <div className="col-md-12  ">
                            {this.state.modalGallerySpinner && (
                              <div className="col-12 mb-2 d-flex justify-content-center">
                                <div
                                  className="spinner-border text-danger"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            )}
                            {!this.state.modalGallerySpinner &&
                              this.state.galleryImage.length <= 0 && (
                                <p class="d-flex justify-content-center my-3">
                                  There are no images.
                                </p>
                              )}

                            <div className="row mx-0">
                              {this.state.galleryImage.length > 0 &&
                                this.state.galleryImage
                                  .slice(
                                    this.getSlice(this.state.pageNumber) - 6,
                                    this.getSlice(this.state.pageNumber)
                                  )
                                  .map((item, index) => {
                                    return (
                                      <div className="col-md-2 img-col p-2">
                                        <div
                                          class="img-col-div"
                                          onClick={() =>
                                            this.getImageUrl(
                                              API_BASE_URL + item.path
                                            )
                                          }
                                        >
                                          <img
                                            src={API_BASE_URL + item.path}
                                            width="100%"
                                            height="100%"
                                            style={{ objectFit: "contain" }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                            </div>
                            <div className="row mx-0 d-flex justify-content-end">
                              <Pagination
                                current={this.state.pageNumber}
                                total={this.getPageCount(
                                  this.state.galleryImage.length
                                )}
                                onPageChange={(e) => {
                                  this.setState({
                                    ...this.state,
                                    pageNumber: e,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="row d-flex justify-content-center">
                              <div className="col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="copyImageUrl"
                                  value={this.state.copyImageUrl}
                                  disabled={true}
                                />
                              </div>
                              <div className="col-md-12 d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-warning mt-3"
                                  disabled={this.state.copyImageUrl === ""}
                                  onClick={() => this.handleCopyUrl()}
                                >
                                  Click me to copy current Url
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}

                  {this.state.addImageToGallery ? (
                    <Fragment>
                      <div
                        className="card-body"
                        style={{
                          overflowY: "auto",
                          overflowX: "auto",
                          height: `${this.state.src ? "460px" : "auto"}`,
                        }}
                      >
                        <div className="w-100 mx-0">
                          <input
                            className="w-100"
                            type="file"
                            accept="image/*"
                            //  value={this.state.thumbnailImage}
                            onChange={this.handleGalleryImage}
                          />
                          <p style={{ fontSize: "13px" }}>
                            Aspect ratio: 1:1
                            <small className="form-text text-secondary mb-1">
                              Max file size: 5 MB
                            </small>
                          </p>
                        </div>
                        <div className="row mt-3 mx-0">
                          <div className="col-md-6 p-1">
                            {this.state.gallerySrc && (
                              <Cropper
                                style={{ height: 400, width: "100%" }}
                                zoomTo={0.1}
                                // initialAspectRatio={16 / 16}
                                preview=".img-preview"
                                src={this.state.gallerySrc}
                                viewMode={1}
                                minCropBoxHeight={1024}
                                minCropBoxWidth={1024}
                                cropBoxResizable={false}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                // aspectRatio={16 / 16}
                                checkOrientation={false}
                                onInitialized={(instance) => {
                                  this.setState({
                                    ...this.state,
                                    galleryCropper: instance,
                                  });
                                }}
                                guides={true}
                              />
                            )}
                          </div>
                          <div
                            className="col-md-6 p-1"
                            style={{
                              overflow: "auto",
                              height: "auto",
                              maxHeight: "404px",
                            }}
                          >
                            {this.state.galleryCroppedImageUrl && (
                              <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                }}
                                src={this.state.galleryCroppedImageUrl}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 mt-3 text-center">
                        {this.state.gallerySpinner && (
                          <div className="col-12 mb-2 d-flex justify-content-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                        {!this.state.gallerySpinner &&
                        !this.state.galleryIsImageError &&
                        this.state.galleryResponseMessage ? (
                          <div
                            className="alert alert-success d-flex justify-content-center text-center mt-3 mb-3"
                            role="alert"
                          >
                            {this.state.galleryResponseMessage}
                          </div>
                        ) : (
                          ""
                        )}
                        {!this.state.gallerySpinner &&
                        this.state.galleryIsImageError ? (
                          <div
                            className="alert alert-danger d-flex justify-content-center text-center mt-3 mb-3"
                            role="alert"
                          >
                            {this.state.galleryResponseMessage}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="modal-footer p-0">
                        <div className="row d-flex justify-content-end mx-1 my-2">
                          <button
                            type="button"
                            className="btn btn-secondary mx-1"
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                addImageToGallery: false,
                              });
                            }}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary mx-1"
                            onClick={this.handleCloseGalleryImageModal}
                          >
                            Close
                          </button>
                          {this.state.gallerySrc && (
                            <button
                              type="button"
                              className="btn btn-success mx-1"
                              onClick={this.getCropGalleryImageData}
                            >
                              Crop
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn btn-primary mx-1"
                            onClick={this.handleSaveGalleryImageModal}
                            disabled={this.state.galleryCroppedImageUrl === ""}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Fade>
          </Modal>
        </Fragment>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.livePostStore.addPost,
    appState: state.appStore.refreshToken,
    categoryListState: state.categoryStore.categoryList,
    tagListState: state.tagStore.tagsList,
    addState: state.tagStore.tag,
    checkUserPermissionState: state.usersStore.checkUserPermission,
    galleryState: state.galleryStore.galleryImages,
    saveGalleryState: state.galleryStore.galleryImage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addLivePost: (data) => dispatch(addLivePost(data)),
  getAllCategory: () => dispatch(getAllCategory()),
  refreshToken: () => dispatch(refreshToken()),
  getAllTags: () => dispatch(getAllTags()),
  addTag: (data) => dispatch(addTag(data)),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
  getAllGalleryImages: () => dispatch(getAllGalleryImages()),
  saveGalleryImage: (data) => dispatch(saveGalleryImage(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddLivePost)
);
