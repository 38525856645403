import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../Login/index";
import Verify from "../Verify/index";
import ResetPassword from "../ResetPassword/index";

function AuthRoutes(props) {
  let status = props.status;
  let setStatus = props.setStatus;
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={(props) => (
          <Login {...props} status={status} setStatus={setStatus} />
        )}
      />
      <Route
        exact
        path="/verify/:email/:token"
        render={(props) => (
          <Verify {...props} status={status} setStatus={setStatus} />
        )}
      />
      <Route
        exact
        path="/reset-password/:email"
        render={(props) => (
          <ResetPassword {...props} status={status} setStatus={setStatus} />
        )}
      />
    </Switch>
  );
}

export default AuthRoutes;
