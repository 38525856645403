import React, { useState } from "react";

import Navbar from "./Navbar/index";
import Footer from "./Footer/index";
import Routes from "./Routes/index";
import SideBar from "./SideBar/index";
import AuthRoutes from "./Routes/auth";
import "./App.css";
import { Fragment } from "react";
import jwt_decode from "jwt-decode";
import Citizen from "../img/logo02.png";
// import moment from "moment";
let auth = false;
let isRedirect = true;
var token = window.localStorage.getItem("refreshToken");
if (token && token !== undefined && token !== "undefined") {
  var decoded = jwt_decode(token);
  var utcSeconds = decoded.exp;
  var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(utcSeconds);
  window.localStorage.setItem("role", decoded.data.role);
  var userPermission = window.localStorage.getItem("CIZUP");
  if (d > new Date()) {
    auth = true;
  } else {
    auth = false;
  }
} else {
  if (window.location.pathname !== "/") {
    window.location.href = "/";
  }
}

function App() {
  const [status, setStatus] = useState(auth);
  const [expanded, setExpanded] = useState(true);

  return (
    <Fragment>
      {status ? (
        <div className="row d-flex m-0">
          {/* {userPermission == undefined || userPermission.length == 0 ? (
            <div className="loading-div d-flex justify-content-center align-items-center m-auto">
              <img className="circular-image" src={Citizen} alt="Logo" />
            </div>
          ) : (
            <Fragment> */}
          <div
            className={
              expanded ? "col-2 m-0 px-0 side-nav" : "col-1 m-0 px-0 side-nav"
            }
          >
            <SideBar setExpanded={setExpanded} />
          </div>
          <div
            className={expanded ? "col-10 p-0 " : "col-11 p-0 "}
            style={{
              marginLeft: "auto",
            }}
          >
            <header>
              <Navbar setStatus={setStatus} />
            </header>
            <div className="main-container mx-0">
              <main>
                <Routes setStatus={setStatus} />
              </main>
            </div>
          </div>
          {/* </Fragment>
          )} */}
        </div>
      ) : (
        <AuthRoutes status={status} setStatus={setStatus} />
      )}
    </Fragment>
  );
}

export default App;
