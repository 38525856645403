import React, { Fragment, PureComponent } from "react";
import jwt_decode from "jwt-decode";
import "./index.css";
import { withRouter } from "react-router";

class NavBar extends PureComponent {
  constructor(props) {
    super(props);
  }

  logout = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("name");
    window.localStorage.removeItem("role");
    window.localStorage.removeItem("languages");
    window.localStorage.setItem("CIZUP", []);
    this.props.history.push("/", []);
    // window.location.href = "/";

    this.props.setStatus(false);
  };
  render() {
    let username = "";
    var token = window.localStorage.getItem("accessToken");
    if (token && token !== undefined && token !== "undefined") {
      var decoded = jwt_decode(token);
      username = decoded.data.name;
    }
    return (
      <Fragment>
        <div className="navbar-wrapper">
          <div className="row">
            <div className="col-12 col-sm-4">
              <h3 className="d-none d-sm-block">Admin Dashboard</h3>
            </div>
            <div className="col-12 col-sm-8 d-flex justify-content-end my-auto flex-row">
              <p
                className="my-auto mr-4 profile-link"
                onClick={(e) => this.props.history.push("/profile")}
              >
                <i
                  className="fa fa-user-circle-o fa-lg mr-2"
                  aria-hidden="true"
                ></i>
                Profile
              </p>
              <button
                type="button"
                className="btn primary_btn my-auto"
                style={{ border: "1px solid white" }}
                onClick={(e) => this.logout()}
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(NavBar);
