import {
  ACTION_GET_ALL_INFO,
  ACTION_ADD_INFO,
  ACTION_GET_INFO_BY_ID,
  ACTION_EDIT_INFO,
  ACTION_DELETE_INFO,
} from "../../constants/actions";

const initialState = {
  infos: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  info: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editInfo: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  deleteInfo: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};

export default function infoStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_INFO: {
      _state = {
        ...state,
        infos: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_ADD_INFO: {
      _state = {
        ...state,
        info: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
        infos: {
          ...state.infos,
          success: false,
          isLoading: false,
          isError: false,
        },
      };
      break;
    }

    case ACTION_GET_INFO_BY_ID: {
      _state = {
        ...state,
        info: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }

    case ACTION_EDIT_INFO: {
      _state = {
        ...state,
        editInfo: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        infos: {
          ...state.infos,
          success: false,
          isLoading: false,
          isError: false,
        },
      };
      break;
    }

    case ACTION_DELETE_INFO: {
      _state = {
        ...state,
        deleteInfo: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        infos: {
          ...state.infos,
          data: state.infos.data.filter((x) => x.Id !== action.data),
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
