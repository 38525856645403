const checkMinLength = (value, length) => value.length <= length;

const checkMaxLength = (value, length) => value.length > length;

export const isErrorObjectEmpty = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => {
    if (val.length > 0) {
      valid = false;
    }
  });
  return valid;
};

export const validateInputs = (id, value, _errors) => {
  let errors = _errors;

  const validEmailRegex = RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  );

  const numberRegex = RegExp(/^[0-9]*$/);

  const specialCharacters = RegExp(/[^a-zA-Z0-9]/);

  if (id === "username") {
    if (value === "") {
      errors[id] = "Username cannot be empty!";
    } else if (numberRegex.test(value)) {
      errors[id] = "Username cannot be only numbers!";
    } else if (validEmailRegex.test(value)) {
      errors[id] = "Username cannot be email format!";
    } else if (specialCharacters.test(value)) {
      errors[id] = "Username cannot include special characters!";
    } else if (checkMinLength(value, 3)) {
      errors[id] = "Username should be more than 3 characters!";
    } else if (checkMaxLength(value, 10)) {
      errors[id] = "Username should be less than or equal to 10 characters!";
    } else {
      errors[id] = "";
    }
  }

  // if (id === "username") {
  //   if (checkMinLength(value, 0)) {
  //     errors[id] = "Username cannot be empty!";
  //   } else {
  //     errors[id] = "";
  //   }
  // }

  if (id === "firstName") {
    if (checkMinLength(value, 0)) {
      errors[id] = "First Name cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "lastName") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Last Name cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "email") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Email cannot be empty!";
    } else if (!validEmailRegex.test(value)) {
      errors[id] = "Invalid email address!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "phone") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Phone number cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  // if (id === "password") {
  //   if (checkMinLength(value, 0)) {
  //     errors[id] = "Password cannot be empty!";
  //   } else {
  //     errors[id] = "";
  //   }
  // }

  // if (id === "confirmPassword") {
  //   if (checkMinLength(value, 0)) {
  //     errors[id] = "confirm password cannot be empty!";
  //   } else {
  //     errors[id] = "";
  //   }
  // }

  return errors;
};
