import {
  ACTION_GET_ALL_TAG,
  ACTION_ADD_TAG,
  ACTION_EDIT_TAG,
  ACTION_DELETE_TAG,
} from "../../constants/actions";

const initialState = {
  tagsList: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  tag: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editTag: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  deleteTag: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};

export default function tagStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_TAG: {
      if (action.status) {
        _state = {
          ...state,
          tagsList: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      } else {
        _state = {
          ...state,
          tagsList: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      }
      break;
    }

    case ACTION_ADD_TAG: {
      _state = {
        ...state,
        tag: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
        tagsList: {
          ...state.tagsList,
          success: false,
          isLoading: false,
          isError: false,
          data: [],
        },
      };
      break;
    }

    case ACTION_EDIT_TAG: {
      _state = {
        ...state,
        editTag: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        tagsList: {
          ...state.tagsList,
          success: false,
          isLoading: false,
          isError: false,
          data: [],
        },
      };
      break;
    }

    case ACTION_DELETE_TAG: {
      _state = {
        ...state,
        deleteTag: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        tagsList: {
          ...state.tagsList,
          data: state.tagsList.data.filter((x) => x.Id !== action.data),
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
