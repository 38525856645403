const checkMinLength = (value, length) => value.length === length;

// const checkMaxLength = (value, length) => value.length > length;

// const validEmailRegex = RegExp(
//   /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
// );
// const checkNumber = RegExp("[0-9]");

var format = /[`@#%^&*()_+\=\[\]{};'"\\<>\/?~]/;

export const isErrorObjectEmpty = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => {
    if (val.length > 0) {
      valid = false;
    }
  });
  return valid;
};

export const validateInputs = (id, value, _errors) => {
  let errors = _errors;

  if (id === "Title") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Title cannot be empty!";
    }
    // else if (format.test(value)) {
    //   errors[id] = "Title cannot contain any special characters!";
    // }
    else {
      errors[id] = "";
    }
  }

  if (id === "Source") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Source cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Description") {
    var replaceValue = value.replace(/<[^>]*>/g, "");

    if (checkMinLength(replaceValue, 0)) {
      errors[id] = "Description cannot be empty!";
    } else if (replaceValue === "\n") {
      errors[id] = "Description cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Origin") {
    var replaceValue = value.replace(/<[^>]*>/g, "");

    if (checkMinLength(replaceValue, 0)) {
      errors[id] = "Origin cannot be empty!";
    } else if (replaceValue === "\n") {
      errors[id] = "Origin cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Verification") {
    let replaceValue = value.replace(/<[^>]*>/g, "");

    if (checkMinLength(replaceValue, 0)) {
      errors[id] = "Verification cannot be empty!";
    } else if (replaceValue === "\n") {
      errors[id] = "Verification cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "DateCreated") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Date Created cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Thumbnail") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Image required for the article!";
    } else {
      errors[id] = "";
    }
  }
  return errors;
};
