import React, { PureComponent, Fragment } from "react";
import ReactPlayer from "react-player/youtube";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getVideoById } from "../../../store/actions/video-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import moment from "moment";
import { Link } from "react-router-dom";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
class ViewVideo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: { Title: "", Author: "", URL: "", DateCreated: "", Language: "" },
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("2");
    window.scrollTo(0, 0);
    var post = this.props.videosState.data.filter(
      (x) => x.Id === this.props.match.params.id
    );
    if (post.length !== 0 && this.props.videosState.success) {
      this.setState({
        ...this.state,
        data: post[0],
        success: true,
      });
    } else {
      this.props.getVideoById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (this.props.videoState !== prevProps.videoState) {
      if (this.props.videoState.success && this.props.videoState.status === 1) {
        this.setState({
          ...this.state,
          data: this.props.videoState.data[0],
          success: true,
        });
      } else if (this.props.videoState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getVideoById(this.props.match.params.name);
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  render() {
    const { Title, Category, Author, DateCreated, URL, Language } =
      this.state.data;
    return (
      <Fragment>
        {!this.state.success ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-sm-12 mb-3 mb-sm-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/",
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/videos/all",
                      }}
                    >
                      Videos
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active p-0"
                    aria-current="page"
                  >
                    View Video
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-12 mb-4">
              <h3>View Video Details</h3>
            </div>
            <div className="col-md-6">
              <p>
                <b>Title:</b> {Title}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <b>Category:</b> {Category}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Author:</b> {Author}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <b>Date Created:</b> {moment(DateCreated).format("DD-MM-YYYY")}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <b>Language:</b> {Language}
              </p>
            </div>
            <div className="col-md-12">
              <p className="mb-3">
                <b>Video</b>
              </p>
              {URL.length !== 0 ? (
                <ReactPlayer url={URL} controls width="100%" height="480px" />
              ) : null}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    videoState: state.videoStore.video,
    videosState: state.videoStore.videos,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVideoById: (data) => dispatch(getVideoById(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewVideo)
);
