import React, { PureComponent } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import Home from "../Home/index";
import ViewAllPosts from "../Posts/index";
import ViewPost from "../Posts/View/index";
import AddPost from "../Posts/Add/index";
import EditPost from "../Posts/Edit/index";
import ViewAllVideo from "../Videos/index";
import AddVideo from "../Videos/Add/index";
import ViewVideo from "../Videos/View/index";
import EditVideo from "../Videos/Edit/index";
import ViewAllEmployees from "../Employees/index";
import ViewEmployee from "../Employees/View/index";
import AddEmployee from "../Employees/Add/index";
import EditEmployee from "../Employees/Edit/index";
import LiveNews from "../LiveNews/index";
import AddLiveNews from "../LiveNews/Add/index";
import ViewLiveNews from "../LiveNews/View/index";
import EditLiveNews from "../LiveNews/Edit/index";
import AllFactCheck from "../FactCheck/index";
import ViewFact from "../FactCheck/View/index";
import AddFactCheck from "../FactCheck/Add/index";
import EditFactCheck from "../FactCheck/Edit/index";
import AllFactCheckArticles from "../FactCheckArticle/index";
import ViewFactCheckArticle from "../FactCheckArticle/View/index";
import AddFactCheckArticle from "../FactCheckArticle/Add/index";
import EditFactCheckArticle from "../FactCheckArticle/Edit/index";
import UserProfile from "../Profile/index";
import ViewAllUsers from "../Users/index";
import ViewUser from "../Users/View/index";
import EditUser from "../Users/Edit/index";
import ViewAllLivePosts from "../LivePosts/index";
import AddLivePost from "../LivePosts/Add/index";
import ViewLivePost from "../LivePosts/View/index";
import EditLivePost from "../LivePosts/Edit/index";
import ViewAllCategory from "../Category/index";
import ViewAllAds from "../Ad/index";
import SaveAd from "../Ad/Add/index";
import EditAd from "../Ad/Edit/index";
import ViewAd from "../Ad/View/index";
import InfoBoard from "../Dashboard/index";
import AddInfo from "../Dashboard/Add/index";
import EditInfo from "../Dashboard/Edit/index";
import ViewInfo from "../Dashboard/View/index";
import AccessDenied from "../AccessDeniedPage/index";
import UserPermissions from "../UserPermissions";
import GalleryManagement from "../GalleryManagement";

class Routes extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/posts/all"
          render={(props) => (
            <ViewAllPosts {...props} setStatus={this.props.setStatus} />
          )}
        />
        {/* <Route exact path="/posts/add" component={AddPost} /> */}
        <Route
          exact
          path="/posts/:type/:name"
          render={(props) => (
            <ViewPost {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/posts/add/e/:type"
          render={(props) => (
            <AddPost {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/posts/e/:type/:id"
          render={(props) => (
            <EditPost {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/videos/all"
          render={(props) => (
            <ViewAllVideo {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/videos/add"
          render={(props) => (
            <AddVideo {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/videos/:id"
          render={(props) => (
            <ViewVideo {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/videos/e/:id"
          render={(props) => (
            <EditVideo {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/employees/all"
          render={(props) => (
            <ViewAllEmployees {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/employees/:id"
          render={(props) => (
            <ViewEmployee {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/employees/s/add"
          render={(props) => (
            <AddEmployee {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/employees/e/:id"
          render={(props) => (
            <EditEmployee {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/livenews"
          render={(props) => (
            <LiveNews {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/livenews/add"
          render={(props) => (
            <AddLiveNews {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/livenews/:id"
          render={(props) => (
            <ViewLiveNews {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/livenews/e/:id"
          render={(props) => (
            <EditLiveNews {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/factcheck/all"
          render={(props) => (
            <AllFactCheck {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          path="/factcheck/add/:type"
          render={(props) => (
            <AddFactCheck {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/factcheck/:id"
          render={(props) => (
            <ViewFact {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/factcheck/e/:type/:id"
          render={(props) => (
            <EditFactCheck {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/factcheckarticle/all"
          render={(props) => (
            <AllFactCheckArticles {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/factcheckarticle/add"
          render={(props) => (
            <AddFactCheckArticle {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/factcheckarticle/:id"
          render={(props) => (
            <ViewFactCheckArticle {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/factcheckarticle/e/:id"
          render={(props) => (
            <EditFactCheckArticle {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/users/all"
          render={(props) => (
            <ViewAllUsers {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/users/e/:id"
          render={(props) => (
            <EditUser {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/users/:id"
          render={(props) => (
            <ViewUser {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/live/post/all"
          render={(props) => (
            <ViewAllLivePosts {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/live/post/add/:type"
          render={(props) => (
            <AddLivePost {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/live/post/:id"
          render={(props) => (
            <ViewLivePost {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/live/post/e/:type/:id"
          render={(props) => (
            <EditLivePost {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/category"
          render={(props) => (
            <ViewAllCategory {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/ad/all"
          render={(props) => (
            <ViewAllAds {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/ad/add"
          render={(props) => (
            <SaveAd {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/ad/e/:id"
          render={(props) => (
            <EditAd {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/ad/:id"
          render={(props) => (
            <ViewAd {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/profile"
          render={(props) => (
            <UserProfile {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/info/all"
          render={(props) => (
            <InfoBoard {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/info/add"
          render={(props) => (
            <AddInfo {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/info/e/:id"
          render={(props) => (
            <EditInfo {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route
          exact
          path="/info/:id"
          render={(props) => (
            <ViewInfo {...props} setStatus={this.props.setStatus} />
          )}
        />
        <Route
          exact
          path="/gallery-management"
          render={(props) => (
            <GalleryManagement {...props} setStatus={this.props.setStatus} />
          )}
        />

        <Route exact path="/access-denied" component={AccessDenied}></Route>
        <Route
          exact
          path="/user-permission"
          component={UserPermissions}
        ></Route>
      </Switch>
    );
  }
}

export default withRouter(Routes);
