import {
  ACTION_GET_ALL_LIVE_POSTS,
  ACTION_ADD_LIVE_POST,
  ACTION_GET_LIVE_POST_BY_ID,
  ACTION_EDIT_LIVE_POST,
  ACTION_DELETE_LIVE_POST,
} from "../../constants/actions";

const initialState = {
  livePostList: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  viewPost: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  addPost: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editPost: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  deletePost: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
    state: "",
  },
};

export default function livePostStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_LIVE_POSTS: {
      if (action.status) {
        _state = {
          ...state,
          livePostList: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      } else {
        _state = {
          ...state,
          livePostList: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      }
      break;
    }
    case ACTION_ADD_LIVE_POST: {
      _state = {
        ...state,
        addPost: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
        livePostList: {
          ...state.livePostList,
          success: false,
          isLoading: false,
          isError: false,
          data: [],
        },
      };
      break;
    }
    case ACTION_EDIT_LIVE_POST: {
      _state = {
        ...state,
        editPost: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
        livePostList: {
          ...state.livePostList,
          success: false,
          isLoading: false,
          isError: false,
          data: [],
        },
      };
      break;
    }
    case ACTION_GET_LIVE_POST_BY_ID: {
      _state = {
        ...state,
        viewPost: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_DELETE_LIVE_POST: {
      _state = {
        ...state,
        deletePost: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
          state: action.state,
        },
        livePostList: {
          ...state.livePostList,
          data: state.livePostList.data.filter((x) => x.Id !== action.data),
        },
      };
      break;
    }
    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
