const checkMinLength = (value, length) => value.length === length;

// const checkMaxLength = (value, length) => value.length > length;

// const validEmailRegex = RegExp(
//   /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
// );
// const checkNumber = RegExp("[0-9]");

export const isErrorObjectEmpty = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => {
    if (val.length > 0) {
      valid = false;
    }
  });
  return valid;
};

export const validateInputs = (id, value, _errors) => {
  let errors = _errors;

  const validPhoneNoRegex = RegExp(
    /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/
  );

  if (id === "firstName") {
    if (checkMinLength(value, 0)) {
      errors[id] = "First name cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "lastName") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Last name cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "email") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Email cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "phone") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Phone number cannot be empty!";
    } else if (!validPhoneNoRegex.test(value)) {
      errors[id] = "Invalid phone number.";
    } else {
      errors[id] = "";
    }
  }

  return errors;
};
