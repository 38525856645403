import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getAllFactCheck,
  deleteFactCheck,
} from "../../store/actions/fact-check-action";
import { refreshToken } from "../../store/actions/app-state-actions";
import AllFactCheckArticles from "../FactCheckArticle/index";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import moment from "moment";
import { WEB_BASE_URL } from "../../../src/constants/api";
import { checkUserPermission } from "../../store/actions/users-state-actions";
import { getAllAuthors } from "../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";
class AllFactCheck extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        author: "",
        title: "",
        dateFrom: "",
        dateTo: "",
      },
      fackCheck: [],
      success: false,
      open: false,
      id: 0,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      isLoading: true,
      pageSize: 10,
      responseMessage: "",
      showResponse: false,
      isSinhala: true,
      isEnglish: false,
      isTamil: false,
      active: "isSinhala",
      authorList: [],
    };

    this.checkLanguage = this.checkLanguage.bind(this);
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("6");
    if (!this.props.factCheckState.success) {
      this.props.getAllFactCheck(0, 1000000);
    } else {
      this.setState({
        ...this.state,
        fackCheck:
          this.props.factCheckState.data.length > 0
            ? this.props.factCheckState.data.filter(
                (item) => item.Language === "Sinhala"
              )
            : [],
        success: true,
        isLoading: false,
      });
    }

    this.props.getAllAuthors().then(() => {
      if (this.props.authorState.success && this.props.authorState.data) {
        this.setState({
          ...this.state,
          authorList:
            this.props.authorState.data.length !== 0
              ? this.props.authorState.data
              : [],
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (this.props.factCheckState !== prevProps.factCheckState) {
      if (
        this.props.factCheckState.success &&
        this.props.factCheckState.token === 1
      ) {
        if (
          this.props.factCheckState.status &&
          this.props.factCheckState.data.length > 10
        ) {
          // this.props.getAllFactCheck(0, 1000000);
        }
        this.setState({
          ...this.state,
          fackCheck:
            this.props.factCheckState.data.length > 0
              ? this.props.factCheckState.data.filter(
                  (item) => item.Language === "Sinhala"
                )
              : [],
          success: true,
          isLoading: false,
        });
      } else if (this.props.factCheckState.token === -1) {
        this.props.refreshToken();
      }
    }

    if (this.props.deleteState !== prevProps.deleteState) {
      if (
        this.props.deleteState.success &&
        this.props.deleteState.status === 1
      ) {
        this.setState(
          { ...this.state, spinner: false, isLoading: true },
          () => {
            this.showSuccess();
            this.props.getAllFactCheck(0, 1000000);
          }
        );
      } else if (this.props.deleteState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.deleteState.isError) {
        this.setState({ ...this.state, spinner: false }, () => {
          this.showError("Failed to delete selected fact check article.");
        });
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        // this.props.getAllFactCheck(0, 1000000);
        if (this.props.deleteState.status === -1) {
          this.handleDelete();
        }
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleEnglishPosts = (prev, next) => {
    if (
      !this.props.factCheckState.success &&
      !this.props.factCheckState.isLoading
    ) {
      this.props.getAllPosts(0, 50);
      this.setState({
        ...this.state,
        [prev]: false,
        [next]: true,
        active: next,
        postList: [],
      });
    } else {
      this.setState({
        ...this.state,
        fackCheck:
          this.props.factCheckState.data.length > 0
            ? this.props.factCheckState.data.filter(
                (item) => item.Language === "English"
              )
            : [],
        success: true,
        [prev]: false,
        [next]: true,
        active: next,
      });
    }
  };

  handleTamilPosts = (prev, next) => {
    if (
      !this.props.factCheckState.success &&
      !this.props.factCheckState.isLoading
    ) {
      this.setState({
        ...this.state,
        [prev]: false,
        [next]: true,
        active: next,
        postList: [],
      });
    } else {
      this.setState({
        ...this.state,
        fackCheck:
          this.props.factCheckState.data.length > 0
            ? this.props.factCheckState.data.filter(
                (item) => item.Language === "Tamil"
              )
            : [],
        success: true,
        [prev]: false,
        [next]: true,
        active: next,
      });
    }
  };

  handleSinhalaPosts = (prev, next) => {
    if (
      !this.props.factCheckState.success &&
      !this.props.factCheckState.isLoading
    ) {
      this.setState({
        ...this.state,
        [prev]: false,
        [next]: true,
        active: next,
        postList: [],
      });
    } else {
      this.setState({
        ...this.state,
        fackCheck:
          this.props.factCheckState.data.length > 0
            ? this.props.factCheckState.data.filter(
                (item) => item.Language === "Sinhala"
              )
            : [],

        success: true,
        [prev]: false,
        [next]: true,
        active: next,
      });
    }
  };

  handleDelete = () => {
    var obj = {
      id: this.state.id,
    };
    this.setState({ ...this.state, spinner: true });
    this.props.deleteFactCheck(obj);
  };

  handleEditRedirect = (id) => {
    if (this.state.isSinhala) {
      // window.location.href = WEB_BASE_URL + `/factcheck/e/sinhala/` + id;
      this.props.history.push(`/factcheck/e/sinhala/` + id);
    }
    if (this.state.isTamil) {
      // window.location.href = WEB_BASE_URL + `/factcheck/e/tamil/` + id;
      this.props.history.push(`/factcheck/e/tamil/` + id);
    }
    if (this.state.isEnglish) {
      // window.location.href = WEB_BASE_URL + `/factcheck/e/english/` + id;
      this.props.history.push(`/factcheck/e/english/` + id);
    }

    //this.props.history.push(`/factcheck/e/` + id);
  };

  handleAddRedirect = (name) => {
    this.props.history.push(`/factcheck/add/` + name);
  };

  handleRedirect = (id) => {
    this.props.history.push(`/factcheck/` + id);
  };

  getRows = () => {
    var arr =
      this.state.success &&
      this.state.fackCheck &&
      this.state.fackCheck
        .filter((v, i, a) => a.findIndex((t) => t.Id === v.Id) === i)
        .sort(
          (a, b) =>
            new Date(b.DateCreated).getTime() -
            new Date(a.DateCreated).getTime()
        )
        .map((item, index) => {
          item.id = index + 1;
          return item;
        });
    return arr;
  };

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = (id) => {
    this.setState({ ...this.state, open: true, id: id });
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Article has been deleted successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          this.handleCloseModal();
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  }

  handlePageSize = (value) => {
    this.setState({ ...this.state, pageSize: value });
  };

  filterAuthor = (item) => {
    if (this.state.data.author !== "0" && this.state.data.author !== "") {
      return item.Author.toLowerCase().includes(
        this.state.data.author.toLowerCase()
      );
    } else {
      return item;
    }
  };

  filterTitle = (item) => {
    if (this.state.data.title !== "") {
      return item.Title.toLowerCase().includes(
        this.state.data.title.toLowerCase()
      );
    } else {
      return item;
    }
  };

  filterDate = (item) => {
    if (this.state.data.dateFrom !== "" && this.state.data.dateTo !== "") {
      return (
        moment(item.DateCreated).utc().format("YYYY-MM-DD") >=
          moment(this.state.data.dateFrom).utc().format("YYYY-MM-DD") &&
        moment(item.DateCreated).utc().format("YYYY-MM-DD") <=
          moment(this.state.data.dateTo).utc().format("YYYY-MM-DD")
      );
    } else {
      return item;
    }
  };

  handleReset = () => {
    if (this.state.active === "isSinhala") {
      this.setState({
        data: {
          ...this.state.data,
          author: "",
          title: "",
          dateFrom: "",
          dateTo: "",
        },
        fackCheck: this.props.factCheckState.data,
        search: false,
      });
    }
    if (this.state.active === "isEnglish") {
      this.setState({
        data: {
          ...this.state.data,
          author: "",
          title: "",
          dateFrom: "",
          dateTo: "",
        },
        fackCheck: this.props.factCheckState.data,
        search: false,
      });
    }
    if (this.state.active === "isTamil") {
      this.setState({
        data: {
          ...this.state.data,
          author: "",
          title: "",
          dateFrom: "",
          dateTo: "",
        },
        fackCheck: this.props.factCheckState.data,
        search: false,
      });
    }
  };

  checkDateRange = () => {
    let today = new Date();
    if (
      this.state.data.dateFrom.length !== 0 &&
      this.state.data.dateTo.length !== 0
    ) {
      if (
        moment(this.state.data.dateTo).utc().format("YYYY-MM-DD") <
        moment(this.state.data.dateFrom).utc().format("YYYY-MM-DD")
      ) {
        return false;
      }
    }

    return true;
  };

  checkDates = () => {
    if (this.state.data.dateFrom === "" && this.state.data.dateTo !== "") {
      return false;
    }

    if (this.state.data.dateFrom !== "" && this.state.data.dateTo === "") {
      return false;
    }

    return true;
  };

  handleSubmit = () => {
    if (this.checkSearchParameters()) {
      if (!this.checkDates()) {
        this.setState(
          {
            responseMessage:
              "You need to insert value in both (Date From) and (Date To) fields to search records by date range.",
            showResponse: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                responseMessage: "",
                showResponse: false,
              });
            }, 2500);
          }
        );
        return false;
      }

      if (!this.checkDateRange()) {
        this.setState(
          {
            responseMessage:
              "(Date From) value cannot be greater than (Date To) value.",
            showResponse: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                responseMessage: "",
                showResponse: false,
              });
            }, 2500);
          }
        );
        return false;
      }

      if (this.state.active === "isSinhala") {
        var items = this.props.factCheckState.data
          .filter(this.filterAuthor)
          .filter(this.filterTitle)
          .filter(this.filterDate);
      }

      if (this.state.active === "isEnglish") {
        var items = this.props.factCheckState.data
          .filter(this.filterAuthor)
          .filter(this.filterTitle)
          .filter(this.filterDate);
      }

      if (this.state.active === "isTamil") {
        var items = this.props.factCheckState.data
          .filter(this.filterAuthor)
          .filter(this.filterTitle)
          .filter(this.filterDate);
      }

      this.setState({ fackCheck: items });
    }
  };

  checkSearchParameters() {
    for (var key in this.state.data) {
      if (
        this.state.data[key] !== "" &&
        this.state.data[key] !== "0" &&
        this.state.data[key] !== null
      ) {
        return true;
      }
    }
    return false;
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [id]: value,
      },
    });
  };

  checkLanguage(val) {
    var isExist = false;
    var role = window.localStorage.getItem("role");
    var languages = window.localStorage.getItem("languages");
    var languagesArr = JSON.parse(languages) ? JSON.parse(languages) : [];

    if (role === "Editor") {
      isExist = languagesArr.split(",").some((l) => l === val);
      return isExist;
    } else {
      isExist = true;
    }

    return isExist;
  }

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 80 },
      {
        field: "Title",
        headerName: "Title",
        width: 320,
        sortable: true,
      },
      {
        field: "Author",
        headerName: "Author",
        sortable: true,
        width: 120,
      },
      {
        field: "Status",
        headerName: "Status",
        sortable: true,
        width: 150,

        renderCell: (params) => (
          <Fragment>
            {params.row.Status === "1" ? (
              <button
                type="button"
                className="btn btn-block btn-sm verified-btn"
              >
                Verified
              </button>
            ) : null}
            {params.row.Status === "2" ? (
              <button
                type="button"
                className="btn btn-block btn-sm progress-btn"
              >
                In Progress
              </button>
            ) : null}
            {params.row.Status === "3" ? (
              <button type="button" className="btn btn-block btn-sm false-btn">
                False/Misleading
              </button>
            ) : null}
          </Fragment>
        ),
      },
      {
        field: "DateCreated",
        headerName: "Date Created",
        width: 150,
        sortable: true,
        valueFormatter: (params) => {
          return moment(params.row.DateCreated).format("DD-MM-YYYY");
        },
      },
      {
        field: "action",
        headerName: "Action",
        sortable: true,
        width: 210,

        renderCell: (params) => (
          <Fragment>
            <button
              type="button"
              className="btn btn-dark mr-2"
              onClick={(e) => this.handleRedirect(params.row.Slug)}
            >
              View
            </button>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={(e) => this.handleEditRedirect(params.row.Slug)}
            >
              Update
            </button>
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleOpenModal(params.row.Id)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Fact Checks
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12 col-sm-4 mb-3 mb-sm-0">
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) =>
                this.handleAddRedirect(
                  this.state.isEnglish
                    ? "english"
                    : this.state.isSinhala
                    ? "sinhala"
                    : "tamil"
                )
              }
            >
              Add Fact Check
            </button>
          </div>
          <div className="col-12 col-sm-8 d-flex justify-content-end">
            <div
              className="btn-group"
              role="group"
              aria-label=""
              style={{ height: "34px" }}
            >
              {this.checkLanguage("Sinhala") && (
                <button
                  type="button"
                  className={
                    this.state.isSinhala
                      ? "btn primary_btn active-post"
                      : "btn primary_btn"
                  }
                  onClick={(e) =>
                    this.handleSinhalaPosts(this.state.active, "isSinhala")
                  }
                >
                  Sinhala
                </button>
              )}
              {this.checkLanguage("Tamil") && (
                <button
                  type="button"
                  className={
                    this.state.isTamil
                      ? "btn primary_btn active-post"
                      : "btn primary_btn"
                  }
                  onClick={(e) =>
                    this.handleTamilPosts(this.state.active, "isTamil")
                  }
                >
                  Tamil
                </button>
              )}
              {this.checkLanguage("English") && (
                <button
                  type="button"
                  className={
                    this.state.isEnglish
                      ? "btn primary_btn active-post"
                      : "btn primary_btn"
                  }
                  onClick={(e) =>
                    this.handleEnglishPosts(this.state.active, "isEnglish")
                  }
                >
                  English
                </button>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={this.state.data.title}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  placeholder="Title"
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="author">Author</label>
                <select
                  className="form-control"
                  id="author"
                  value={this.state.data.author}
                  onChange={this.handleChange}
                >
                  <option value={0}>Please Select</option>
                  {this.state.authorList &&
                    this.state.authorList
                      .sort((a, b) => a.FirstName.localeCompare(b.FirstName))
                      .map((author, index) => {
                        return (
                          <option
                            key={index}
                            value={author.FirstName + " " + author.LastName}
                          >
                            {author.FirstName + " " + author.LastName}
                          </option>
                        );
                      })}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="title">Date From</label>
                <input
                  type="date"
                  className="form-control"
                  id="dateFrom"
                  value={this.state.data.dateFrom}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="title">Date To</label>
                <input
                  type="date"
                  className="form-control"
                  id="dateTo"
                  value={this.state.data.dateTo}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>
              <div className="col-12 mt-3 flex-row d-flex justify-content-end">
                <button
                  type="button"
                  className="btn primary_btn mr-2"
                  onClick={() => this.handleSubmit()}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => this.handleReset()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 d-flex justify-content-center">
            <div className="mt-3 text-center">
              {this.state.showResponse ? (
                <div className="alert alert-danger mt-3 mb-3" role="alert">
                  {this.state.responseMessage}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12 mb-3">
            <h3>View All Fact Checks</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ width: "100%" }}>
              {this.state.isLoading ? (
                <div className="row d-flex justify-content-center">
                  <div
                    className="spinner-border text-danger"
                    role="status"
                    style={{ width: "3rem", height: "3rem" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <DataGrid
                  rows={this.getRows() || []}
                  columns={columns}
                  disableColumnMenu
                  pageSize={this.state.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    this.handlePageSize(newPageSize)
                  }
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  disableSelectionOnClick={true}
                  autoHeight
                />
              )}
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h4>
                      Are you sure that you want to delete this fact check?
                    </h4>
                  </div>
                  <div className="col-12 my-2">
                    {this.state.spinner ? (
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.isError ? (
                      <div
                        className="alert alert-danger w-100 text-center"
                        role="alert"
                      >
                        {this.state.errorMsg}
                      </div>
                    ) : null}
                    {this.state.isValid ? (
                      <div
                        className="alert alert-success w-100 text-center"
                        role="alert"
                      >
                        {this.state.validMsg}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleDelete()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleCloseModal()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.appStore.refreshToken,
    deleteState: state.factCheckStore.deleteFactCheck,
    factCheckState: state.factCheckStore.factCheckList,
    authorState: state.usersStore.authors,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllFactCheck: (from, to) => dispatch(getAllFactCheck(from, to)),
  deleteFactCheck: (id) => dispatch(deleteFactCheck(id)),
  refreshToken: () => dispatch(refreshToken()),
  getAllAuthors: () => dispatch(getAllAuthors()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllFactCheck)
);
