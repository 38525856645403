import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  editInfo,
  getInfoById,
} from "../../../store/actions/info-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import { Link } from "react-router-dom";
import * as validator from "../validations";
import moment from "moment";
import { API_STORAGE_BASE_URL } from "../../../constants/api";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";

class EditInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Title: "",
        Subtitle: "",
        Thumbnail: "",
        ThumbnailImage: "",
        Option01: "",
        Value01: "",
        Option02: "",
        Value02: "",
        Option03: "",
        Value03: "",
        Option04: "",
        Value04: "",
        Status: false,
        DateCreated: "",
      },
      errors: {
        Title: "",
        Subtitle: "",
        Thumbnail: "",
        Option01: "",
        Value01: "",
        Option02: "",
        Value02: "",
        Option03: "",
        Value03: "",
        Option04: "",
        Value04: "",
        DateCreated: "",
      },
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      viewImage: "",
      articleImg: "",
      imgName: "",
      isbtnDisable: false,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    var post = this.props.infoListState.data.filter(
      (x) => x.Id === this.props.match.params.id.toString()
    );
    if (post.length !== 0 && this.props.infoListState.success) {
      this.setState({
        ...this.state,
        data: post[0],
        data: {
          Title: post[0].Title,
          Subtitle: post[0].Subtitle,
          Thumbnail: API_STORAGE_BASE_URL + post[0].Thumbnail,
          ThumbnailImage: post[0].ThumbnailImage,
          Option01: post[0].Option01,
          Value01: post[0].Value01,
          Option02: post[0].Option02,
          Value02: post[0].Value02,
          Option03: post[0].Option03,
          Value03: post[0].Value03,
          Option04: post[0].Option04,
          Value04: post[0].Value04,
          Status: post[0].Status,
          DateCreated: post[0].DateCreated,
          Id: post[0].Id,
        },
        success: true,
      });
    } else {
      this.props.getInfoById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editState !== this.props.editState) {
      if (this.props.editState.success && this.props.editState.status === 1) {
        this.showSuccess();
      } else if (
        this.props.editState.success &&
        this.props.editState.status === -1
      ) {
        this.props.refreshToken();
      } else if (
        !this.props.editState.isLoading &&
        this.props.editState.isError
      ) {
        this.showError("Failed to add new information. Please try again.");
      }
    }

    if (prevProps.infoState !== this.props.infoState) {
      if (this.props.infoState.success && this.props.infoState.status === 1) {
        this.setState({
          ...this.state,
          // data: this.props.infoState.data[0],
          data: {
            Title: this.props.infoState.data[0].Title,
            Subtitle: this.props.infoState.data[0].Subtitle,
            Thumbnail:
              API_STORAGE_BASE_URL + this.props.infoState.data[0].Thumbnail,
            ThumbnailImage: this.props.infoState.data[0].ThumbnailImage,
            Option01: this.props.infoState.data[0].Option01,
            Value01: this.props.infoState.data[0].Value01,
            Option02: this.props.infoState.data[0].Option02,
            Value02: this.props.infoState.data[0].Value02,
            Option03: this.props.infoState.data[0].Option03,
            Value03: this.props.infoState.data[0].Value03,
            Option04: this.props.infoState.data[0].Option04,
            Value04: this.props.infoState.data[0].Value04,
            Status: this.props.infoState.data[0].Status,
            DateCreated: this.props.infoState.data[0].DateCreated,
            Id: this.props.infoState.data[0].Id,
          },

          success: true,
        });
      } else if (
        this.props.infoState.success &&
        this.props.infoState.status === -1
      ) {
        this.props.refreshToken();
      } else if (this.props.infoState.isError) {
        this.showError("Failed to update information. Please try again.");
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success && this.props.appState.status === 1) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );

        if (this.props.infoState.status === -1) {
          this.props.getInfoById(this.props.match.params.id);
        }

        if (this.props.editState.status === -1) {
          this.handleAdd();
        }
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      data: { ...this.state.data, [id]: value },
    });
  };

  handleAdd = () => {
    for (let [id, value] of Object.entries(this.state.data)) {
      this.validateInputAndSetState(id, value);
    }

    const isValid = validator.isErrorObjectEmpty(this.state.errors);
    if (isValid) {
      var fromData = {
        id: this.props.match.params.id,
        title: this.state.data.Title,
        subtitle: this.state.data.Subtitle,
        option01: this.state.data.Option01,
        option02: this.state.data.Option02,
        option03: this.state.data.Option03,
        option04: this.state.data.Option04,
        value01: this.state.data.Value01,
        value02: this.state.data.Value02,
        value03: this.state.data.Value03,
        value04: this.state.data.Value04,
        thumbnail:
          this.state.articleImg.length !== 0
            ? this.state.articleImg
            : this.state.data.Thumbnail.replace(API_STORAGE_BASE_URL, ""),
        thumbnailImage:
          this.state.imgName.length !== 0
            ? this.state.imgName
            : this.state.data.ThumbnailImage,
        status: this.state.data.Status,
        dateCreated: this.state.data.DateCreated,
      };

      this.props.editInfo(fromData);
      this.setState({ ...this.state, spinner: true, isbtnDisable: true });
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async getImage(file) {
    await this.toBase64(file).then((res) => {
      this.setState({
        ...this.state,
        articleImg: res,
      });
    });
  }

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  handleImage = (e) => {
    let image = e.target.files[0];
    if (image) {
      const file = image.type.split("/").pop().toLowerCase();
      if (file === "jpeg" || file === "jpg" || file === "png") {
        if (image.size <= 5242880) {
          this.getImage(e.target.files[0]).then(() => {
            this.setState({
              ...this.state,
              data: {
                ...this.state.data,
                Thumbnail: URL.createObjectURL(e.target.files[0]),
                ThumbnailImage: e.target.value,
              },
              imgName: e.target.files[0].name,
              viewImage: e.target.files[0],
            });
          });
        } else {
          this.showError("The file is too large. Allowed maximum size is 5MB.");
        }
      } else {
        this.showError(
          "This file type is not allowed. Accepted file types are: JPEG, JPG or PNG."
        );
      }
    }
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Information has been updated successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
            isbtnDisable: false,
          });
          this.props.history.push("/info/all");
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
            isbtnDisable: false,
          });
        }, 3500);
      }
    );
  }

  render() {
    const {
      Title,
      Subtitle,
      Option01,
      Option02,
      Option03,
      Option04,
      Value01,
      Value02,
      Value03,
      Value04,
      Thumbnail,
    } = this.state.data;
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/info/all",
                    }}
                  >
                    Info Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Update Information
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12 col-sm-11">
            <div className="row">
              <div className="col-12">
                <h3>Update Information</h3>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Title">Title ({Title.length}/250) *</label>
                <textarea
                  type="text"
                  rows={3}
                  className="form-control"
                  id="Title"
                  aria-describedby="helpId"
                  placeholder="Title"
                  value={Title}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Subtitle">
                  Subtitle ({Subtitle.length}/250) *
                </label>
                <textarea
                  type="text"
                  rows={3}
                  className="form-control"
                  id="Subtitle"
                  aria-describedby="helpId"
                  placeholder="Subtitle"
                  value={Subtitle}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="Option01">Option 01 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Option01"
                  aria-describedby="helpId"
                  placeholder="Option 01"
                  value={Option01}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="Value01">Value 01 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Value01"
                  aria-describedby="helpId"
                  placeholder="Value 01"
                  value={Value01}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="Option02">Option 02 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Option02"
                  aria-describedby="helpId"
                  placeholder="Option 02"
                  value={Option02}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="Value02">Value 02 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Value02"
                  aria-describedby="helpId"
                  placeholder="Value 02"
                  value={Value02}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="Option03">Option 03 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Option03"
                  aria-describedby="helpId"
                  placeholder="Option 03"
                  value={Option03}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="Value03">Value 03 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Value03"
                  aria-describedby="helpId"
                  placeholder="Value 03"
                  value={Value03}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="Option04">Option 04 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Option04"
                  aria-describedby="helpId"
                  placeholder="Option 04"
                  value={Option04}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="Value04">Value 04 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Value04"
                  aria-describedby="helpId"
                  placeholder="Value 04"
                  value={Value04}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-12 mb-3">
                <label htmlFor="value04">Upload Image *</label>
                <br />
                <input
                  type="file"
                  id="ThumbnailImage"
                  className="form-control"
                  // value={this.state.data.ThumbnailImage}
                  onChange={this.handleImage}
                  aria-describedby="helpId"
                />
                <small id="helpId" className="form-text text-secondary ">
                  Max file size: 5 MB
                </small>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                {Thumbnail.length !== 0 ? (
                  <img
                    className="post-img-prev"
                    src={Thumbnail}
                    alt="Background Image"
                  />
                ) : null}
              </div>
              <div className="col-12 row d-flex justify-content-end mt-3 mb-3 mx-0">
                <div className="col-12 d-flex justify-content-center mt-4 mb-4 p-0">
                  {this.state.spinner ? (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.isError ? (
                    <div
                      className="alert alert-danger w-100 text-center"
                      role="alert"
                    >
                      {this.state.errorMsg}
                    </div>
                  ) : null}
                  {this.state.isValid ? (
                    <div
                      className="alert alert-success w-100 text-center"
                      role="alert"
                    >
                      {this.state.validMsg}
                    </div>
                  ) : null}
                </div>

                <div className="col-12 d-flex justify-content-end p-0">
                  <button
                    type="button"
                    className="btn primary_btn add-btn-size"
                    onClick={(e) => this.handleAdd()}
                    disabled={this.state.isbtnDisable}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    infoListState: state.infoStore.infos,
    infoState: state.infoStore.info,
    editState: state.infoStore.editInfo,
    appState: state.appStore.refreshToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editInfo: (data) => dispatch(editInfo(data)),
  getInfoById: (data) => dispatch(getInfoById(data)),
  refreshToken: () => dispatch(refreshToken()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditInfo)
);
