import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getAllFactCheckArticle,
  deleteFactCheckArticle,
} from "../../store/actions/fact-check-article-actions";
import { refreshToken } from "../../store/actions/app-state-actions";

import { DataGrid } from "@mui/x-data-grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import moment from "moment";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";

class AllFactCheckArticles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        author: "",
        title: "",
        dateFrom: "",
        dateTo: "",
      },
      factCheck: [],
      success: false,
      open: false,
      id: 0,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      isLoading: true,
      pageSize: 10,
      responseMessage: "",
      showResponse: false,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    if (!this.props.factCheckState.success) {
      this.props.getAllFactCheckArticle(0, 50);
    } else {
      this.setState({
        ...this.state,
        factCheck: this.props.factCheckState.data,
        success: true,
        isLoading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.factCheckState !== prevProps.factCheckState) {
      if (
        this.props.factCheckState.success &&
        this.props.factCheckState.token === 1
      ) {
        if (
          this.props.factCheckState.status &&
          this.props.factCheckState.data.length > 10
        ) {
          this.props.getAllFactCheckArticle(
            this.props.factCheckState.data.length,
            50
          );
        }
        this.setState({
          ...this.state,
          factCheck: this.props.factCheckState.data,
          success: true,
          isLoading: false,
        });
      } else if (this.props.factCheckState.token === -1) {
        this.props.refreshToken();
      }
    }

    if (this.props.deleteState !== prevProps.deleteState) {
      if (
        this.props.deleteState.success &&
        this.props.deleteState.status === 1
      ) {
        this.showSuccess();
      } else if (this.props.deleteState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.deleteState.isError) {
        this.showError("Failed to delete selected article.");
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getAllFactCheckArticle(0, 50);

        if (this.props.deleteState.status === -1) {
          this.handleDelete();
        }
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleDelete = () => {
    var obj = {
      id: this.state.id,
    };
    this.props.deleteFactCheckArticle(obj);
    this.handleCloseModal();
  };

  handleEditRedirect = (id) => {
    this.props.history.push(`/factcheckarticle/e/` + id);
  };

  handleAddRedirect = () => {
    this.props.history.push(`/factcheckarticle/add`);
  };

  handleRedirect = (id) => {
    this.props.history.push(`/factcheckarticle/` + id);
  };

  getRows = () => {
    var arr =
      this.state.success &&
      this.state.factCheck &&
      this.state.factCheck.map((item, index) => {
        item.id = index + 1;
        return item;
      });
    return arr;
  };

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = (id) => {
    this.setState({ ...this.state, open: true, id: id });
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Article has been deleted successfully.",
        isValid: true,
        spinner: false,
        factCheck: this.props.factCheckState.data,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  }

  handlePageSize = (value) => {
    this.setState({ ...this.state, pageSize: value });
  };
  filterAuthor = (item) => {
    if (this.state.data.author !== "0" && this.state.data.author !== "") {
      return item.Author.includes(this.state.data.author);
    } else {
      return item;
    }
  };

  filterTitle = (item) => {
    if (this.state.data.title !== "") {
      return item.Title.includes(this.state.data.title);
    } else {
      return item;
    }
  };

  filterDate = (item) => {
    if (this.state.data.dateFrom !== "" && this.state.data.dateTo !== "") {
      return (
        moment(item.DateCreated).utc().format("YYYY-MM-DD") >=
          this.state.data.dateFrom &&
        moment(item.DateCreated).utc().format("YYYY-MM-DD") <=
          this.state.data.dateTo
      );
    } else {
      return item;
    }
  };

  handleReset = () => {
    this.setState({
      data: {
        ...this.state.data,
        author: "",
        title: "",
        dateFrom: "",
        dateTo: "",
      },
      factCheck: this.props.factCheckState.data,
      search: false,
    });
  };

  checkDateRange = () => {
    let today = new Date();
    if (
      this.state.data.dateFrom.length !== 0 &&
      this.state.data.dateTo.length !== 0
    ) {
      if (
        moment(today).utc().format("YYYY-MM-DD") <=
          moment(this.state.data.dateFrom).utc().format("YYYY-MM-DD") ||
        moment(this.state.data.dateTo).utc().format("YYYY-MM-DD") <=
          moment(this.state.data.dateFrom).utc().format("YYYY-MM-DD")
      ) {
        return false;
      }
    }

    return true;
  };

  checkDates = () => {
    if (this.state.data.dateFrom === "" && this.state.data.dateTo !== "") {
      return false;
    }

    if (this.state.data.dateFrom !== "" && this.state.data.dateTo === "") {
      return false;
    }

    return true;
  };

  handleSubmit = () => {
    if (this.checkSearchParameters()) {
      if (!this.checkDates()) {
        this.setState(
          {
            responseMessage:
              "You need to insert value in both (Date From) and (Date To) fields to search records by date range.",
            showResponse: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                responseMessage: "",
                showResponse: false,
              });
            }, 2500);
          }
        );
        return false;
      }

      if (!this.checkDateRange()) {
        this.setState(
          {
            responseMessage:
              "(Date From) value cannot be greater than (Date To) value.",
            showResponse: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                responseMessage: "",
                showResponse: false,
              });
            }, 2500);
          }
        );
        return false;
      }

      var items = this.props.factCheckState.data
        .filter(this.filterAuthor)
        .filter(this.filterTitle)
        .filter(this.filterDate);

      this.setState({ factCheck: items });
    }
  };

  checkSearchParameters() {
    for (var key in this.state.data) {
      if (
        this.state.data[key] !== "" &&
        this.state.data[key] !== "0" &&
        this.state.data[key] !== null
      ) {
        return true;
      }
    }
    return false;
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [id]: value,
      },
    });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 100 },
      {
        field: "Title",
        headerName: "Title",
        width: 180,
        sortable: true,
        flex: 0.5,
      },
      {
        field: "Author",
        headerName: "Author",
        sortable: true,
        width: 120,
      },
      {
        field: "Category",
        headerName: "Category",
        sortable: true,
        width: 120,
        flex: 0.3,
      },
      {
        field: "DateCreated",
        headerName: "Date Created",
        width: 150,
        sortable: true,
        valueFormatter: (params) => {
          return moment(params.row.DateCreated).format("DD-MM-YYYY");
        },
      },
      {
        field: "URL",
        headerName: "URL",
        width: 150,
        sortable: true,
        renderCell: (params) => (
          <Fragment>
            <a href={params.row.URL + params.row.Id}>
              {params.row.URL + params.row.Id}
            </a>
          </Fragment>
        ),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: true,
        width: 180,
        flex: 0.5,
        renderCell: (params) => (
          <Fragment>
            <button
              type="button"
              className="btn btn-dark mr-2"
              onClick={(e) => this.handleRedirect(params.row.Id)}
            >
              View
            </button>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={(e) => this.handleEditRedirect(params.row.Id)}
            >
              Update
            </button>
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleOpenModal(params.row.Id)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn primary_btn mb-4"
              onClick={(e) => this.handleAddRedirect()}
            >
              Add Article
            </button>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-6 mt-3">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={this.state.data.title}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  placeholder="Title"
                />
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="author">Author</label>
                <input
                  type="text"
                  className="form-control"
                  id="author"
                  value={this.state.data.author}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  placeholder="Author"
                />
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="title">Date From</label>
                <input
                  type="date"
                  className="form-control"
                  id="dateFrom"
                  value={this.state.data.dateFrom}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                />
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="title">Date To</label>
                <input
                  type="date"
                  className="form-control"
                  id="dateTo"
                  value={this.state.data.dateTo}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                />
              </div>
              <div className="col-12 mt-3 flex-row d-flex justify-content-end">
                <button
                  type="button"
                  className="btn primary_btn mr-2"
                  onClick={() => this.handleSubmit()}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => this.handleReset()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 d-flex justify-content-center">
            <div className="mt-3 text-center">
              {this.state.showResponse ? (
                <div className="alert alert-danger mt-3 mb-3" role="alert">
                  {this.state.responseMessage}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12 mb-3">
            <h3>View All Fact Check Articles</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ width: "100%" }}>
              {this.state.isLoading ? (
                <div className="row d-flex justify-content-center">
                  <div
                    className="spinner-border text-danger"
                    role="status"
                    style={{ width: "3rem", height: "3rem" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <DataGrid
                  rows={this.getRows() || []}
                  columns={columns}
                  disableColumnMenu
                  pageSize={this.state.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    this.handlePageSize(newPageSize)
                  }
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  disableSelectionOnClick={true}
                  autoHeight
                />
              )}
            </div>
          </div>
          <div className="col-12">
            {this.state.spinner ? (
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.isError ? (
              <div
                className="alert alert-danger w-100 text-center"
                role="alert"
              >
                {this.state.errorMsg}
              </div>
            ) : null}
            {this.state.isValid ? (
              <div
                className="alert alert-success w-100 text-center"
                role="alert"
              >
                {this.state.validMsg}
              </div>
            ) : null}
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h4>Are you sure that you want to delete this article?</h4>
                  </div>
                  <div className="col-12 d-flex justify-content-end my-2">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleDelete()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleCloseModal()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.appStore.refreshToken,
    deleteState: state.factCheckArticleStore.deleteFactCheck,
    factCheckState: state.factCheckArticleStore.factCheckList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllFactCheckArticle: (from, to) =>
    dispatch(getAllFactCheckArticle(from, to)),
  deleteFactCheckArticle: (id) => dispatch(deleteFactCheckArticle(id)),
  refreshToken: () => dispatch(refreshToken()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllFactCheckArticles)
);
