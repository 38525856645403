import {
  ACTION_GET_ALL_ENGLISH_POSTS,
  ACTION_GET_ALL_SINHALA_POSTS,
  ACTION_GET_ALL_TAMIL_POSTS,
  ACTION_ADD_POSTS,
  ACTION_VIEW_POSTS,
  ACTION_EDIT_POSTS,
  ACTION_DELETE_POST,
  ACTION_DASHBOARD_DATA,
  ACTION_USER_DASHBOARD_DATA,
} from "../../constants/actions";

const initialState = {
  englishPosts: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
  },
  sinhalaPosts: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
  },
  tamilPosts: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
  },
  viewPost: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  addPost: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editPost: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  deletePost: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
    state: "",
  },
  img: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
    state: "",
  },
  dashboard: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
    state: "",
  },
};

export default function postStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_ENGLISH_POSTS: {
      if (action.status) {
        _state = {
          ...state,
          englishPosts: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      } else {
        _state = {
          ...state,
          englishPosts: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      }
      break;
    }
    case ACTION_GET_ALL_SINHALA_POSTS: {
      if (action.status) {
        _state = {
          ...state,
          sinhalaPosts: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      } else {
        _state = {
          ...state,
          sinhalaPosts: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      }
      break;
    }
    case ACTION_GET_ALL_TAMIL_POSTS: {
      if (action.status) {
        _state = {
          ...state,
          tamilPosts: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      } else {
        _state = {
          ...state,
          tamilPosts: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      }
      break;
    }
    case ACTION_ADD_POSTS: {
      _state = {
        ...state,
        addPost: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }

    case ACTION_EDIT_POSTS: {
      _state = {
        ...state,
        editPost: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_VIEW_POSTS: {
      _state = {
        ...state,
        viewPost: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_DELETE_POST: {
      _state = {
        ...state,
        deletePost: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
          state: action.state,
        },
        [action.state]: {
          ...state[action.state],
          data: state[action.state].data.filter((x) => x.Id !== action.data),
          isLoading: false,
          isError: false,
          message: "",
          success: false,
        },
      };
      break;
    }
    case ACTION_DASHBOARD_DATA: {
      _state = {
        ...state,
        dashboard: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_USER_DASHBOARD_DATA: {
      _state = {
        ...state,
        dashboard: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
