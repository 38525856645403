import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getLivePostById } from "../../../store/actions/live-post-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import Skeleton from "@material-ui/lab/Skeleton";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import NoImg from "../../../img/no-img.jpg";
import {
  API_STORAGE_BASE_URL,
  DEFUALT_IMAGE_PLACEHOLDER,
} from "../../../constants/api";
import { Link } from "react-router-dom";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import MDEditor from "@uiw/react-md-editor";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
class ViewLivePost extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { news: [], success: false };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    this.props.checkUserPermission("5");
    // this.props.getLivePostById(this.props.match.params.id);
    var post = this.props.livePostsState.data.filter(
      (x) => x.Slug === this.props.match.params.id.toString()
    );
    if (post.length !== 0 && this.props.livePostsState.success) {
      this.setState({
        ...this.state,
        news: post[0],
        success: true,
      });
    } else {
      this.props.getLivePostById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (this.props.postState !== prevProps.postState) {
      if (this.props.postState.success && this.props.postState.status === 1) {
        this.setState({
          ...this.state,
          news: this.props.postState.data[0],
          success: true,
        });
      } else if (this.props.postState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getLivePostById(this.props.match.params.id);
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  addDefaultSrc(e) {
    e.target.src = DEFUALT_IMAGE_PLACEHOLDER;
  }

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <a href="/live/post/all"></a>
                  <Link
                    to={{
                      pathname: "/live/post/all",
                    }}
                  >
                    Live Posts
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  View Live Post
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12 col-sm-12">
            {this.state.success ? (
              <p className="article-heading">{this.state.news.Title}</p>
            ) : (
              <Skeleton variant="rect" height={144} />
            )}
            <div className="row pl-3 mx-0">
              {this.state.success ? (
                <Fragment>
                  <div className="col-sm-4 p-0 top-col">
                    <p className="text-wrap">
                      <i
                        className="fa fa-clock-o fa-lg mr-2"
                        aria-hidden="true"
                      ></i>
                      {moment
                        .utc(this.state.news.DateCreated)
                        .local("si")
                        .startOf("seconds")
                        .fromNow()}
                    </p>
                  </div>
                </Fragment>
              ) : (
                <Skeleton
                  className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                  variant="rect"
                  height={30}
                  width={150}
                />
              )}

              {this.state.success ? (
                <Fragment>
                  <div className="col-sm-4 p-0 top-col">
                    <p>
                      <i
                        className="fa fa-pencil fa-lg mr-2"
                        aria-hidden="true"
                      ></i>
                      {this.state.news.Author}
                    </p>
                  </div>
                </Fragment>
              ) : (
                <Skeleton
                  className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                  variant="rect"
                  height={30}
                  width={150}
                />
              )}
            </div>
            <div className="row">
              <div className="col-12">
                {this.state.success ? (
                  <img
                    className="post-img-prev"
                    src={
                      this.state.news.Thumbnail
                        ? API_STORAGE_BASE_URL + this.state.news.Thumbnail
                        : NoImg
                    }
                    alt="Article image not available!"
                    onError={this.addDefaultSrc}
                  />
                ) : (
                  <Skeleton variant="rect" height={430} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 mt-2 text-dark">
            <p>
              <b>Category: </b>
              {this.state.news.Category}
            </p>
          </div>
          <div className="col-12">
            {this.state.success ? (
              <p>
                <b>Featured: </b>
                {this.state.news.IsFeatured === "0" ? "No" : "Yes"}
              </p>
            ) : (
              <Skeleton
                className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                variant="rect"
                height={30}
                width={150}
              />
            )}
          </div>
          <div className="col-12 col-sm-12 text-dark">
            <p>
              <b>Tags: </b>
              {this.state.news.Tags !== undefined &&
              this.state.news.Tags !== null &&
              this.state.news.Tags.length > 0
                ? this.state.news.Tags.join(", ")
                : "-"}
            </p>
          </div>
          <div className="col-12 col-sm-12 article-text text-dark">
            {this.state.success ? (
              <Fragment>
                <MDEditor.Markdown
                  source={this.state.news.Article}
                  style={{
                    whiteSpace: "pre-wrap",
                    backgroundColor: "white",
                    color: "black",
                  }}
                />
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.news.Article,
                  }}
                  className="text-wrap"
                  style={{ lineHeight: "28px", fontSize: 14 }}
                ></div> */}
                <br />
              </Fragment>
            ) : (
              <Skeleton variant="rect" height={284} />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.livePostStore.viewPost,
    livePostsState: state.livePostStore.livePostList,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLivePostById: (id) => dispatch(getLivePostById(id)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewLivePost)
);
