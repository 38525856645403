import React, { PureComponent, Fragment } from "react";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import {
  editFactCheckArticle,
  getFactCheckArticlById,
} from "../../../store/actions/fact-check-article-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import { getAllTags } from "../../../store/actions/tag-state-actions";
import { getAllCategory } from "../../../store/actions/category-state-action";
import { withRouter } from "react-router-dom";
import htmlToDraft from "html-to-draftjs";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
import * as validator from "../validations";

class EditFactCheckArticle extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      news: {},
      success: true,
      editorState: EditorState.createEmpty(),
      data: {
        Article: "",
        Title: "",
        Author: "",
        Category: "",
        Tags: [],
        Thumbnail: "",
        ThumbnailImage: "",
        Url: "",
      },
      errors: {
        Article: "",
        Title: "",
        Author: "",
        Category: "",
      },
      isSubmit: false,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      imgName: "",
      viewImage: "",
      articleImg: "",
      tagList: [],
      categoryList: [],
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    // this.props.getFactCheckArticlById(this.props.match.params.name);
    var post = this.props.factCheckState.data.filter(
      (x) => x.Id === this.props.match.params.id.toString()
    );
    if (post.length !== 0 && this.props.factCheckState.success) {
      const blocksFromHtml = htmlToDraft(post[0].Article);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );

      this.setState({
        ...this.state,
        data: post[0],
        success: true,
        editorState: EditorState.createWithContent(contentState),
      });
    } else {
      this.props.getFactCheckArticlById(this.props.match.params.id);
    }

    if (this.props.categoryListState.success) {
      this.setState({
        categoryList: this.props.categoryListState.data,
      });
    } else {
      this.props.getAllCategory();
    }

    if (this.props.tagListState.success) {
      this.setState({
        tagList: this.props.tagListState.data,
      });
    } else {
      this.props.getAllTags();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tagListState !== this.props.tagListState) {
      if (
        this.props.tagListState.success &&
        this.props.tagListState.token === 1
      ) {
        this.setState({
          ...this.state,
          tagList: this.props.tagListState.data,
        });
      } else if (this.props.tagListState.token === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.categoryListState !== this.props.categoryListState) {
      if (
        this.props.categoryListState.success &&
        this.props.categoryListState.status === 1
      ) {
        this.setState({
          ...this.state,
          categoryList: this.props.categoryListState.data,
        });
      } else if (this.props.categoryListState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.editState !== this.props.editState) {
      if (this.props.editState.success && this.props.editState.status === 1) {
        this.showSuccess();
      } else if (this.props.editState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.postState !== this.props.postState) {
      if (
        this.props.postState.success &&
        this.props.postState.status === 1 &&
        this.props.postState.data[0]
      ) {
        const blocksFromHtml = htmlToDraft(
          this.props.postState.data[0].Article
        );
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );

        this.setState({
          ...this.state,
          data: this.props.postState.data[0],
          editorState: EditorState.createWithContent(contentState),
        });
      } else if (this.props.postState.status === -1) {
        this.props.refreshToken();
      }
    }
    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );

        if (this.props.categoryListState.status === -1) {
          this.props.getAllCategory();
        }

        if (this.props.tagListState.token === -1) {
          this.props.getAllTags();
        }

        if (this.state.isSubmit) {
          this.handleSubmit();
        }
        this.props.getFactCheckArticlById(this.props.match.params.id);
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onContentStateChange = (contentState) => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, Article: draftToHtml(contentState) },
    });
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, data: { ...this.state.data, [id]: value } });
  };

  handleImage = (e) => {
    let image = e.target.files[0];
    if (image) {
      const file = image.type.split("/").pop().toLowerCase();
      if (file === "jpeg" || file === "jpg" || file === "png") {
        if (image.size <= 5242880) {
          this.getImage(e.target.files[0]).then(() => {
            this.setState({
              ...this.state,
              data: {
                ...this.state.data,
                Thumbnail: URL.createObjectURL(e.target.files[0]),
                ThumbnailImage: e.target.value,
              },
              imgName: e.target.files[0].name,
              viewImage: e.target.files[0],
            });
          });
        } else {
          this.showError("The file is too large. Allowed maximum size is 5MB.");
        }
      } else {
        this.showError(
          "This file type is not allowed. Accepted file types are: JPEG, JPG or PNG."
        );
      }
    }
  };

  handleTags = (e) => {
    const { value } = e.target;
    this.setState({
      ...this.state,
      data: { ...this.state.data, Tags: this.state.data.Tags.concat(value) },
    });
  };

  handleDelete = (num) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        Tags: this.state.data.Tags.filter(function (item, index) {
          return index !== num;
        }),
      },
    });
  };

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Article has been updated successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  }

  handleSubmit = () => {
    for (let [id, value] of Object.entries(this.state.data)) {
      this.validateInputAndSetState(id, value);
    }

    const isValid = validator.isErrorObjectEmpty(this.state.errors);
    if (isValid) {
      var formData = {
        id: this.props.match.params.id,
        title: this.state.data.Title,
        thumbnail:
          this.state.articleImg.length !== 0
            ? this.state.articleImg
            : this.state.data.Thumbnail,
        thumbnailImage:
          this.state.imgName.length !== 0
            ? this.state.imgName
            : this.state.data.ThumbnailImage,
        url: this.state.data.Url,
        author: this.state.data.Author,
        category: this.state.data.Category,
        tags: this.state.data.Tags.toString(),
        article: this.state.data.Article,
        dateCreated: this.state.data.DateCreated,
      };
      this.setState({ ...this.state, spinner: true });
      this.props.editFactCheckArticle(formData);
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async getImage(file) {
    await this.toBase64(file).then((res) => {
      this.setState({
        ...this.state,
        articleImg: res,
      });
    });
  }

  render() {
    return (
      <Fragment>
        <div className="row mx-auto">
          <div className="col-11">
            <h3>Update Fact Check Article</h3>
          </div>
          <div className="col-11">
            <div className="row">
              <div className="col-12 mb-3">
                <label htmlFor="title">Article Title *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Title"
                  aria-describedby="helpId"
                  placeholder="Title"
                  value={this.state.data.Title}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="category">Article Category *</label>
                <select
                  className="form-control"
                  name=""
                  id="Category"
                  value={this.state.data.Category}
                  onChange={this.handleChange}
                >
                  <option value="">Select Category...</option>
                  {this.state.categoryList
                    .filter((x) => x.type === "english")
                    .map((item, index) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                </select>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Author">Author *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Author"
                  aria-describedby="helpId"
                  placeholder="Author"
                  value={this.state.data.Author}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6">
                <label htmlFor="ThumbnailImage">Upload Image *</label>
                <br />
                <input
                  type="file"
                  id="ThumbnailImage"
                  className="form-control"
                  // value={this.state.data.ThumbnailImage}
                  onChange={this.handleImage}
                  aria-describedby="helpId"
                />
                <small id="helpId" className="form-text text-secondary ">
                  Max file size: 5 MB
                </small>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="tags">Article Tags</label>
                <select
                  className="form-control"
                  name=""
                  id="tags"
                  onChange={this.handleTags}
                >
                  <option value="">Select Tags...</option>
                  {this.state.tagList
                    .filter((x) => x.type === "english")
                    .map((item, index) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                </select>
              </div>
              <div className="col-12 col-sm-6">
                {this.state.data.Thumbnail.length !== 0 ? (
                  <img
                    className="post-img-prev"
                    src={this.state.data.Thumbnail}
                    alt="[Image Not Available]"
                  />
                ) : null}
              </div>
              <div className="col-12 col-sm-6 mt-2 mb-4">
                {this.state.data.Tags &&
                  this.state.data.Tags.length !== 0 &&
                  this.state.data.Tags.map((item, index) => {
                    return (
                      <Chip
                        className="mr-3"
                        label={item}
                        onDelete={(e) => this.handleDelete(index)}
                        key={index}
                        style={{
                          backgroundColor: "#cd3235",
                          color: "white",
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="col-11">
            <label>Article Contents *</label>
          </div>
          <div className="col-11">
            <div className="card">
              <div className="card-body">
                <Editor
                  editorState={this.state.editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onContentStateChange={this.onContentStateChange}
                  onEditorStateChange={this.onEditorStateChange}
                />
              </div>
            </div>
          </div>

          <div className="col-11 d-flex justify-content-center mt-4 mb-4">
            {this.state.isError ? (
              <div
                className="alert alert-danger w-100 text-center"
                role="alert"
              >
                {this.state.errorMsg}
              </div>
            ) : this.state.spinner ? (
              <div className="row">
                <div className="col-12">
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.isValid ? (
              <div
                className="alert alert-success w-100 text-center"
                role="alert"
              >
                {this.state.validMsg}
              </div>
            ) : null}
          </div>

          <div className="col-11 d-flex justify-content-end mt-4 mb-4">
            {!this.state.spinner ? (
              <button
                type="button"
                className="btn primary_btn"
                onClick={(e) => this.handleSubmit()}
              >
                Update
              </button>
            ) : null}
          </div>

          <div className="col-11 mt-4">
            <h4>Content Preview</h4>
            <hr />
          </div>
          <div className="col-11" style={{ wordBreak: "break-all" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.data.Article,
              }}
            ></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editState: state.factCheckArticleStore.editFactCheck,
    postState: state.factCheckArticleStore.factCheck,
    factCheckState: state.factCheckArticleStore.factCheckList,
    appState: state.appStore.refreshToken,
    tagListState: state.tagStore.tagsList,
    categoryListState: state.categoryStore.categoryList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editFactCheckArticle: (data) => dispatch(editFactCheckArticle(data)),
  getFactCheckArticlById: (id) => dispatch(getFactCheckArticlById(id)),
  refreshToken: () => dispatch(refreshToken()),
  getAllCategory: () => dispatch(getAllCategory()),
  getAllTags: () => dispatch(getAllTags()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditFactCheckArticle)
);
