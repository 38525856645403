import {
  ACTION_GET_ALL_GALLERY_IMAGES,
  ACTION_SAVE_GALLERY_IMAGE,
  ACTION_DELETE_VIDEO,
} from "../../constants/actions";

const initialState = {
  galleryImages: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  galleryImage: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },

  deleteVideo: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};

export default function galleryStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_GALLERY_IMAGES: {
      if (action.status) {
        _state = {
          ...state,
          galleryImages: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      } else {
        _state = {
          ...state,
          videos: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      }
      break;
    }
    case ACTION_SAVE_GALLERY_IMAGE: {
      _state = {
        ...state,
        galleryImage: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }

    case ACTION_DELETE_VIDEO: {
      _state = {
        ...state,
        deleteVideo: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        videos: {
          ...state.videos,
          data: state.videos.data.filter((x) => x.Id !== action.data),
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
