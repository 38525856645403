import moment from "moment";
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  refreshToken,
  editProfile,
  passwordChange,
} from "../../store/actions/app-state-actions";
import {
  editEmployee,
  getEmployeeById,
} from "../../store/actions/emp-state-actions";
import { Link } from "react-router-dom";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";

class UserProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        status: "",
        dateCreated: "",
      },
      changePassword: {
        userId: 0,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      editProfile: {
        userId: 0,
        firstName: "",
        lastName: "",
        phone: "",
      },
      success: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      errorMsg: "",
      isError: false,

      successEdit: false,
      validMsgEdit: "",
      isValidEdit: false,
      spinnerEdit: false,
      errorMsgEdit: "",
      isErrorEdit: false,
    };

    this.validatePassword = this.validatePassword.bind(this);
    this.comparePassword = this.comparePassword.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.savePassword = this.savePassword.bind(this);

    this.handleProfile = this.handleProfile.bind(this);
    this.saveProfileDetails = this.saveProfileDetails.bind(this);
  }
  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    var id = window.localStorage.getItem("userId");
    var userId = localStorage.getItem("userId");
    this.setState({
      ...this.state,
      changePassword: {
        ...this.state.changePassword,
        userId: JSON.parse(userId),
      },
    });
    var user = this.props.usersState.data.filter((x) => x.Id === id);
    if (user.length !== 0 && this.props.usersState.success) {
      this.setState({
        ...this.state,
        data: user[0],
        editProfile: user[0],
        success: true,
      });
    } else {
      this.props.getEmployeeById(id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userState !== prevProps.userState) {
      if (this.props.userState.success && this.props.userState.status === 1) {
        this.setState({
          ...this.state,
          data: this.props.userState.data[0],
          editProfile: this.props.userState.data[0],
          success: true,
        });
      } else if (
        this.props.userState.status === -1 ||
        this.props.profileState.status === -1
      ) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        var id = window.localStorage.getItem("userId");
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getEmployeeById(id);
      } else {
        // window.localStorage.removeItem("login");
        // this.props.history.push("/");
        // this.props.setStatus(false);
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      changePassword: { ...this.state.changePassword, [id]: value },
    });
  };

  handleProfileChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      editProfile: { ...this.state.editProfile, [id]: value },
    });
  };

  validatePassword(value) {
    var error = "";
    const validPasswordRegex = RegExp(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
    );
    if (value === "") {
      error = "New Password is required.";
    } else if (!validPasswordRegex.test(value)) {
      error =
        "Password should be minimum eight characters, at least one upper case, one lower case, one number and one special character.";
    } else {
      error = "";
    }
    return error;
  }

  comparePassword() {
    var error = "";

    if (this.state.changePassword.confirmPassword === "") {
      error = "Confirm Password is required.";
    } else if (
      this.state.changePassword.newPassword !=
      this.state.changePassword.confirmPassword
    ) {
      error = "New password and confirm password do not match.";
    } else {
      error = "";
    }
    return error;
  }

  validatePhone = (value) => {
    let error = "";
    const validPhoneNoRegex = RegExp(
      /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/
    );

    if (value === "") {
      error = "Phone Number is required.";
    } else if (!validPhoneNoRegex.test(value)) {
      error = "Invalid phone number!";
    } else {
      error = "";
    }

    return error;
  };

  handleProfile() {
    if (
      this.state.editProfile.firstName !== "" &&
      this.state.editProfile.lastName !== "" &&
      this.validatePhone(this.state.editProfile.phone) === ""
    ) {
      this.setState({ ...this.state, spinnerEdit: true }, () => {
        this.saveProfileDetails();
      });
    } else {
      this.setState({
        ...this.state,
        validMsgEdit: "",
        isValidEdit: false,
        spinnerEdit: false,
        errorMsgEdit:
          this.state.editProfile.firstName === ""
            ? "First Name is required."
            : this.state.editProfile.lastName === ""
            ? "Last Name is required."
            : this.validatePhone(this.state.editProfile.phone) !== ""
            ? this.validatePhone(this.state.editProfile.phone)
            : "",
        isErrorEdit: true,
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          validMsgEdit: "",
          isValidEdit: false,
          spinnerEdit: false,
          errorMsgEdit: "",
          isErrorEdit: false,
        });
      }, 2500);
    }
  }

  saveProfileDetails() {
    this.props.editProfile(this.state.editProfile).then(() => {
      if (this.props.profileState.success) {
        this.setState({
          ...this.state,
          validMsgEdit: this.props.profileState.message,
          isValidEdit: true,
          spinnerEdit: false,
          errorMsgEdit: "",
          isErrorEdit: false,
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            validMsgEdit: "",
            isValidEdit: false,
            spinnerEdit: false,
            errorMsgEdit: "",
            isErrorEdit: false,
          });

          this.props.getEmployeeById(this.state.editProfile.userId);
        }, 2500);
      } else {
        if (parseInt(this.props.profileState.status) === -1) {
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("refreshToken");
          window.localStorage.removeItem("userId");
          window.localStorage.removeItem("name");
          this.props.history.push("/");
          this.props.setStatus(false);
        } else {
          this.setState({
            ...this.state,
            validMsgEdit: "",
            isValidEdit: false,
            spinnerEdit: false,
            errorMsgEdit: this.props.profileState.message,
            isErrorEdit: true,
          });
          setTimeout(() => {
            this.setState({
              ...this.state,
              validMsgEdit: "",
              isValidEdit: false,
              spinnerEdit: false,
              errorMsgEdit: "",
              isErrorEdit: false,
            });
          }, 2500);
        }
      }
    });
  }

  handlePassword() {
    if (
      this.state.changePassword.currentPassword !== "" &&
      this.validatePassword(this.state.changePassword.newPassword) === "" &&
      this.comparePassword() === ""
    ) {
      this.savePassword();
    } else {
      this.setState({
        ...this.state,
        validMsg: "",
        isValid: false,
        spinner: false,
        errorMsg:
          this.state.changePassword.currentPassword === ""
            ? "Current Password is required."
            : this.validatePassword(this.state.changePassword.newPassword)
            ? this.validatePassword(this.state.changePassword.newPassword)
            : this.comparePassword()
            ? this.comparePassword()
            : "",
        isError: true,
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          validMsg: "",
          isValid: false,
          spinner: false,
          errorMsg: "",
          isError: false,
        });
      }, 2500);
    }
  }

  savePassword() {
    this.setState({ ...this.state, spinner: true });
    this.props.passwordChange(this.state.changePassword).then(() => {
      if (this.props.changeState.success) {
        this.setState({
          ...this.state,
          validMsg: this.props.changeState.message,
          isValid: true,
          spinner: false,
          errorMsg: "",
          isError: false,
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            validMsg: "",
            isValid: false,
            spinner: false,
            errorMsg: "",
            isError: false,

            changePassword: {
              ...this.state.changePassword,
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            },
          });
        }, 2500);
      } else {
        if (parseInt(this.props.changeState.status) === -1) {
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("refreshToken");
          window.localStorage.removeItem("userId");
          window.localStorage.removeItem("name");
          this.props.history.push("/");
          this.props.setStatus(false);
        } else {
          this.setState({
            ...this.state,
            validMsg: "",
            isValid: false,
            spinner: false,
            errorMsg: this.props.changeState.message,
            isError: true,
          });
          setTimeout(() => {
            this.setState({
              ...this.state,
              validMsg: "",
              isValid: false,
              spinner: false,
              errorMsg: "",
              isError: false,
            });
          }, 2500);
        }
      }
    });
  }

  render() {
    const { username, firstName, lastName, email, phone, dateCreated } =
      this.state.data;
    return (
      <Fragment>
        {!this.state.success ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="row">
              <div className="col-12 col-sm-12 mb-3 mb-sm-0">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item p-0">
                      <Link
                        to={{
                          pathname: "/",
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active p-0"
                      aria-current="page"
                    >
                      Profile View
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-12 mb-3">
                <h3>Profile View</h3>
              </div>
              <div className="col-md-6">
                <p className="text-wrap">
                  <b>First Name: </b>
                  {firstName}
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-wrap">
                  <b>Last Name: </b>
                  {lastName}
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-wrap">
                  <b>Username: </b>
                  {username}
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-wrap">
                  <b>Email: </b>
                  {email}
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-wrap">
                  <b>Phone Number: </b>
                  {phone}
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-wrap">
                  <b>Date Created: </b>
                  {moment(dateCreated).format("DD-MM-YYYY")}
                </p>
              </div>

              <div className="col-md-12 row d-flex flex-row w-100 my-3 mx-0">
                <div className="col-md-6 col-sm-12 mx-0 pr-md-1 pl-0 p-0 my-2">
                  <div className="card row d-flex flex-row mx-0">
                    <div className="col-md-12 mb-3">
                      <h3>Edit Profile</h3>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          aria-describedby="helpId"
                          placeholder="First Name"
                          value={this.state.editProfile.firstName}
                          onChange={this.handleProfileChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          aria-describedby="helpId"
                          placeholder="Last Name"
                          value={this.state.editProfile.lastName}
                          onChange={this.handleProfileChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          aria-describedby="helpId"
                          placeholder="Phone Number"
                          value={this.state.editProfile.phone}
                          onChange={this.handleProfileChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="row w-100 d-flex flex-row justify-content-center mt-3 mx-0">
                      <div className="col-md-12 d-flex flex-row justify-content-center mx-0">
                        {this.state.isValidEdit ? (
                          <div className="alert alert-success" role="alert">
                            {this.state.validMsgEdit}
                          </div>
                        ) : this.state.isErrorEdit ? (
                          <div className="alert alert-danger" role="alert">
                            {this.state.errorMsgEdit}
                          </div>
                        ) : this.state.spinnerEdit ? (
                          <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                              <div
                                className="spinner-border text-danger"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row w-100 d-flex flex-row justify-content-end mx-0 my-2">
                      <div className="col-md-4 d-flex flex-row justify-content-end mx-0">
                        <button
                          type="button"
                          className="btn btn-block text-white"
                          onClick={this.handleProfile}
                          style={{ backgroundColor: "#CD3235", width: "150px" }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 mx-0 pl-md-1 pr-0 p-0 my-2">
                  <div className="card row d-flex flex-row mx-0">
                    <div className="col-md-12 mb-3">
                      <h3>Change Password</h3>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="currentPassword"
                          aria-describedby="helpId"
                          placeholder="Current Password"
                          value={this.state.changePassword.currentPassword}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="newPassword"
                          aria-describedby="helpId"
                          placeholder="New Password"
                          value={this.state.changePassword.newPassword}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          aria-describedby="helpId"
                          placeholder="Confirm Password"
                          value={this.state.changePassword.confirmPassword}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="row w-100 d-flex flex-row justify-content-center mt-3 mx-0 px-3">
                      {this.state.isValid ? (
                        <div className="alert alert-success" role="alert">
                          {this.state.validMsg}
                        </div>
                      ) : this.state.isError ? (
                        <div className="alert alert-danger" role="alert">
                          {this.state.errorMsg}
                        </div>
                      ) : this.state.spinner ? (
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row w-100 d-flex flex-row justify-content-end mx-0 my-2">
                      <div className="col-md-4 d-flex flex-row justify-content-end mx-0">
                        <button
                          type="button"
                          className="btn btn-block text-white"
                          onClick={this.handlePassword}
                          style={{ backgroundColor: "#CD3235", width: "150px" }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usersState: state.employeeStore.employees,
    userState: state.employeeStore.employee,
    appState: state.appStore.refreshToken,
    changeState: state.appStore.changePassword,
    profileState: state.appStore.editProfile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEmployeeById: (data) => dispatch(getEmployeeById(data)),
  refreshToken: () => dispatch(refreshToken()),
  passwordChange: (data) => dispatch(passwordChange(data)),
  editProfile: (data) => dispatch(editProfile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
);
