import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getFactCheckArticlById } from "../../../store/actions/fact-check-article-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import Skeleton from "@material-ui/lab/Skeleton";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import NoImg from "../../../img/no-img.jpg";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
import "./index.css";
class ViewFactCheckArticle extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { news: [], success: false };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    var post = this.props.factCheckState.data.filter(
      (x) => x.Id === this.props.match.params.id.toString()
    );
    if (post.length !== 0 && this.props.factCheckState.success) {
      this.setState({
        ...this.state,
        news: post[0],
        success: true,
      });
    } else {
      this.props.getFactCheckArticlById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postState !== prevProps.postState) {
      if (this.props.postState.success && this.props.postState.status === 1) {
        this.setState({
          ...this.state,
          news: this.props.postState.data[0],
          success: true,
        });
      } else if (this.props.postState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getFactCheckArticlById(this.props.match.params.id);
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleRedirect = (name) => {
    this.props.history.push(`/tags/` + name);
  };
  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-10 pl-3 pl-sm-0">
            {this.state.success ? (
              <p className="article-heading">{this.state.news.Title}</p>
            ) : (
              <Skeleton variant="rect" height={144} />
            )}
            <div className="row pl-3">
              {this.state.success ? (
                <Fragment>
                  <div className="row pl-3 article-sub-text">
                    <p className="pl-2 pr-3">
                      <i
                        className="fa fa-clock-o fa-lg mr-2"
                        aria-hidden="true"
                      ></i>
                      {moment
                        .utc(this.state.news.DateCreated)
                        .local("si")
                        .startOf("seconds")
                        .fromNow()}
                    </p>
                  </div>
                </Fragment>
              ) : (
                <Skeleton
                  className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                  variant="rect"
                  height={30}
                  width={150}
                />
              )}

              {this.state.success ? (
                <Fragment>
                  <div className="row ml-3 article-sub-text">
                    <p>
                      <i
                        className="fa fa-pencil fa-lg mr-2"
                        aria-hidden="true"
                      ></i>
                      {this.state.news.Author}
                    </p>
                  </div>
                </Fragment>
              ) : (
                <Skeleton
                  className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                  variant="rect"
                  height={30}
                  width={150}
                />
              )}
            </div>
            <div className="row">
              <div className="col-12">
                {this.state.success ? (
                  <img
                    className="post-img-prev"
                    src={
                      this.state.news.Thumbnail.length !== 0
                        ? this.state.news.Thumbnail
                        : NoImg
                    }
                    alt="Article"
                  />
                ) : (
                  <Skeleton variant="rect" height={430} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-10 article-text text-dark pr-2 pr-sm-5">
            {this.state.success ? (
              <Fragment>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.news.Article,
                  }}
                ></div>
                <br />
              </Fragment>
            ) : (
              <Skeleton variant="rect" height={284} />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    factCheckState: state.factCheckArticleStore.factCheckList,
    postState: state.factCheckArticleStore.factCheck,
    appState: state.appStore.refreshToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFactCheckArticlById: (id) => dispatch(getFactCheckArticlById(id)),
  refreshToken: () => dispatch(refreshToken()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewFactCheckArticle)
);
