import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_GET_ALL_VIDEO,
  ACTION_ADD_VIDEO,
  ACTION_GET_VIDEO_BY_ID,
  ACTION_EDIT_VIDEO,
  ACTION_DELETE_VIDEO,
} from "../../constants/actions";

const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const getAllVideos = (from, to) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_VIDEO,
    data: {},
    status: false,
    token: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getAllVideos(from, to, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        token: res.data.status,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const addVideo = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_ADD_VIDEO,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.addVideo(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getVideoById = (id) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_VIDEO_BY_ID,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getVideoById(id, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const editVideo = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_EDIT_VIDEO,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.editVideo(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const deleteVideo = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_DELETE_VIDEO,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.deleteVideo(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
