import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Link } from "react-router-dom";
import {
  editUser,
  getUserById,
} from "../../../store/actions/users-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
import * as validator from "../validations";
import moment from "moment";

class EditUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dateCreated: moment().format("YYYY-MM-DD"),
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      redirect: "",
      isbtnDisable: false,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    this.props.checkUserPermission("8");
    var post = this.props.usersState.data.filter(
      (x) => x.userId === this.props.match.params.id
    );
    if (post.length !== 0 && this.props.usersState.success) {
      this.setState({
        ...this.state,
        data: post[0],
        success: true,
      });
    } else {
      this.props.getUserById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (prevProps.editState !== this.props.editState) {
      if (this.props.editState.success && this.props.editState.status === 1) {
        this.showSuccess();
      } else if (
        this.props.editState.success &&
        this.props.editState.status === -1
      ) {
        this.props.refreshToken();
      } else if (
        !this.props.editState.isLoading &&
        this.props.editState.isError
      ) {
        this.showError("Failed to update user. Please try again.");
      }
    }

    if (this.props.userState !== prevProps.userState) {
      if (this.props.userState.success && this.props.userState.status === 1) {
        this.setState({
          ...this.state,
          data: this.props.userState.data[0],
          success: true,
        });
      } else if (this.props.userState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.editUser(this.state.data);
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      data: { ...this.state.data, [id]: value },
    });
  };

  isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  hasSpecialChar = (s) => {
    var format = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);
    if (format.test(s)) {
      return false;
    } else {
      return true;
    }
  };

  hasWhiteSpace = (s) => {
    var reWhiteSpace = new RegExp(/\s/);

    // Check for white space
    if (reWhiteSpace.test(s)) {
      //alert("Please Check Your Fields For Spaces");
      return false;
    }

    return true;
  };

  handleAdd = () => {
    for (let [id, value] of Object.entries(this.state.data)) {
      this.validateInputAndSetState(id, value);
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    const isValid = validator.isErrorObjectEmpty(this.state.errors);

    if (isValid) {
      if (!pattern.test(this.state.data.email)) {
        this.showError("Invalid email address!");
        return false;
      }
      if (
        !this.hasWhiteSpace(this.state.data.firstName) ||
        !this.hasSpecialChar(this.state.data.firstName)
      ) {
        this.showError(
          "First name cannot contain white spaces or special characters!"
        );
        return false;
      }

      if (
        !this.hasWhiteSpace(this.state.data.lastName) ||
        !this.hasSpecialChar(this.state.data.lastName)
      ) {
        this.showError(
          "Last name cannot contain white spaces or special characters!"
        );
        return false;
      }

      if (this.state.data.phone.length !== 10) {
        this.showError("Phone number must have 10 digits!");
        return false;
      }

      if (!this.isNumeric(this.state.data.phone)) {
        this.showError("Phone number contains non-numeric values!");
        return false;
      }

      this.props.editUser(this.state.data);
      this.setState({ ...this.state, spinner: true, isbtnDisable: true });
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "User has been updated successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
            redirect: "/users/all",
            isbtnDisable: false,
          });
          // this.props.history.push("/posts/all");
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
            isbtnDisable: false,
          });
        }, 3500);
      }
    );
  }

  render() {
    const { email, phone, firstName, lastName } = this.state.data;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/users/all",
                    }}
                  >
                    Users
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Update User
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-11">
            <div className="row">
              <div className="col-12">
                <h3>Update User</h3>
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="firstName">First Name *</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  aria-describedby="helpId"
                  placeholder="First Name"
                  value={firstName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="lastName">Last Name *</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  aria-describedby="helpId"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="email">Email *</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  aria-describedby="helpId"
                  placeholder="Email"
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="phone">Phone Number *</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  aria-describedby="helpId"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-center mt-4 mb-4">
                {this.state.spinner ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.isError ? (
                  <div
                    className="alert alert-danger w-100 text-center"
                    role="alert"
                  >
                    {this.state.errorMsg}
                  </div>
                ) : null}
                {this.state.isValid ? (
                  <div
                    className="alert alert-success w-100 text-center"
                    role="alert"
                  >
                    {this.state.validMsg}
                  </div>
                ) : null}
              </div>

              <div className="col-12 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn primary_btn add-btn-size"
                  onClick={(e) => this.handleAdd()}
                  disabled={this.state.isbtnDisable}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usersState: state.usersStore.users,
    userState: state.usersStore.user,
    editState: state.usersStore.editUser,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editUser: (data) => dispatch(editUser(data)),
  getUserById: (data) => dispatch(getUserById(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditUser)
);
