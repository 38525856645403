import {
  ACTION_GET_ALL_USERS,
  ACTION_GET_USER_BY_ID,
  ACTION_EDIT_USER,
  ACTION_DELETE_USER,
  ACTION_USER_BAN_UNBAN,
  ACTION_GET_ALL_AUTHORS,
  ACTION_GET_ALL_SYSTEM_FUNCTION,
  ACTION_GET_ALL_USER_PERMISSION,
  ACTION_SAVE_USER_PERMISSION,
  ACTION_UPDATE_USER_PERMISSION,
  ACTION_GET_CURRENT_USER_ROLE,
  ACTION_CHECK_USER_PERMISSION,
} from "../../constants/actions";

const initialState = {
  users: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  user: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editUser: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  deleteUser: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  userStatus: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  authors: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  systemFunctions: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  userPermissions: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  savePermission: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  role: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  checkUserPermission: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
    token: 0,
    status: false,
  },
};

export default function usersStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_USERS: {
      if (action.status) {
        _state = {
          ...state,
          users: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      } else {
        _state = {
          ...state,
          users: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: action.data,
            status: action.status,
            token: action.token,
          },
        };
      }
      break;
    }
    case ACTION_GET_USER_BY_ID: {
      _state = {
        ...state,
        user: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }

    case ACTION_EDIT_USER: {
      _state = {
        ...state,
        editUser: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        users: {
          ...state.users,
          isLoading: false,
          isError: false,
          success: false,
          data: [],
        },
      };
      break;
    }

    case ACTION_DELETE_USER: {
      _state = {
        ...state,
        deleteUser: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        users: {
          ...state.users,
          data: state.users.data.filter((x) => x.UserId !== action.data),
        },
      };
      break;
    }
    case ACTION_USER_BAN_UNBAN: {
      _state = {
        ...state,
        userStatus: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_GET_ALL_AUTHORS: {
      _state = {
        ...state,
        authors: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
          token: action.token,
        },
      };
      break;
    }
    case ACTION_GET_ALL_SYSTEM_FUNCTION: {
      _state = {
        ...state,
        systemFunctions: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
          token: action.token,
        },
      };
      break;
    }
    case ACTION_GET_ALL_USER_PERMISSION: {
      _state = {
        ...state,
        userPermissions: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
          token: action.token,
        },
      };
      break;
    }
    case ACTION_SAVE_USER_PERMISSION: {
      _state = {
        ...state,
        savePermission: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
          token: action.token,
        },
      };
      break;
    }
    case ACTION_UPDATE_USER_PERMISSION: {
      _state = {
        ...state,
        savePermission: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
          token: action.token,
        },
      };
      break;
    }
    case ACTION_GET_CURRENT_USER_ROLE: {
      _state = {
        ...state,
        role: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_CHECK_USER_PERMISSION: {
      _state = {
        ...state,
        checkUserPermission: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
