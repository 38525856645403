import React, { PureComponent, Fragment } from "react";

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { addFactCheckArticl } from "../../../store/actions/fact-check-article-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import { getAllTags } from "../../../store/actions/tag-state-actions";
import { getAllCategory } from "../../../store/actions/category-state-action";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
import * as validator from "../validations";

class AddFactCheckArticle extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      contents: "",
      title: "",
      author: "",
      category: "",
      tags: [],
      thumbnailImage: "",
      thumbnail: "",
      viewImage: "",
      articleImg: "",
      errors: {
        title: "",
        author: "",
        contents: "",
        category: "",
      },
      imgStatus: false,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      imgName: "",
      tagList: [],
      categoryList: [],
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    if (this.props.categoryListState.success) {
      this.setState({
        categoryList: this.props.categoryListState.data,
      });
    } else {
      this.props.getAllCategory();
    }

    if (this.props.tagListState.success) {
      this.setState({
        tagList: this.props.tagListState.data,
      });
    } else {
      this.props.getAllTags();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tagListState !== this.props.tagListState) {
      if (
        this.props.tagListState.success &&
        this.props.tagListState.token === 1
      ) {
        this.setState({
          ...this.state,
          tagList: this.props.tagListState.data,
        });
      } else if (this.props.tagListState.token === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.categoryListState !== this.props.categoryListState) {
      if (
        this.props.categoryListState.success &&
        this.props.categoryListState.status === 1
      ) {
        this.setState({
          ...this.state,
          categoryList: this.props.categoryListState.data,
        });
      } else if (this.props.categoryListState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.postState !== this.props.postState) {
      if (this.props.postState.success && this.props.postState.status === 1) {
        this.showSuccess();
      } else if (this.props.postState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.postState.isError) {
        this.showError("Failed to add new post. Please try again.");
      }
    }
    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );

        if (this.props.categoryListState.status === -1) {
          this.props.getAllCategory();
        }

        if (this.props.tagListState.token === -1) {
          this.props.getAllTags();
        }

        this.handleSubmit();
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onContentStateChange = (contentState) => {
    this.setState({ ...this.state, contents: draftToHtml(contentState) });
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };

  handleImage = (e) => {
    let image = e.target.files[0];
    if (image) {
      const file = image.type.split("/").pop().toLowerCase();
      if (file === "jpeg" || file === "jpg" || file === "png") {
        if (image.size <= 5242880) {
          this.setState({
            ...this.state,
            thumbnail: e.target.files[0],
            imgName: e.target.files[0].name,
            viewImage: URL.createObjectURL(e.target.files[0]),
            thumbnailImage: e.target.value,
          });
        } else {
          this.showError("The file is too large. Allowed maximum size is 5MB.");
        }
      } else {
        this.showError(
          "This file type is not allowed. Accepted file types are: JPEG, JPG or PNG."
        );
      }
    }
  };

  handleTags = (e) => {
    const { value } = e.target;
    this.setState({ ...this.state, tags: this.state.tags.concat(value) });
  };

  handleDelete = (num) => {
    this.setState({
      ...this.setState,
      tags: this.state.tags.filter(function (item, index) {
        return index !== num;
      }),
    });
  };

  handleSubmit = () => {
    for (let [id, value] of Object.entries(this.state)) {
      this.validateInputAndSetState(id, value);
    }

    const isValid = validator.isErrorObjectEmpty(this.state.errors);
    if (isValid) {
      var today = new Date();
      this.getImage().then(() => {
        var formData = {
          title: this.state.title,
          thumbnail: this.state.articleImg,
          thumbnailImage: this.state.imgName,
          url: " English  url",
          author: this.state.author,
          category: this.state.category,
          tags: this.state.tags.toString(),
          article: this.state.contents,
          dateCreated: moment(today).format("YYYY-MM-DD HH:m:s"),
        };
        this.setState({ ...this.state, spinner: true });
        this.props.addFactCheckArticl(formData);
      });
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async getImage() {
    await this.toBase64(this.state.thumbnail).then((res) => {
      this.setState({
        ...this.state,
        articleImg: res,
        spinner: true,
      });
    });
  }

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Fact check article has been added successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          // this.props.history.push("/posts/all");
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  }

  render() {
    return (
      <Fragment>
        <div className="row mx-auto">
          <div className="col-11">
            <h3>Add Fact Check Article</h3>
          </div>
          <div className="col-11">
            <div className="row">
              <div className="col-12 mb-3">
                <label htmlFor="title">Article Title *</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  aria-describedby="helpId"
                  placeholder="Title"
                  value={this.state.title}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="category">Article Category *</label>
                <select
                  className="form-control"
                  name=""
                  id="category"
                  value={this.state.category}
                  onChange={this.handleChange}
                >
                  <option value="">Select Category...</option>
                  {this.state.categoryList
                    .filter((x) => x.type === "english")
                    .map((item, index) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                </select>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="author">Author *</label>
                <input
                  type="text"
                  className="form-control"
                  id="author"
                  aria-describedby="helpId"
                  placeholder="Author"
                  value={this.state.author}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6">
                <label htmlFor="thumbnailImage">Upload Image *</label>
                <br />
                <input
                  type="file"
                  id="thumbnailImage"
                  name="thumbnailImage"
                  className="form-control"
                  value={this.state.thumbnailImage}
                  onChange={this.handleImage}
                  aria-describedby="helpId"
                />
                <small id="helpId" className="form-text text-secondary ">
                  Max file size: 5 MB
                </small>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="tags">Article Tags</label>
                <select
                  className="form-control"
                  name=""
                  id="tags"
                  onChange={this.handleTags}
                >
                  <option value="">Select Tags...</option>
                  {this.state.tagList
                    .filter((x) => x.type === "english")
                    .map((item, index) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                </select>
              </div>
              <div className="col-12 col-sm-6">
                {this.state.viewImage.length !== 0 ? (
                  <img
                    className="post-img-prev"
                    src={this.state.viewImage}
                    alt="[Image Not Available]"
                  />
                ) : null}
              </div>
              <div className="col-12 col-sm-6 mt-2 mb-2">
                {this.state.tags.length !== 0 &&
                  this.state.tags.map((item, index) => {
                    return (
                      <Chip
                        className="mr-3"
                        label={item}
                        onDelete={(e) => this.handleDelete(index)}
                        key={index}
                        style={{
                          backgroundColor: "#cd3235",
                          color: "white",
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="col-11">
            <label>Article Contents *</label>
          </div>
          <div className="col-11">
            <div className="card">
              <div className="card-body">
                <Editor
                  editorState={this.state.editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onContentStateChange={this.onContentStateChange}
                  onEditorStateChange={this.onEditorStateChange}
                />
              </div>
            </div>
          </div>

          <div className="col-11 d-flex justify-content-center mt-4 mb-4">
            {this.state.spinner ? (
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.isError ? (
              <div
                className="alert alert-danger w-100 text-center"
                role="alert"
              >
                {this.state.errorMsg}
              </div>
            ) : null}
            {this.state.isValid ? (
              <div
                className="alert alert-success w-100 text-center"
                role="alert"
              >
                {this.state.validMsg}
              </div>
            ) : null}
          </div>

          <div className="col-11 d-flex justify-content-end mt-4">
            {!this.state.spinner ? (
              <button
                type="button"
                className="btn primary_btn"
                onClick={(e) => this.handleSubmit()}
              >
                Add
              </button>
            ) : null}
          </div>

          <div className="col-11 mt-4">
            <h4>Content Preview</h4>
            <hr />
          </div>
          <div className="col-11" style={{ wordBreak: "break-all" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.contents,
              }}
            ></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.factCheckArticleStore.factCheck,
    appState: state.appStore.refreshToken,
    tagListState: state.tagStore.tagsList,
    categoryListState: state.categoryStore.categoryList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addFactCheckArticl: (data) => dispatch(addFactCheckArticl(data)),
  refreshToken: () => dispatch(refreshToken()),
  getAllCategory: () => dispatch(getAllCategory()),
  getAllTags: () => dispatch(getAllTags()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddFactCheckArticle)
);
