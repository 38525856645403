import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getFactCheckById } from "../../../store/actions/fact-check-action";
import { refreshToken } from "../../../store/actions/app-state-actions";
import "./index.css";
import moment from "moment";
import {
  API_STORAGE_BASE_URL,
  DEFUALT_IMAGE_PLACEHOLDER,
} from "../../../constants/api";
import { Link } from "react-router-dom";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import MDEditor from "@uiw/react-md-editor";

class ViewFact extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      factCheck: {
        Title: "",
        Source: "",
        Description: "",
        Origin: "",
        Verification: "",
        Author: "",
        Thumbnail: "",
        DateCreated: "",
        Status: 0,
      },
      success: false,
    };
  }
  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    this.props.checkUserPermission("6");
    var post = this.props.factCheckState.data.filter(
      (x) => x.Slug === this.props.match.params.id.toString()
    );
    if (post.length !== 0 && this.props.factCheckState.success) {
      this.setState({
        ...this.state,
        factCheck: post[0],
        success: true,
      });
    } else {
      this.props.getFactCheckById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (this.props.factState !== prevProps.factState) {
      if (this.props.factState.success && this.props.factState.status === 1) {
        this.setState({
          ...this.state,
          factCheck: this.props.factState.data[0],
          success: true,
        });
      } else if (this.props.factState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success && this.props.appState.status === 1) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getFactCheckById(this.props.match.params.id);
      } else if (
        this.props.appState.success &&
        this.props.appState.status === -1
      ) {
        // window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  addDefaultSrc(e) {
    e.target.src = DEFUALT_IMAGE_PLACEHOLDER;
  }

  render() {
    const {
      Title,
      Source,
      Description,
      Origin,
      Verification,
      Author,
      Thumbnail,
      DateCreated,
      Status,
    } = this.state.factCheck;
    return (
      <Fragment>
        {!this.state.success ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-sm-12 mb-3 mb-sm-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/",
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/factcheck/all",
                      }}
                    >
                      Fact Checks
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active p-0"
                    aria-current="page"
                  >
                    View Fact Check
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <p className="fact-check-title">{Title}</p>
                </div>
                <div className="col-12">
                  <p className="fact-check-info">
                    <b>Status: </b>
                    {parseInt(Status) == 1 ? (
                      <span class="badge badge-success text-white p-1">
                        Verified
                      </span>
                    ) : parseInt(Status) == 2 ? (
                      <span class="badge badge-warning text-white p-1">
                        In Progress
                      </span>
                    ) : (
                      <span class="badge badge-danger text-white p-1">
                        False/Misleading
                      </span>
                    )}
                  </p>
                </div>
                <div className="col-12">
                  <p className="fact-check-info">
                    <b>Verified through:</b> {Source}
                  </p>
                </div>
                <div className="col-12">
                  <p className="fact-check-info">
                    <b>Verification by:</b> {Author}, <b>on</b>&nbsp;
                    {moment(DateCreated).format("MMMM Do YYYY, hh:mm:ss A")}
                  </p>
                </div>
                <div className="col-12">
                  <MDEditor.Markdown
                    source={this.state.factCheck.Description}
                    style={{
                      whiteSpace: "pre-wrap",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  />
                  {/* <p
                    className="fact-check-article text-wrap"
                    dangerouslySetInnerHTML={{
                      __html: this.state.factCheck.Description,
                    }}
                    style={{ lineHeight: "28px" }}
                  ></p> */}
                </div>

                <div className="col-12">
                  <p className="mt-2 mb-2">
                    <b>Origin of Story</b>
                  </p>
                  <MDEditor.Markdown
                    source={this.state.factCheck.Origin}
                    style={{
                      whiteSpace: "pre-wrap",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  />
                  {/* <p
                    className="fact-check-article text-wrap"
                    dangerouslySetInnerHTML={{
                      __html: this.state.factCheck.Origin,
                    }}
                    style={{ lineHeight: "28px" }}
                  ></p> */}
                </div>
                <div className="col-12 fact-img-prev">
                  {Thumbnail && Thumbnail.length !== 0 ? (
                    <img
                      src={API_STORAGE_BASE_URL + Thumbnail}
                      alt="[Image Not Available]"
                      className="post-img-prev"
                      onError={this.addDefaultSrc}
                    />
                  ) : null}
                </div>
                <div className="col-12">
                  <p className="mt-2 mb-2">
                    <b>Verification</b>
                  </p>
                  <MDEditor.Markdown
                    source={this.state.factCheck.Verification}
                    style={{
                      whiteSpace: "pre-wrap",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  />
                  {/* <p
                    className="fact-check-article text-wrap"
                    dangerouslySetInnerHTML={{
                      __html: this.state.factCheck.Verification,
                    }}
                    style={{ lineHeight: "28px" }}
                  ></p> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    factCheckState: state.factCheckStore.factCheckList,
    factState: state.factCheckStore.factCheck,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFactCheckById: (data) => dispatch(getFactCheckById(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewFact)
);
