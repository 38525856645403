import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import appStateReducer from "./reducers/app-state-reducer";
import postStateReducer from "./reducers/post-state-reducer";
import videoStateReducer from "./reducers/video-state-reducer";
import employeeStateReducer from "./reducers/emp-state-reducer";
import liveNewsStateReducer from "./reducers/live-news-reducer";
import factCheckStateReducer from "./reducers/fact-check-reducer";
import factCheckArticleStateReducer from "./reducers/fact-check-article-reducer";
import usersStateReducer from "./reducers/users-state-reducer";
import livePostStateReducer from "./reducers/live-post-reducer";
import categoryStateReducer from "./reducers/category-state-reducer";
import tagStateReducer from "./reducers/tags-state-reducer";
import adStateReducer from "./reducers/ad-state-reducer";
import infoStateReducer from "./reducers/info-state-reducer";
import galleryStateReducer from "./reducers/gallery-state-reducer";

export const store = createStore(
  combineReducers({
    appStore: appStateReducer,
    postStore: postStateReducer,
    videoStore: videoStateReducer,
    employeeStore: employeeStateReducer,
    liveNewsStore: liveNewsStateReducer,
    factCheckStore: factCheckStateReducer,
    factCheckArticleStore: factCheckArticleStateReducer,
    usersStore: usersStateReducer,
    livePostStore: livePostStateReducer,
    categoryStore: categoryStateReducer,
    tagStore: tagStateReducer,
    adStore: adStateReducer,
    infoStore: infoStateReducer,
    galleryStore: galleryStateReducer,
  }),
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);
