import React, { Fragment } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import logo from "./image/Access-Denieds.jpg";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    checkIfJwtTokenValid();
  }

  render() {
    return (
      <Fragment>
        <div
          className="d-flex justify-content-center "
          style={{ marginTop: "100px" }}
        >
          <div className="row">
            <img
              src={logo}
              style={{
                width: "900px",
                verticalAlign: "middle",
                display: "table-cell",
                textAlign: "center",
              }}
              alt="AccessDeniedImage"
            />
          </div>
        </div>
        {/* <div className="d-flex justify-content-center">
          <p className="main-access-denied-label-text text-center  mt-5">
            Access Denied!
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <p className="access-denied-label-text text-center  mt-5">
            Sorry about that, but you do not have permission to access the
            requested page!
          </p>
        </div> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
