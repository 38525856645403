import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_AUTH_LOGIN,
  ACTION_REFRESH_TOKEN,
  ACTION_INITIAL_PASSWORD,
  ACTION_FORGOT_PASSWORD,
  ACTION_SEND_MAIL,
  ACTION_VERIFY_USER,
  ACTION_RESET_PASSWORD,
  ACTION_CHANGE_PASSWORD,
  ACTION_UPDATE_PROFILE,
} from "../../constants/actions";
const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const loginUser = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_AUTH_LOGIN, data: {} };
  dispatch(actionData);
  await AppServices.userLogin(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to login due to some server issue, please try again later.",
        success: false,
      };
    });
  dispatch(actionData);
};

export const refreshToken = () => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_REFRESH_TOKEN,
    data: {},
    status: 0,
  };
  var token = window.localStorage.getItem("refreshToken");
  await AppServices.getRefreshToken(token)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        status: res.data.status,
        data: { jwt: res.data.jwt, refreshToken: res.data.refreshToken },
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const initialPaswordChange = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_INITIAL_PASSWORD, data: {} };
  dispatch(actionData);
  await AppServices.initialPassword(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to change password due to some server issue, please try again later.",

        success: false,
      };
    });
  dispatch(actionData);
};

export const forgotPasswordChange = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_FORGOT_PASSWORD, data: {} };
  dispatch(actionData);
  await AppServices.forgotPassword(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to change password due to some server issue, please try again later.",

        success: false,
      };
    });
  dispatch(actionData);
};

export const mailSend = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_SEND_MAIL, data: {} };
  dispatch(actionData);
  await AppServices.semdMail(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to send mail due to some server issue, please try again later.",
        status: err.response && err.response.data && err.response.data.status,
        success: false,
      };
    });
  dispatch(actionData);
};

export const userVerify = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_VERIFY_USER, data: {} };
  dispatch(actionData);
  await AppServices.verifyUser(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to verify user due to some server issue, please try again later.",

        success: false,
      };
    });
  dispatch(actionData);
};

export const resetPasswordChange = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_RESET_PASSWORD, data: {} };
  dispatch(actionData);
  await AppServices.resetPassword(data)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to reset password due to some server issue, please try again later.",

        success: false,
      };
    });
  dispatch(actionData);
};

export const passwordChange = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_CHANGE_PASSWORD, data: {} };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.changePassword(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to change password due to some server issue, please try again later.",
        status: err.response && err.response.data && err.response.data.status,
        success: false,
      };
    });
  dispatch(actionData);
};

export const editProfile = (data) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_UPDATE_PROFILE, data: {} };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.updateProfile(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to update profile due to some server issue, please try again later.",
        status: err.response && err.response.data && err.response.data.status,
        success: false,
      };
    });
  dispatch(actionData);
};
