import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_GET_ALL_USERS,
  ACTION_GET_USER_BY_ID,
  ACTION_EDIT_USER,
  ACTION_DELETE_USER,
  ACTION_USER_BAN_UNBAN,
  ACTION_GET_ALL_AUTHORS,
  ACTION_GET_ALL_SYSTEM_FUNCTION,
  ACTION_GET_ALL_USER_PERMISSION,
  ACTION_SAVE_USER_PERMISSION,
  ACTION_UPDATE_USER_PERMISSION,
  ACTION_GET_CURRENT_USER_ROLE,
  ACTION_CHECK_USER_PERMISSION,
} from "../../constants/actions";

const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const getAllUsers = (from, to) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_USERS,
    data: {},
    status: false,
    token: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getAllUsers(from, to, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        token: res.data.status,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getUserById = (Id) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_USER_BY_ID,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getUserById(Id, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const editUser = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_EDIT_USER,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.editUser(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const deleteUser = (id) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_DELETE_USER,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.deleteUser(id, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: id,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const manageUserStatus = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_USER_BAN_UNBAN,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.manageUserStatus(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Failed to save category due to some server issue, please try again later.",

        success: false,
        status:
          err.response && err.response.data && err.response.data.status
            ? err.response.data.status
            : 0,
      };
    });
  dispatch(actionData);
};

export const getAllAuthors = () => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_AUTHORS,
    data: {},
    status: false,
    token: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getAllAuthors(accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        token: res.data.status,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getAllUserPermissions = () => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_USER_PERMISSION,
    data: {},
    status: false,
    token: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getAllUserPermission(accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        token: res.data.status,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getAllSystemFunctions = () => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_SYSTEM_FUNCTION,
    data: {},
    status: false,
    token: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getAllSystemFunction(accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        token: res.data.status,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const saveUserPermission = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_SAVE_USER_PERMISSION,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.saveUserPermission(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const updateUserPermission = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_UPDATE_USER_PERMISSION,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.updateUserPermission(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getCurrentUserRole = () => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_CURRENT_USER_ROLE,
    data: {},
    status: false,
    token: 0,
  };

  var email = window.localStorage.getItem("email");

  dispatch(actionData);
  await AppServices.getCurrentUserRole(email)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        token: res.data.status,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const checkUserPermission = (id) => (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_CHECK_USER_PERMISSION,
    data: {},
    status: false,
    token: 0,
  };

  dispatch(actionData);

  if (localStorage.getItem("CIZUP") !== null) {
    var arr = localStorage.getItem("CIZUP");
    if (JSON.parse(arr).length !== 0) {
      var isExist = JSON.parse(arr).find((item) => item === id);
      if (isExist === undefined) {
        actionData = {
          ...actionData,
          isLoading: false,
          isError: false,
          message: "",
          success: true,
          data: "/access-denied",
          token: true,
          status: true,
        };
        dispatch(actionData);
        // this.setState({
        //   ...this.state,
        //   redirectPage: "/access-denied",
        // });
      } else {
        actionData = {
          ...actionData,
          isLoading: false,
          isError: false,
          message: "",
          success: true,
          data: "",
          token: true,
          status: true,
        };
        dispatch(actionData);
      }
    } else {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: "",
        success: true,
        data: "",
        token: true,
        status: true,
      };
      dispatch(actionData);
    }
  } else {
    actionData = {
      ...actionData,
      isLoading: false,
      isError: false,
      message: "",
      success: true,
      data: "/access-denied",
      token: true,
      status: true,
    };
    dispatch(actionData);
    // this.setState({
    //   ...this.state,
    //   redirectPage: "/access-denied",
    // });
  }
  dispatch(actionData);
};
