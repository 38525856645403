import React, { PureComponent, Fragment } from "react";
import { refreshToken } from "../../store/actions/app-state-actions";
import {
  getAllTags,
  addTag,
  editTag,
  deleteTag,
} from "../../store/actions/tag-state-actions";

import { DataGrid } from "@mui/x-data-grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";
class ViewAllTags extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
      },
      tagList: [],
      open: false,
      success: false,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      add: {
        name: "",
        type: "",
      },
      isAdd: false,
      edit: {
        name: "",
        type: "",
      },
      isEdit: false,
      id: 0,
      modalIsError: false,
      modalErrorMsg: "",
      modalIsValid: false,
      modalValidMsg: "",
      modalSpinner: false,
      isLoading: true,
      pageSize: 10,
      responseMessage: "",
      showResponse: false,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    if (this.props.tagListState.success) {
      this.setState({
        ...this.state,
        tagList: this.props.tagListState.data,
        success: true,
        isLoading: false,
      });
    } else {
      this.getTags();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success && this.props.appState.status === 1) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        if (this.props.deleteTagState.status === -1) {
          this.handleDelete();
        }

        if (this.props.editState.status === -1) {
          this.handleEditTag();
        }

        if (this.props.addState.status === -1) {
          this.handleAddTag();
        }

        if (this.props.tagListState.token === -1) {
          this.props.getAllTags();
        }
      } else if (
        this.props.appState.success &&
        this.props.appState.status === -1
      ) {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }

    if (prevProps.deleteTagState !== this.props.deleteTagState) {
      if (
        this.props.deleteTagState.success &&
        this.props.deleteTagState.status === 1
      ) {
        this.setState(
          {
            ...this.state,
            modalSpinner: false,
            spinner: false,
            isLoading: true,
          },
          () => {
            this.showSuccessModal("Tag has been deleted successfully.");
            this.getTags();
          }
        );
      } else if (this.props.deleteTagState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.deleteTagState.isError) {
        this.setState(
          { ...this.state, modalSpinner: false, spinner: false },
          () => {
            this.showErrorModal("Failed to delete tag.");
          }
        );
      }
    }

    if (prevProps.editState !== this.props.editState) {
      if (this.props.editState.success && this.props.editState.status === 1) {
        this.setState(
          { ...this.state, success: false, isLoading: true },
          () => {
            this.showSuccessModal("Tag has been updated successfully.");
            this.getTags();
          }
        );
      } else if (this.props.editState.status === -1) {
        this.props.refreshToken();
      } else if (
        this.props.editState.isError &&
        this.props.editState.status === 0
      ) {
        this.showErrorModal(this.props.editState.message);
      }
    }

    if (prevProps.addState !== this.props.addState) {
      if (this.props.addState.success && this.props.addState.status === 1) {
        this.setState(
          {
            ...this.state,
            add: { ...this.state.add, name: "", type: "" },
            success: false,
            isLoading: true,
          },
          () => {
            this.showSuccessModal("New tag has been added successfully.");
            this.getTags();
          }
        );
      } else if (this.props.addState.status === -1) {
        this.props.refreshToken();
      } else if (
        this.props.addState.isError &&
        this.props.addState.status === 0
      ) {
        this.showErrorModal(this.props.addState.message);
      }
    }

    // if (prevProps.tagListState !== this.props.tagListState) {
    //   if (
    //     this.props.tagListState.success &&
    //     this.props.tagListState.token === 1
    //   ) {
    //     this.setState({
    //       ...this.state,
    //       tagList: this.props.tagListState.data,
    //       success: true,
    //       isLoading: false,
    //     });
    //   } else if (this.props.tagListState.token === -1) {
    //     this.props.refreshToken();
    //   }
    // }
  }

  getTags() {
    this.props.getAllTags().then(() => {
      if (this.props.tagListState.success) {
        this.setState({
          ...this.state,
          tagList: this.props.tagListState.data,
          success: true,
          isLoading: false,
        });
      } else {
        this.setState({
          ...this.state,
          success: false,
          isLoading: false,
        });
      }
    });
  }

  showSuccess(msg) {
    this.setState(
      {
        ...this.state,
        validMsg: msg,
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
        }, 1500);
      }
    );
  }

  showSuccessModal(msg) {
    this.setState(
      {
        ...this.state,
        modalIsValid: true,
        modalValidMsg: msg,
        modalSpinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            modalValidMsg: "",
            modalIsValid: false,
            isEdit: false,
            isAdd: false,
          });
          this.handleCloseModal();
        }, 1500);
      }
    );
  }

  showErrorModal(msg) {
    this.setState(
      {
        ...this.state,
        modalErrorMsg: msg,
        modalIsError: true,
        modalSpinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            modalErrorMsg: "",
            modalIsError: false,
            // isEdit: false,
            // isAdd: false,
          });
        }, 3500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  }

  handleAddModelOpen = () => {
    this.setState({ ...this.state, isAdd: true });
  };

  handleAddModelClose = () => {
    this.setState({
      ...this.state,
      add: {
        name: "",
        type: "",
      },
      isAdd: false,
    });
  };

  handleEditModelOpen = (obj) => {
    this.setState({ ...this.state, edit: obj, isEdit: true });
  };

  handleEditModelClose = () => {
    this.setState({
      ...this.state,
      edit: {
        name: "",
        type: "",
      },
      isEdit: false,
    });
  };

  getRows = () => {
    var arr = [];
    arr =
      this.state.success && this.state.tagList
        ? this.state.tagList.map((item, index) => {
            item.id = index + 1;
            return item;
          })
        : [];
    return arr;
  };

  handleAddChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, add: { ...this.state.add, [id]: value } });
  };

  handleEditChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, edit: { ...this.state.edit, [id]: value } });
  };

  handleAddTag = () => {
    if (this.state.add.name.length !== 0 && this.state.add.type.length !== 0) {
      var today = new Date();
      var formData = {
        name: this.state.add.name,
        type: this.state.add.type,
        dateCreated: moment(today).format("YYYY-MM-DD HH:m:s"),
      };
      this.setState(
        {
          ...this.state,
          modalSpinner: true,
        },
        () => {
          this.props.addTag(formData);
        }
      );
    } else {
      this.setState(
        {
          ...this.state,
          modalErrorMsg: "Tag name and tag type cannot be empty!",
          modalIsError: true,
          modalSpinner: false,
        },
        () => {
          setTimeout(() => {
            this.setState({
              modalErrorMsg: "",
              modalIsError: false,
            });
          }, 2000);
        }
      );
    }
  };

  handleEditTag = () => {
    if (
      this.state.edit.name.length !== 0 &&
      this.state.edit.type.length !== 0
    ) {
      var formData = {
        id: this.state.edit.Id,
        name: this.state.edit.name,
        type: this.state.edit.type,
        dateCreated: this.state.edit.DateCreated,
      };
      this.setState({ ...this.state, modalSpinner: true }, () => {
        this.props.editTag(formData);
      });
    } else {
      this.setState(
        {
          ...this.state,
          modalErrorMsg: "Tag name and tag type cannot be empty!",
          modalIsError: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              modalErrorMsg: "",
              modalIsError: false,
            });
          }, 2000);
        }
      );
    }
  };

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = (id) => {
    this.setState({ ...this.state, open: true, id: id });
  };

  handlePageSize = (value) => {
    this.setState({ ...this.state, pageSize: value });
  };

  handleDelete = () => {
    this.setState({
      ...this.state,
      spinner: true,
      modalSpinner: true,
    });
    var obj = {
      id: this.state.id,
    };
    this.props.deleteTag(obj).then(() => {
      if (this.props.deleteTagState.success) {
        this.setState({
          ...this.state,
          isLoading: true,
          spinner: false,
          modalSpinner: false,
        });
      }
    });
  };

  filterName = (item) => {
    if (this.state.data.name !== "0" && this.state.data.name !== "") {
      return item.name
        .toLowerCase()
        .includes(this.state.data.name.toLowerCase());
    } else {
      return item;
    }
  };

  handleReset = () => {
    this.setState({
      data: {
        ...this.state.data,
        name: "",
      },
      tagList: this.props.tagListState.data,
      search: false,
    });
  };

  handleSubmit = () => {
    if (this.checkSearchParameters()) {
      var items = this.props.tagListState.data.filter(this.filterName);
      this.setState({ tagList: items });
    }
  };

  checkSearchParameters() {
    for (var key in this.state.data) {
      if (
        this.state.data[key] !== "" &&
        this.state.data[key] !== "0" &&
        this.state.data[key] !== null
      ) {
        return true;
      }
    }
    return false;
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [id]: value,
      },
    });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 80 },
      {
        field: "name",
        headerName: "Name",
        width: 200,
        sortable: true,
      },
      {
        field: "type",
        headerName: "Type",
        sortable: true,
        width: 150,

        renderCell: (params) => (
          <p className="m-0" style={{ textTransform: "capitalize" }}>
            {params.row.type}
          </p>
        ),
      },
      {
        field: "dateCreated",
        headerName: "Date Created",
        width: 150,
        sortable: true,
        valueFormatter: (params) => {
          return moment(params.row.dateCreated).format("DD-MM-YYYY");
        },
      },
      {
        field: "action",
        headerName: "Action",
        sortable: true,
        width: 180,

        renderCell: (params) => (
          <Fragment>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={(e) => this.handleEditModelOpen(params.row)}
            >
              Update
            </button>
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleOpenModal(params.row.Id)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleAddModelOpen()}
            >
              Add Tag
            </button>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-8 mt-3">
                <label htmlFor="name">Tag Name</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  id="name"
                  value={this.state.data.name}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  placeholder="Tag Name"
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-md-5 mt-3 mx-0 d-flex flex-row justify-content-end justify-content-md-start align-items-center">
                <button
                  type="button"
                  className="btn primary_btn mr-1 "
                  onClick={() => this.handleSubmit()}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-secondary my-1 "
                  onClick={(e) => this.handleReset()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 d-flex justify-content-center">
            <div className="mt-3 text-center">
              {this.state.showResponse ? (
                <div className="alert alert-danger mt-3 mb-3" role="alert">
                  {this.state.responseMessage}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12 mt-4">
            <h3>View All Tags</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ width: "100%" }}>
              {this.state.isLoading ? (
                <div className="row d-flex justify-content-center">
                  <div
                    className="spinner-border text-danger"
                    role="status"
                    style={{ width: "3rem", height: "3rem" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <DataGrid
                  rows={this.getRows() || []}
                  columns={columns}
                  disableColumnMenu
                  pageSize={this.state.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    this.handlePageSize(newPageSize)
                  }
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  disableSelectionOnClick={true}
                  autoHeight
                />
              )}
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.isAdd}
          onClose={this.handleAddModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          disableBackdropClick={true}
        >
          <Fade in={this.state.isAdd}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h5>Add Tag</h5>
                  </div>
                  <div className="col-md-6 col-sm-12 mt-3">
                    <label htmlFor="name">Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={this.state.add.name}
                      onChange={this.handleAddChange}
                      aria-describedby="helpId"
                      placeholder="Tag Name"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-3">
                    <label htmlFor="type">Type *</label>
                    <select
                      className="form-control"
                      name=""
                      id="type"
                      value={this.state.add.type}
                      onChange={this.handleAddChange}
                    >
                      <option value="">Select...</option>
                      <option value={"sinhala"}>Sinhala</option>
                      <option value={"tamil"}>Tamil</option>
                      <option value={"english"}>English</option>
                    </select>
                  </div>

                  <div className="col-12 d-flex justify-content-end mt-3 mx-0">
                    {this.state.modalSpinner && (
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}

                    {this.state.modalIsError ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-danger w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalErrorMsg}
                        </div>
                      </div>
                    ) : null}

                    {this.state.modalIsValid ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-success w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalValidMsg}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 row d-flex justify-content-end mt-3 mx-0">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleAddTag()}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleAddModelClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.isEdit}
          onClose={this.handleEditModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          disableBackdropClick={true}
        >
          <Fade in={this.state.isEdit}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h5>Update Tag</h5>
                  </div>
                  <div className="col-md-6 col-sm-12 mt-3">
                    <label htmlFor="name">Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={this.state.edit.name}
                      onChange={this.handleEditChange}
                      aria-describedby="helpId"
                      placeholder="Tag Name"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-3">
                    <label htmlFor="type">Type *</label>
                    <select
                      className="form-control"
                      name=""
                      id="type"
                      value={this.state.edit.type}
                      onChange={this.handleEditChange}
                    >
                      <option value="">Select...</option>
                      <option value={"sinhala"}>Sinhala</option>
                      <option value={"tamil"}>Tamil</option>
                      <option value={"english"}>English</option>
                    </select>
                  </div>

                  <div className="col-12 d-flex justify-content-end mt-3 mx-0">
                    {this.state.modalSpinner && (
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}

                    {this.state.modalIsError ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-danger w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalErrorMsg}
                        </div>
                      </div>
                    ) : null}

                    {this.state.modalIsValid ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-success w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalValidMsg}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 row d-flex justify-content-end mt-3 mx-0">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleEditTag()}
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleEditModelClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h4>Are you sure that you want to delete this tag?</h4>
                  </div>

                  <div className="col-12 d-flex justify-content-end mt-3 mx-0">
                    {this.state.modalSpinner && (
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}

                    {this.state.modalIsError ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-danger w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalErrorMsg}
                        </div>
                      </div>
                    ) : null}

                    {this.state.modalIsValid ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-success w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalValidMsg}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="col-12 row d-flex justify-content-end mx-0">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleDelete()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleCloseModal()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tagListState: state.tagStore.tagsList,
    editState: state.tagStore.editTag,
    addState: state.tagStore.tag,
    deleteTagState: state.tagStore.deleteTag,
    appState: state.appStore.refreshToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  refreshToken: () => dispatch(refreshToken()),
  getAllTags: () => dispatch(getAllTags()),
  addTag: (data) => dispatch(addTag(data)),
  editTag: (data) => dispatch(editTag(data)),
  deleteTag: (id) => dispatch(deleteTag(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewAllTags)
);
