import {
  ACTION_GET_ALL_EMPLOYEES,
  ACTION_ADD_EMPLOYEE,
  ACTION_GET_EMPLOYEE_BY_ID,
  ACTION_EDIT_EMPLOYEE,
  ACTION_DELETE_EMPLOYEE,
} from "../../constants/actions";

const initialState = {
  employees: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  employee: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editEmployee: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  deleteEmployee: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};

export default function employeeStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_EMPLOYEES: {
      _state = {
        ...state,
        employees: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_ADD_EMPLOYEE: {
      _state = {
        ...state,
        employee: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
        employees: {
          ...state.employees,
          success: false,
          isLoading: false,
          isError: false,
        },
      };
      break;
    }

    case ACTION_GET_EMPLOYEE_BY_ID: {
      _state = {
        ...state,
        employee: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }

    case ACTION_EDIT_EMPLOYEE: {
      _state = {
        ...state,
        editEmployee: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        employees: {
          ...state.employees,
          success: false,
          isLoading: false,
          isError: false,
        },
      };
      break;
    }

    case ACTION_DELETE_EMPLOYEE: {
      _state = {
        ...state,
        deleteEmployee: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        employees: {
          ...state.employees,
          data: state.employees.data.filter((x) => x.UserId !== action.data),
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
