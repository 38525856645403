import React, { Fragment } from "react";
import { connect } from "react-redux";

import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import {
  getAllSystemFunctions,
  getAllUserPermissions,
  saveUserPermission,
  updateUserPermission,
} from "../../store/actions/users-state-actions";
import { checkUserPermission } from "../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";
class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      featuresUsers: [],
      userRolesList: [],
      featurePermissions: [],
      permission: {
        up_id: 0,
        role_id: 0,
        features: [],
        createdDate: new Date().toISOString(),
        createdBy: 0,
        updatedDate: new Date().toISOString(),
        updatedBy: 0,
      },
      allFeatures: false,
      responseMessage: "",
      isError: false,
      showResponse: false,
      redirectPage: "",
      isLoading: false,
      spinner: false,
      isChecked: false,
      showError: "",
    };
    this.rolePermissions = this.rolePermissions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getUserPermissions = this.getUserPermissions.bind(this);
    this.getFeatureUserPermission = this.getFeatureUserPermission.bind(this);

    this.checkAllPermission = this.checkAllPermission.bind(this);
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("9");
    // if (localStorage.getItem("CIZUP") !== null) {
    //   var arr = localStorage.getItem("CIZUP");
    //   if (JSON.parse(arr).length !== 0) {
    //     var isExist = JSON.parse(arr).find((item) => item === "11");
    //     if (isExist === undefined) {
    //       this.setState({
    //         ...this.state,
    //         redirectPage: "/access-denied",
    //       });
    //     }
    //   }
    // } else {
    //   this.setState({
    //     ...this.state,
    //     redirectPage: "/access-denied",
    //   });
    // }

    this.props.getAllSystemFunctions().then(() => {
      if (this.props.systemFunctionState.success) {
        this.setState({
          ...this.state,
          featuresUsers:
            this.props.systemFunctionState.data.length !== 0
              ? this.props.systemFunctionState.data.map((item, index) => ({
                  ...item,
                  id: index + 1,
                  isChecked: false,
                }))
              : [],
        });
      } else {
        this.setState({
          ...this.state,
          showError: "Server Error.",
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            showError: "",
          });
        }, 2500);
      }
    });
    this.getFeatureUserPermission();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
  }
  getFeatureUserPermission() {
    this.props.getAllUserPermissions().then(() => {
      if (this.props.userPermissionState.success) {
        this.setState({
          ...this.state,
          featurePermissions:
            this.props.userPermissionState.data.length !== 0
              ? this.props.userPermissionState.data
              : [],
        });
      }
    });
  }

  getUserPermissions() {
    this.props.getAllSystemFunctions().then(() => {
      if (this.props.systemFunctionState.success) {
        this.setState({
          ...this.state,
          featuresUsers:
            this.props.systemFunctionState.data.length !== 0
              ? this.props.systemFunctionState.data.map((item, index) => ({
                  ...item,
                  id: index + 1,
                  isChecked: false,
                }))
              : [],
        });
      } else {
        this.setState({
          ...this.state,
          showError: "Server Error",
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            showError: "",
          });
        }, 2500);
      }
    });
  }
  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      permission: {
        ...this.state.permission,
        [id]: value,
      },
    });
  };

  handleRoleChange = (e) => {
    const { id, value } = e.target;

    this.setState(
      {
        ...this.state,
        isChecked: false,
        permission: {
          ...this.state.permission,
          [id]: parseInt(value),
        },
      },
      () => {
        this.rolePermissions(
          this.state.permission.role_id,
          this.state.featurePermissions
        );
      }
    );
    // }
  };

  checkAllPermission(featuresUsers) {
    var activePermissions = [];
    var isAllChecked = false;

    if (featuresUsers.length !== 0) {
    }
    featuresUsers.forEach((element) => {
      if (!element.isChecked) {
        activePermissions.push(element);
      }
    });

    isAllChecked = activePermissions.length === 0;

    return isAllChecked;
  }

  handlePermissions = (e) => {
    var value = e.target.value;

    this.state.featuresUsers.forEach((item) => {
      if (item.id === parseInt(value)) {
        item.isChecked = e.target.checked;
      }
    });

    this.setState({
      ...this.state,
      isChecked: this.checkAllPermission(this.state.featuresUsers),
      permission: {
        ...this.state.permission,
        // id: this.state.id,
        features: this.state.featuresUsers
          .filter((item) => item.isChecked === true)
          .map((item) => item.id.toString()),
      },
    });
  };

  rolePermissions(roleId, featurePermissions) {
    var result = [];

    if (roleId !== 0 && featurePermissions === "All") {
      var allPermission =
        this.state.featuresUsers.length !== 0
          ? this.state.featuresUsers.map((item, index) => ({
              ...item,
              id: index + 1,
              isChecked: this.state.isChecked,
            }))
          : [];
      this.setState({
        ...this.state,
        featuresUsers: allPermission,
        permission: {
          ...this.state.permission,
          features: allPermission
            .filter((item) => item.isChecked === true)
            .map((item) => item.id.toString()),
        },
      });
    } else {
      var userFunctions =
        this.props.systemFunctionState.success &&
        this.props.systemFunctionState.data.map((item, index) => ({
          ...item,
          id: index + 1,
          isChecked: false,
        }));

      if (roleId !== 0) {
        var previousPermissions = featurePermissions.find(
          (vp) => parseInt(vp.role_id) === parseInt(roleId)
        );
        if (previousPermissions !== undefined) {
          var permissionsList =
            previousPermissions.permission.length !== 0
              ? previousPermissions.permission.split(",")
              : [];

          if (permissionsList.length !== 0) {
            userFunctions.forEach((element) => {
              var isExist = permissionsList.some(
                (item) => parseInt(item) === element.id
              );
              if (isExist) {
                element.isChecked = true;
              }
              result.push(element);
            });
          }
        } else {
          result = userFunctions;
        }
      } else {
        result = userFunctions;
      }

      this.setState({
        ...this.state,
        isChecked: this.checkAllPermission(result),
        featuresUsers: result,
        permission: {
          ...this.state.permission,
          up_id:
            previousPermissions &&
            previousPermissions.up_id != 0 &&
            previousPermissions.up_id != ""
              ? previousPermissions.up_id
              : 0,
          features: result
            .filter((item) => item.isChecked === true)
            .map((item) => item.id.toString()),
        },
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.permission.role_id !== 0) {
      this.setState({
        ...this.state,
        spinner: true,
      });
      if (this.state.permission.features.length !== 0) {
        var obj = {
          up_id: this.state.permission.up_id,
          role_id: this.state.permission.role_id,
          permissions: this.state.permission.features.join(","),
        };
        this.props.saveUserPermission(obj).then(() => {
          if (this.props.saveUserPermissionState.success) {
            this.setState(
              {
                spinner: false,
                showResponse: true,
                isError: false,
                isChecked: false,
                responseMessage: "Permission updated successfully.",
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    showResponse: false,
                    isError: false,
                    responseMessage: "",
                    permission: {
                      role_id: 0,
                    },
                    redirectPage: "/",
                  });
                  window.location.href = "/";
                  this.getUserPermissions();
                  this.getFeatureUserPermission();
                }, 2500);
              }
            );
          } else {
            this.setState(
              {
                spinner: false,
                showResponse: true,
                isError: true,
                responseMessage: "Permission not updated.",
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    showResponse: false,
                    isError: false,
                    responseMessage: "",
                  });
                }, 2500);
              }
            );
          }
        });
      } else {
        this.setState(
          {
            spinner: false,
            showResponse: true,
            isError: true,
            responseMessage: "Should select at least one permission.",
          },
          () => {
            setTimeout(() => {
              this.setState({
                showResponse: false,
                isError: false,
                responseMessage: "",
              });
            }, 2500);
          }
        );
      }
    } else {
      this.setState({
        ...this.state,
        errors: {
          role_id: "*",
        },
      });
      setTimeout(() => {
        this.setState({
          ...this.state,
          errors: {
            role_id: "",
          },
        });
      }, 2500);
    }
  };

  render() {
    if (this.state.redirectPage) {
      return <Redirect to={this.state.redirectPage} />;
    }

    const columns = [
      { field: "id", headerName: "#", width: 80 },

      {
        field: "function",
        headerName: "Function Name",
        width: 300,
      },
      {
        field: "active",
        headerName: "Active",
        sortable: false,
        width: 160,
        renderCell: (params) => {
          return (
            <div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <Switch
                  id={params.row.featureName}
                  value={params.row.id}
                  checked={params.row.isChecked}
                  onChange={this.handlePermissions}
                  color="primary"
                  name={params.row.featureName}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </div>
          );
        },
      },
    ];

    return (
      <Fragment>
        <div className="home-card main-card-box-shadow mb-5 bg-white rounded p-0">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0 p-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  User Permissions
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12 my-4 default-p-m p-0">
            <h3 className="main-card-titel">User Permissions</h3>
          </div>

          <div className="row d-flex flex-row mx-0 default-p-m my-3 px-0">
            {/* <div className="col-md-2 p-0 input-labels">
              <label className="product-management-search mb-0">Role *</label>
            </div> */}
            <div className="col-md-8 d-flex flex-row mx-0 p-0 d-flex align-items-center">
              <label className="product-management-search mb-0 mr-3">
                Role *
              </label>
              <select
                className="form-control"
                id="role_id"
                value={this.state.permission.role_id}
                onChange={this.handleRoleChange}
                style={{ width: `${70}%` }}
              >
                <option value={0}>Please Select</option>
                <option value={"1"}>Super Admin</option>
                <option value={"2"}>Admin</option>
                <option value={"3"}>Editor</option>
                <option value={"4"}>Fact Check</option>
              </select>
            </div>
            <div className="col-md-4 ">
              <Checkbox
                className="mt-n1"
                id="isChecked"
                checked={this.state.isChecked}
                onChange={(e) =>
                  this.setState(
                    {
                      ...this.state,
                      isChecked: e.target.checked,
                      allFeatures: e.target.checked,
                    },
                    () => {
                      this.rolePermissions(
                        this.state.permission.role_id,
                        "All"
                      );
                    }
                  )
                }
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <label className="main-label-text mb-0">
                Give All Permissions
              </label>
            </div>
          </div>

          {this.props.userPermissionState.isLoading ? (
            <div className="col-12 col-md-12 d-flex justify-content-center">
              <div className="row  mt-3">
                <div className="col-12 d-flex justify-content-center">
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <div style={{ width: "100%" }}>
                  <DataGrid
                    rows={
                      this.state.featuresUsers.length !== 0
                        ? this.state.featuresUsers
                        : []
                    }
                    columns={columns}
                    pageSize={15}
                    autoHeight={true}
                    disableSelectionOnClick
                    disableColumnMenu
                    showToolbar
                  />
                  <div className="col-12 col-md-12 d-flex justify-content-center">
                    {this.state.spinner && (
                      <div className="row mt-3">
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {!this.state.isError && this.state.showResponse && (
                      <div
                        className="alert alert-success w-100 d-flex justify-content-center my-2"
                        role="alert"
                      >
                        {this.state.responseMessage}
                      </div>
                    )}
                    {this.state.isError && (
                      <div
                        className="alert alert-danger w-100 d-flex justify-content-center my-2"
                        role="alert"
                      >
                        {this.state.responseMessage}
                      </div>
                    )}
                  </div>
                  <div
                    className="col-md-12 mt-5 mb-3 justify-content-end edit-page-button2"
                    align="right"
                  >
                    <button
                      className={
                        this.state.permission.role_id
                          ? "btn primary_btn mx-1"
                          : "btn primary_btn"
                      }
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row default-p-m">
            <div className="col-12 col-md-12 d-flex justify-content-center">
              <div className="mt-3 text-center">
                {this.state.showError && (
                  <div
                    className="alert alert-danger mt-3 mb-3 w-100 text-center"
                    role="alert"
                  >
                    {this.state.showError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    saveUserPermissionState: state.usersStore.savePermission,
    updateUserPermissionState: state.usersStore.savePermission,
    userPermissionState: state.usersStore.userPermissions,
    systemFunctionState: state.usersStore.systemFunctions,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAllSystemFunctions: () => dispatch(getAllSystemFunctions()),
  getAllUserPermissions: () => dispatch(getAllUserPermissions()),
  saveUserPermission: (data) => dispatch(saveUserPermission(data)),
  updateUserPermission: (data) => dispatch(updateUserPermission(data)),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
