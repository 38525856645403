import React, { Fragment, PureComponent } from "react";
import { addAd, getAllAds } from "../../../store/actions/ad-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import moment from "moment";
import * as validator from "../validations";
import { Link } from "react-router-dom";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";

class SaveAd extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Section: "",
        Thumbnail: "",
        ThumbnailImage: "",
        Url: "",
        DateExpire: "",
      },
      errors: {
        Section: "",
        Thumbnail: "",
        URL: "",
        DateExpire: "",
      },
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      articleImg: "",
      imgName: "",
      redirect: "",
      isbtnDisable: false,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("7");

    if (!this.props.adState.success && !this.props.adState.isLoading) {
      this.props.getAllAds();
    } else {
      this.setState({
        ...this.state,
        adList: this.props.adState.data,
        success: true,
        isLoading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (prevProps.adState !== this.props.adState) {
      if (this.props.adState.success && this.props.adState.status === 1) {
        this.showSuccess();
      } else if (this.props.adState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.adState.isError) {
        this.showError("Failed to add new advertisement. Please try again.");
      }
    }
    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.handleAdd();
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  isUrlValid = () => {
    var res = this.state.data.Url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Advertisement has been added successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
            redirect: "/ad/all",
            isbtnDisable: false,
          });
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
            isbtnDisable: false,
          });
        }, 3500);
      }
    );
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, data: { ...this.state.data, [id]: value } });
  };

  handleAdd = () => {
    for (let [id, value] of Object.entries(this.state.data)) {
      this.validateInputAndSetState(id, value);
    }

    var isValid = validator.isErrorObjectEmpty(this.state.errors);

    if (isValid) {
      if (this.state.data.Url && !this.isUrlValid()) {
        this.showError("Invalid url inserted!");
        return false;
      }

      var today = new Date();
      var exp = new Date(this.state.data.DateExpire);
      if (exp <= today) {
        this.showError("Please choose a valid expire date.");
        return false;
      }

      var fromData = {
        section: this.state.data.Section,
        thumbnail: this.state.articleImg,
        thumbnailImage: this.state.imgName,
        url: this.state.data.Url,
        dateExpire: this.state.data.DateExpire,
        userId: window.localStorage.getItem("userId"),
        dateCreated: moment(today).format("YYYY-MM-DD HH:m:s"),
      };
      this.props.addAd(fromData);
      this.setState({ ...this.state, spinner: true, isbtnDisable: true });
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  handleImage = (e) => {
    let image = e.target.files[0];
    if (image) {
      const file = image.type.split("/").pop().toLowerCase();
      if (file === "jpeg" || file === "jpg" || file === "png") {
        if (image.size <= 5242880) {
          this.getImage(e.target.files[0]).then(() => {
            this.setState({
              ...this.state,
              data: {
                ...this.state.data,
                Thumbnail: URL.createObjectURL(e.target.files[0]),
                ThumbnailImage: e.target.value,
              },
              imgName: e.target.files[0].name,
            });
          });
        } else {
          this.showError("The file is too large. Allowed maximum size is 5MB.");
        }
      } else {
        this.showError(
          "This file type is not allowed. Accepted file types are: JPEG, JPG or PNG."
        );
      }
    }
  };

  toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async getImage(file) {
    await this.toBase64(file).then((res) => {
      this.setState({
        ...this.state,
        articleImg: res,
      });
    });
  }

  checkSection = (data) => {
    var arr = [];
    if (data.length > 0 && this.props.advState.success) {
      data.forEach((element) => {
        var isExit = this.props.advState.data.some(
          (item) => item.Section === element.Section
        );

        if (!isExit) {
          arr.push(element);
        }
      });
    }

    return arr;
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    const sectionlist = [
      {
        Section: "Section 01 - Home-Header / Top Section",
        value: "Section 01",
      },
      {
        Section: "Section 02 - Home-Header / Bottom Section",
        value: "Section 02",
      },
      {
        Section: "Section 03 - Home-Trending News Section",
        value: "Section 03",
      },
      {
        Section: "Section 04 - Home-Bottom / Top Section",
        value: "Section 04",
      },
      {
        Section: "Section 05 - Home-Bottom / Bottom Section",
        value: "Section 05",
      },
      {
        Section: "Section 06 - Article-Header / Top Section",
        value: "Section 06",
      },
      {
        Section: "Section 07 - Article-Bottom / Bottom Section",
        value: "Section 07",
      },
    ];
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/ad/all",
                    }}
                  >
                    Advertisements
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Add Advertisement
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-11">
            <h3>Add Advertisement</h3>
          </div>
          <div className="col-11">
            <div className="row">
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Section">Section * </label>
                <select
                  className="form-control"
                  id="Section"
                  value={this.state.data.Section}
                  onChange={this.handleChange}
                >
                  <option value={""}>Select Section...</option>

                  {sectionlist.length !== 0 &&
                    this.checkSection(sectionlist).map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item.Section}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-12 col-sm-6 ">
                <label htmlFor="URL">URL</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  id="Url"
                  aria-describedby="helpId"
                  placeholder="URL"
                  value={this.state.data.Url}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mb-1">
                <label htmlFor="ThumbnailImage">Upload Image *</label>
                <br />
                <input
                  type="file"
                  id="ThumbnailImage"
                  className="form-control"
                  // value={this.state.data.ThumbnailImage}
                  onChange={this.handleImage}
                  aria-describedby="helpId"
                />
                <small id="helpId" className="form-text text-secondary mb-1">
                  Max file size: 5 MB
                </small>
              </div>
              <div className="col-12 col-sm-6">
                <label htmlFor="DateExpire">Expire Date *</label>
                <input
                  type="date"
                  className="form-control"
                  id="DateExpire"
                  aria-describedby="helpId"
                  placeholder="DateExpire"
                  value={this.state.data.DateExpire}
                  min={moment().add(1, "days").format("YYYY-MM-DD")}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mt-3 mb-3">
                {this.state.data.Thumbnail.length !== 0 ? (
                  <img
                    className="post-img-prev"
                    src={this.state.data.Thumbnail}
                    alt="[Image Not Available]"
                  />
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center my-4">
                {this.state.spinner ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.isError ? (
                  <div
                    className="alert alert-danger w-100 text-center"
                    role="alert"
                  >
                    {this.state.errorMsg}
                  </div>
                ) : null}
                {this.state.isValid ? (
                  <div
                    className="alert alert-success w-100 text-center"
                    role="alert"
                  >
                    {this.state.validMsg}
                  </div>
                ) : null}
              </div>

              <div className="col-12 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn primary_btn add-btn-size"
                  onClick={(e) => this.handleAdd()}
                  disabled={this.state.isbtnDisable}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adState: state.adStore.ad,
    appState: state.appStore.refreshToken,
    advState: state.adStore.ads,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addAd: (data) => dispatch(addAd(data)),
  refreshToken: () => dispatch(refreshToken()),
  getAllAds: () => dispatch(getAllAds()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveAd));
