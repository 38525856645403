import React, { Fragment, PureComponent } from "react";

import "./index.css";

class Footer extends PureComponent {
  render() {
    return (
      <Fragment>
        <div className="footer-wrapper">
          <h3>Footer</h3>
        </div>
      </Fragment>
    );
  }
}

export default Footer;
