import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getAllLiveNews,
  deleteLiveNews,
  toggleLiveNews,
} from "../../store/actions/live-news-action";
import { addVideo } from "../../store/actions/video-state-actions";
import { refreshToken } from "../../store/actions/app-state-actions";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import { checkUserPermission } from "../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";

class LiveNews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      liveNewsList: [],
      success: false,
      id: 0,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      open: false,
      moveVideo: false,
      checked: false,
      toggleOpen: false,
      Status: "",
      pageSize: 5,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("4");
    if (
      !this.props.liveNewsState.success &&
      !this.props.liveNewsState.isLoading
    ) {
      this.props.getAllLiveNews();
    } else {
      this.setState({
        ...this.state,
        liveNewsList: this.props.liveNewsState.data,
        success: true,
        checked:
          this.props.liveNewsState.data.length !== 0 &&
          this.props.liveNewsState.data[0].Status === "1"
            ? true
            : false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (this.props.liveNewsState !== prevProps.liveNewsState) {
      if (
        this.props.liveNewsState.success &&
        this.props.liveNewsState.token === 1
      ) {
        this.setState({
          ...this.state,
          liveNewsList: this.props.liveNewsState.data,
          success: true,
          checked:
            this.props.liveNewsState.data.length !== 0 &&
            this.props.liveNewsState.data[0].Status === "1"
              ? true
              : false,
        });
      } else if (this.props.liveNewsState.token === -1) {
        this.props.refreshToken();
      }
    }

    // if (prevProps.videoState !== this.props.videoState) {
    //   if (this.props.videoState.success && this.props.videoState.status === 1) {
    //   } else if (this.props.videoState.status === -1) {
    //     this.props.refreshToken();
    //   } else if (this.props.videoState.isError) {
    //     this.showError("Failed to add new live broadcast. Please try again.");
    //   }
    // }

    if (prevProps.newsState !== this.props.newsState) {
      if (this.props.newsState.success && this.props.newsState.status === 1) {
        this.setState({
          ...this.state,
          liveNewsList: this.props.liveNewsState.data,
          success: true,
        });
      } else if (this.props.newsState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.editState !== this.props.editState) {
      if (this.props.editState.success && this.props.editState.status === 1) {
        this.handleToggleClose();
      } else if (this.props.editState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (this.props.deleteState !== prevProps.deleteState) {
      if (
        this.props.deleteState.success &&
        this.props.deleteState.status === 1
      ) {
        this.setState(
          {
            ...this.state,
            liveNewsList: this.props.liveNewsState.data,
          },
          () => {
            this.showSuccess("Live broadcast has been deleted successfully.");
            this.props.getAllLiveNews();
          }
        );
      } else if (
        this.props.deleteState.success &&
        this.props.deleteState.status === -1
      ) {
        this.props.refreshToken();
      } else if (this.props.deleteState.isError) {
        this.showError("Failed to delete live broadcast.");
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success && this.props.appState.status === 1) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        if (this.props.deleteState.status === -1) {
          this.handleDelete();
        }

        if (this.props.editState.status === -1) {
          this.handleSwitch();
        }
      } else if (
        this.props.appState.success &&
        this.props.appState.status === -1
      ) {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleDelete = () => {
    this.setState({ ...this.state, spinner: true });

    if (this.state.moveVideo) {
      var today = new Date();
      var video = this.state.liveNewsList.filter((x) => x.Id === this.state.id);
      var fromData = {
        title: video[0].Title,
        embedlink: video[0].Embedlink,
        url: video[0].URL,
        status: true,
        category: "Live",
        author: video[0].Author,
        dateCreated: moment(today).format("YYYY-MM-DD HH:m:s"),
      };
      this.props.addVideo(fromData);
    }
    var obj = {
      id: this.state.id,
    };
    this.props.deleteLiveNews(obj);
  };

  handleEditRedirect = (id) => {
    this.props.history.push(`/livenews/e/` + id);
  };

  handleAddRedirect = () => {
    this.props.history.push(`/livenews/add`);
  };

  handleRedirect = (id) => {
    this.props.history.push(`/livenews/` + id);
  };

  handleCheckBox = () => {
    this.setState({ ...this.state, moveVideo: !this.state.moveVideo });
  };

  getRows = () => {
    var arr =
      this.state.success &&
      this.state.liveNewsList.map((item, index) => {
        item.id = index + 1;
        return item;
      });
    return arr;
  };

  getPrevImg = (url_string) => {
    var url = new URL(url_string);
    return url.searchParams.get("v");
  };

  showSuccess(msg) {
    this.setState(
      {
        ...this.state,
        validMsg: msg,
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          this.handleCloseModal();
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  }

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = (id) => {
    this.setState({ ...this.state, open: true, id: id });
  };

  handleSwitch = () => {
    var video = this.state.liveNewsList;
    var check = false;
    if (this.state.Status === "1") {
      video[0].Status = "2";
      check = false;
    } else {
      video[0].Status = "1";
      check = true;
    }
    this.setState({
      ...this.state,
      liveNewsList: video,
      checked: check,
    });

    var formData = {
      id: this.state.liveNewsList[0].Id,
      status: this.state.liveNewsList[0].Status,
    };

    this.props.toggleLiveNews(formData);
  };

  handleToggleOpen = (status) => {
    this.setState({ ...this.state, toggleOpen: true, Status: status });
  };

  handleToggleClose = () => {
    this.setState({ ...this.state, toggleOpen: false });
  };

  handlePageSize = (value) => {
    this.setState({ ...this.state, pageSize: value });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 80 },
      {
        field: "Title",
        headerName: "Broadcast Title",
        width: 320,
        sortable: true,
      },
      {
        field: "Author",
        headerName: "Author",
        sortable: true,
        width: 150,
      },
      {
        field: "DateCreated",
        headerName: "Date Created",
        sortable: true,
        width: 180,

        renderCell: (params) => (
          <Fragment>
            {moment(params.row.DateCreated).startOf("minutes").fromNow()}
          </Fragment>
        ),
      },
      {
        field: "Toggle",
        headerName: "Autoplay Video",
        sortable: true,
        width: 180,
        renderCell: (params) => (
          <Fragment>
            <Switch
              checked={params.row.Status === "1" ? true : false}
              onChange={(e) => this.handleToggleOpen(params.row.Status)}
              inputProps={{ "aria-label": "controlled" }}
              style={{ color: "#cd3235" }}
            />
          </Fragment>
        ),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: true,
        width: 210,

        renderCell: (params) => (
          <Fragment>
            <button
              type="button"
              className="btn btn-dark mr-2"
              onClick={(e) => this.handleRedirect(params.row.Slug)}
            >
              View
            </button>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={(e) => this.handleEditRedirect(params.row.Slug)}
            >
              Update
            </button>
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleOpenModal(params.row.Id)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Live Broadcast
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12">
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleAddRedirect()}
              disabled={
                this.state.success && this.state.liveNewsList.length === 1
              }
              data-toggle="tooltip"
              data-placement="top"
              title={
                this.state.success && this.state.liveNewsList.length === 1
                  ? "Live Broadcast limit exceeded."
                  : ""
              }
            >
              Add Live Broadcast
            </button>
          </div>
          <div className="col-12 mt-4">
            <h3>Live Broadcast</h3>
          </div>
        </div>
        <div className="row">
          {this.props.liveNewsState.isLoading ? (
            <div className="col-12 text-center mt-3">
              <div
                className="spinner-border text-danger"
                role="status"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="col-12">
              <div style={{ width: "100%" }}>
                <DataGrid
                  rows={this.getRows() || []}
                  columns={columns}
                  disableColumnMenu
                  pageSize={this.state.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    this.handlePageSize(newPageSize)
                  }
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  disableSelectionOnClick={true}
                  autoHeight
                />
              </div>
            </div>
          )}
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h4>
                      Are you sure that you want to delete this live broadcast?
                    </h4>
                  </div>
                  <div className="col-12 my-4">
                    <div className="form-check">
                      <label className="form-check-label row d-flex mx-0 align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name=""
                          id="moveVideo"
                          value="checkedValue"
                          checked={this.state.moveVideo}
                          onChange={this.handleCheckBox}
                          style={{ marginTop: 2 }}
                        />
                        I want to move this video to video gallery.
                      </label>
                    </div>
                  </div>
                  <div className="col-12 my-2">
                    {this.state.spinner ? (
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.isError ? (
                      <div
                        className="alert alert-danger w-100 text-center"
                        role="alert"
                      >
                        {this.state.errorMsg}
                      </div>
                    ) : null}
                    {this.state.isValid ? (
                      <div
                        className="alert alert-success w-100 text-center"
                        role="alert"
                      >
                        {this.state.validMsg}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleDelete()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleCloseModal()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.toggleOpen}
          onClose={this.handleToggleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.toggleOpen}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h4>
                      Are you sure that you want to change the live broadcast
                      status?
                    </h4>
                  </div>
                  <div className="col-12 text-center">
                    {this.props.editState.isLoading ? (
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleSwitch()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleToggleClose()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    liveNewsState: state.liveNewsStore.liveNewsList,
    deleteState: state.liveNewsStore.deleteLiveNews,
    videoState: state.videoStore.video,
    newsState: state.liveNewsStore.liveNews,
    editState: state.liveNewsStore.editLiveNews,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllLiveNews: () => dispatch(getAllLiveNews()),
  deleteLiveNews: (id) => dispatch(deleteLiveNews(id)),
  addVideo: (data) => dispatch(addVideo(data)),
  toggleLiveNews: (data) => dispatch(toggleLiveNews(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiveNews)
);
