import React, { PureComponent, Fragment } from "react";
import {
  getAllVideos,
  deleteVideo,
} from "../../store/actions/video-state-actions";
import { refreshToken } from "../../store/actions/app-state-actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { checkUserPermission } from "../../store/actions/users-state-actions";
import { getAllAuthors } from "../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";

class ViewAllVideo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        author: "",
        title: "",
        dateFrom: "",
        dateTo: "",
      },
      videoList: [],
      success: false,
      id: 0,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      open: false,
      isLoading: true,
      pageSize: 10,
      responseMessage: "",
      showResponse: false,
      authorList: [],
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("2");
    if (!this.props.videoState.success && !this.props.videoState.isLoading) {
      this.getVideos();
    } else {
      this.setState({
        ...this.state,
        videoList: this.props.videoState.data,
        success: true,
        isLoading: false,
      });
    }

    this.props.getAllAuthors().then(() => {
      if (this.props.authorState.success && this.props.authorState.data) {
        this.setState({
          ...this.state,
          authorList:
            this.props.authorState.data.length !== 0
              ? this.props.authorState.data
              : [],
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    // if (this.props.videoState !== prevProps.videoState) {
    //   if (this.props.videoState.success) {
    //     if (this.props.videoState.status) {
    //       this.props.getAllVideos(this.props.videoState.data.length, 50);
    //     }
    //     this.setState({
    //       ...this.state,
    //       videoList: this.props.videoState.data,
    //       success: true,
    //       isLoading: false,
    //     });
    //   } else if (this.props.videoState.token === -1) {
    //     this.props.refreshToken();
    //   }
    // }

    if (this.props.deleteState !== prevProps.deleteState) {
      if (
        this.props.deleteState.success &&
        this.props.deleteState.status === 1
      ) {
        this.setState(
          {
            ...this.state,
            isLoading: true,
          },
          () => {
            this.showSuccess();
            this.getVideos();
          }
        );
      } else if (this.props.deleteState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.deleteState.isError) {
        this.showError("Failed to delete selected video.");
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        if (this.props.deleteState.status === -1) {
          this.handleDelete();
        }

        if (this.props.videoState.status === -1) {
          this.getVideos();
        }
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  getVideos() {
    this.props.getAllVideos(0, 100).then(() => {
      if (this.props.videoState.success) {
        this.setState({
          ...this.state,
          videoList: this.props.videoState.data,
          success: true,
          isLoading: false,
        });
      } else {
        this.setState({
          ...this.state,
          success: false,
          isLoading: false,
        });
      }
    });
  }

  handleDelete = () => {
    this.setState({ ...this.state, spinner: true });
    var obj = {
      id: this.state.id,
    };
    this.props.deleteVideo(obj);
  };

  handleEditRedirect = (id) => {
    this.props.history.push(`/videos/e/` + id);
  };

  handleAddRedirect = () => {
    this.props.history.push(`/videos/add`);
  };

  handleRedirect = (id) => {
    this.props.history.push(`/videos/` + id);
  };

  getRows = () => {
    var arr =
      this.state.success &&
      this.state.videoList.map((item, index) => {
        item.id = index + 1;
        return item;
      });
    return arr;
  };

  getPrevImg = (url_string) => {
    var id = "";
    var url = new URL(url_string).pathname;

    id = url.replace("/", "");
    return id;
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Video has been deleted successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          this.handleCloseModal();
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  }

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = (id) => {
    this.setState({ ...this.state, open: true, id: id });
  };

  handlePageSize = (value) => {
    this.setState({ ...this.state, pageSize: value });
  };

  filterAuthor = (item) => {
    if (this.state.data.author !== "0" && this.state.data.author !== "") {
      return item.Author.toLowerCase().includes(
        this.state.data.author.toLowerCase()
      );
    } else {
      return item;
    }
  };

  filterTitle = (item) => {
    if (this.state.data.title !== "") {
      return item.Title.toLowerCase().includes(
        this.state.data.title.toLowerCase()
      );
    } else {
      return item;
    }
  };

  filterDate = (item) => {
    if (this.state.data.dateFrom !== "" && this.state.data.dateTo !== "") {
      return (
        moment(item.DateCreated).utc().format("YYYY-MM-DD") >=
          moment(this.state.data.dateFrom).utc().format("YYYY-MM-DD") &&
        moment(item.DateCreated).utc().format("YYYY-MM-DD") <=
          moment(this.state.data.dateTo).utc().format("YYYY-MM-DD")
      );
    } else {
      return item;
    }
  };

  handleReset = () => {
    this.setState({
      data: {
        ...this.state.data,
        author: "",
        title: "",
        dateFrom: "",
        dateTo: "",
      },
      videoList: this.props.videoState.data,
      search: false,
    });
  };

  checkDateRange = () => {
    let today = new Date();
    if (
      this.state.data.dateFrom.length !== 0 &&
      this.state.data.dateTo.length !== 0
    ) {
      if (
        moment(this.state.data.dateTo).utc().format("YYYY-MM-DD") <
        moment(this.state.data.dateFrom).utc().format("YYYY-MM-DD")
      ) {
        return false;
      }
    }

    return true;
  };

  checkDates = () => {
    if (this.state.data.dateFrom === "" && this.state.data.dateTo !== "") {
      return false;
    }

    if (this.state.data.dateFrom !== "" && this.state.data.dateTo === "") {
      return false;
    }

    return true;
  };

  handleSubmit = () => {
    if (this.checkSearchParameters()) {
      if (!this.checkDates()) {
        this.setState(
          {
            responseMessage:
              "You need to insert value in both (Date From) and (Date To) fields to search records by date range.",
            showResponse: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                responseMessage: "",
                showResponse: false,
              });
            }, 2500);
          }
        );
        return false;
      }
      if (!this.checkDateRange()) {
        this.setState(
          {
            responseMessage:
              "(Date From) value cannot be greater than (Date To) value.",
            showResponse: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                responseMessage: "",
                showResponse: false,
              });
            }, 2500);
          }
        );
        return false;
      }

      var items = this.props.videoState.data
        .filter(this.filterAuthor)
        .filter(this.filterTitle)
        .filter(this.filterDate);

      this.setState({ videoList: items });
    }
  };

  checkSearchParameters() {
    for (var key in this.state.data) {
      if (
        this.state.data[key] !== "" &&
        this.state.data[key] !== "0" &&
        this.state.data[key] !== null
      ) {
        return true;
      }
    }
    return false;
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [id]: value,
      },
    });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 100 },
      {
        field: "URL",
        headerName: "Thumbnail",
        width: 150,
        renderCell: (params) => (
          <Fragment>
            <div
              className="video-list-img mt-4 mb-4"
              style={{
                backgroundImage: `url(https://img.youtube.com/vi/${this.getPrevImg(
                  params.row.URL
                )}/0.jpg)`,
              }}
            ></div>

            {/* <img src={this.getPrevImg(params.row.URL)} height="60" /> */}
          </Fragment>
        ),
      },
      {
        field: "Title",
        headerName: "Video Title",
        width: 250,
        sortable: true,
      },
      {
        field: "Author",
        headerName: "Author",
        sortable: true,
        width: 150,
      },
      {
        field: "DateCreated",
        headerName: "Date Created",
        sortable: true,
        width: 180,
        valueFormatter: (params) => {
          return moment(params.row.DateCreated).format("DD-MM-YYYY");
        },
      },
      {
        field: "action",
        headerName: "Action",
        sortable: true,
        width: 250,
        renderCell: (params) => (
          <Fragment>
            <button
              type="button"
              className="btn btn-dark mr-2"
              onClick={(e) => this.handleRedirect(params.row.Id)}
            >
              View
            </button>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={(e) => this.handleEditRedirect(params.row.Id)}
            >
              Update
            </button>
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleOpenModal(params.row.Id)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Videos
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12">
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleAddRedirect()}
            >
              Add Video
            </button>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={this.state.data.title}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  placeholder="Title"
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="author">Author</label>
                <select
                  className="form-control"
                  id="author"
                  value={this.state.data.author}
                  onChange={this.handleChange}
                >
                  <option value={0}>Please Select</option>
                  {this.state.authorList &&
                    this.state.authorList
                      .sort((a, b) => a.FirstName.localeCompare(b.FirstName))
                      .map((author, index) => {
                        return (
                          <option
                            key={index}
                            value={author.FirstName + " " + author.LastName}
                          >
                            {author.FirstName + " " + author.LastName}
                          </option>
                        );
                      })}
                </select>
              </div>

              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="title">Date From</label>
                <input
                  type="date"
                  className="form-control"
                  id="dateFrom"
                  value={this.state.data.dateFrom}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="title">Date To</label>
                <input
                  type="date"
                  className="form-control"
                  id="dateTo"
                  value={this.state.data.dateTo}
                  onChange={this.handleChange}
                  aria-describedby="helpId"
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>
              <div className="col-12 mt-3 flex-row d-flex justify-content-end">
                <button
                  type="button"
                  className="btn primary_btn mr-2"
                  onClick={() => this.handleSubmit()}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => this.handleReset()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 d-flex justify-content-center">
            <div className="mt-3 text-center">
              {this.state.showResponse ? (
                <div className="alert alert-danger mt-3 mb-3" role="alert">
                  {this.state.responseMessage}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12 mt-4 mb-4">
            <h3>View All Videos</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ width: "100%" }}>
              {this.state.isLoading ? (
                <div className="row d-flex justify-content-center">
                  <div
                    className="spinner-border text-danger"
                    role="status"
                    style={{ width: "3rem", height: "3rem" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <DataGrid
                  rows={this.getRows() || []}
                  columns={columns}
                  disableColumnMenu
                  pageSize={this.state.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    this.handlePageSize(newPageSize)
                  }
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  disableSelectionOnClick={true}
                  autoHeight
                />
              )}
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h4>Are you sure that you want to delete this video?</h4>
                  </div>
                  <div className="col-12 my-2">
                    {this.state.spinner ? (
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.isError ? (
                      <div
                        className="alert alert-danger w-100 text-center"
                        role="alert"
                      >
                        {this.state.errorMsg}
                      </div>
                    ) : null}
                    {this.state.isValid ? (
                      <div
                        className="alert alert-success w-100 text-center"
                        role="alert"
                      >
                        {this.state.validMsg}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 d-flex justify-content-end my-2">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleDelete()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleCloseModal()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    videoState: state.videoStore.videos,
    deleteState: state.videoStore.deleteVideo,
    appState: state.appStore.refreshToken,
    authorState: state.usersStore.authors,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllVideos: (from, to) => dispatch(getAllVideos(from, to)),
  deleteVideo: (from) => dispatch(deleteVideo(from)),
  refreshToken: () => dispatch(refreshToken()),
  getAllAuthors: () => dispatch(getAllAuthors()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewAllVideo)
);
