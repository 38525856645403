import React, { PureComponent, Fragment } from "react";
import "./index.css";

import Citizen from "../../img/logo02.png";
import { userVerify } from "../../store/actions/app-state-actions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Verify extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.match.params.email,
      token: this.props.match.params.token,
      validMsg: "",
      isValid: false,
      spinner: true,
      errorMsg: "",
      isError: false,
      redirect: "",
    };
  }

  componentDidMount() {
    var formData = {
      email: this.state.email,
      token: this.state.token,
    };

    this.props.userVerify(formData).then(() => {
      if (this.props.verifyState.success) {
        this.setState({
          ...this.state,
          validMsg: this.props.verifyState.message,
          isValid: true,
          spinner: false,
          errorMsg: "",
          isError: false,
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            validMsg: "",
            isValid: false,
            spinner: false,
            errorMsg: "",
            isError: false,
            redirect: "/reset-password/" + this.props.verifyState.data.email,
          });
        }, 2500);
      } else {
        this.setState({
          ...this.state,
          validMsg: "",
          isValid: false,
          spinner: false,
          errorMsg: this.props.verifyState.message,
          isError: true,
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            validMsg: "",
            isValid: false,
            spinner: false,
            errorMsg: "",
            isError: false,
          });
        }, 2500);
      }
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <Fragment>
        <div className="login-wrapper">
          <div className="row d-flex align-items-center w-100">
            <div className="col-12 col-sm-8 text-center d-flex justify-content-center align-items-center mb-3">
              <img className="citizen-logo" src={Citizen} alt="logo" />
            </div>
            <div className="col-12 col-sm-4">
              <div className="card login-card">
                <div className="card-body">
                  <p className="login-text-01">User Verification</p>
                  <p className="login-text-02">
                    Please wait while your identification has been confirmed.
                  </p>

                  <div className="row">
                    <div className="col-12 mt-3">
                      {this.state.isValid ? (
                        <div className="alert alert-success" role="alert">
                          {this.state.validMsg}
                        </div>
                      ) : this.state.isError ? (
                        <div className="alert alert-danger" role="alert">
                          {this.state.errorMsg}
                        </div>
                      ) : this.state.spinner ? (
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    verifyState: state.appStore.verify,
  };
};

const mapDispatchToProps = (dispatch) => ({
  userVerify: (data) => dispatch(userVerify(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
