import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_GET_ALL_INFO,
  ACTION_ADD_INFO,
  ACTION_GET_INFO_BY_ID,
  ACTION_EDIT_INFO,
  ACTION_DELETE_INFO,
} from "../../constants/actions";

const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const getAllInfo = () => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_INFO,
    data: {},
    status: false,
    token: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getAllInfo(accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        token: res.data.status,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const addInfo = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_ADD_INFO,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.addInfo(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getInfoById = (id) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_INFO_BY_ID,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getInfoById(id, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const editInfo = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_EDIT_INFO,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.editInfo(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const deleteInfo = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_DELETE_INFO,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.deleteInfo(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const toggleInformationBoard = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_EDIT_INFO,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.toggleInformationBoard(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
