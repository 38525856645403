import {
  ACTION_AUTH_LOGIN,
  ACTION_REFRESH_TOKEN,
  ACTION_INITIAL_PASSWORD,
  ACTION_FORGOT_PASSWORD,
  ACTION_SEND_MAIL,
  ACTION_VERIFY_USER,
  ACTION_RESET_PASSWORD,
  ACTION_CHANGE_PASSWORD,
  ACTION_UPDATE_PROFILE,
} from "../../constants/actions";

const initialState = {
  login: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  session: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  refreshToken: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
    status: 0,
  },
  initial: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  forgot: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  mail: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    status: 0,
    data: {},
  },
  verify: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  reset: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  changePassword: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editProfile: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};

export default function appStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_AUTH_LOGIN: {
      _state = {
        ...state,
        login: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
        session: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_REFRESH_TOKEN: {
      _state = {
        ...state,
        refreshToken: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_INITIAL_PASSWORD: {
      _state = {
        ...state,
        initial: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_FORGOT_PASSWORD: {
      _state = {
        ...state,
        forgot: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_SEND_MAIL: {
      _state = {
        ...state,
        mail: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_VERIFY_USER: {
      _state = {
        ...state,
        verify: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_RESET_PASSWORD: {
      _state = {
        ...state,
        reset: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_CHANGE_PASSWORD: {
      _state = {
        ...state,
        changePassword: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_UPDATE_PROFILE: {
      _state = {
        ...state,
        editProfile: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
