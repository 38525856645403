import React, { PureComponent, Fragment } from "react";
import { refreshToken } from "../../store/actions/app-state-actions";
import {
  getAllCategory,
  addCategory,
  editCategory,
  deleteCategory,
} from "../../store/actions/category-state-action";
import ViewAllTags from "../Tags/index";

import { DataGrid } from "@mui/x-data-grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";
import { checkUserPermission } from "../../store/actions/users-state-actions";

class ViewAllCategory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      open: false,
      success: false,
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      add: {
        name: "",
        type: "",
      },
      isAdd: false,
      edit: {
        name: "",
        type: "",
      },
      isEdit: false,
      id: 0,
      modalIsError: false,
      modalErrorMsg: "",
      modalIsValid: false,
      modalValidMsg: "",
      modalSpinner: false,
      isLoading: true,
      pageSize: 10,
      errors: {
        name: "",
        type: "",
      },
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.getAllCategory();
    this.props.checkUserPermission("10");
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success && this.props.appState.status === 1) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        if (this.props.deleteState.status === -1) {
          this.handleDelete();
        }

        if (this.props.editState.status === -1) {
          this.handleEditCategory();
        }

        if (this.props.addState.status === -1) {
          this.handleAddCategory();
        }

        if (this.props.categoryListState.status === -1) {
          this.props.getAllCategory();
        }
      } else if (
        this.props.appState.success &&
        this.props.appState.status === -1
      ) {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }

    if (prevProps.deleteState !== this.props.deleteState) {
      if (
        this.props.deleteState.success &&
        this.props.deleteState.status === 1
      ) {
        this.setState(
          { ...this.state, open: false, modalSpinner: false, spinner: false },
          () => {
            this.props.getAllCategory();
            this.showSuccessModal("Category has been deleted successfully.");
            this.handleCloseModal();
          }
        );
      } else if (this.props.deleteState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.deleteState.isError) {
        this.setState(
          { ...this.state, modalSpinner: false, spinner: false },
          () => {
            this.showErrorModal("Failed to delete category.");
          }
        );
      }
    }

    if (prevProps.editState !== this.props.editState) {
      if (this.props.editState.success && this.props.editState.status === 1) {
        this.setState(
          { ...this.state, success: false, isLoading: true },
          () => {
            this.props.getAllCategory();
            this.showSuccessModal("Category successfully updated.");
          }
        );
      } else if (this.props.editState.status === -1) {
        this.props.refreshToken();
      }
      // else if (
      //   !this.props.editState.isLoading &&
      //   this.props.editState.isError
      // ) {
      //   this.showErrorModal("Failed to update category!");
      // }
      else if (
        !this.props.editState.isLoading &&
        this.props.editState.isError &&
        this.props.editState.status === 0
      ) {
        this.showErrorModal(this.props.editState.message);
      }
    }

    if (prevProps.addState !== this.props.addState) {
      if (this.props.addState.success && this.props.addState.status === 1) {
        this.setState(
          {
            ...this.state,
            add: { ...this.state.add, name: "", type: "" },
            success: false,
            isLoading: true,
          },
          () => {
            this.props.getAllCategory();
            this.showSuccessModal("New category successfully added.");
          }
        );
      } else if (this.props.addState.status === -1) {
        this.props.refreshToken();
      }
      // else if (
      //   !this.props.addState.isLoading &&
      //   this.props.addState.isError
      // ) {
      //   this.showErrorModal("Failed to add new category!");
      // }
      else if (
        !this.props.addState.isLoading &&
        this.props.addState.isError &&
        this.props.addState.status === 0
      ) {
        this.showErrorModal(this.props.addState.message);
      }
    }

    if (prevProps.categoryListState !== this.props.categoryListState) {
      if (
        this.props.categoryListState.success &&
        this.props.categoryListState.status === 1
      ) {
        this.setState({
          ...this.state,
          categoryList: this.props.categoryListState.data,
          success: true,
          isLoading: false,
        });
      } else if (this.props.categoryListState.status === -1) {
        this.props.refreshToken();
      }
    }
  }

  showSuccess(msg) {
    this.setState(
      {
        ...this.state,
        validMsg: msg,
        isValid: true,
        spinner: false,
        open: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
            open: false,
          });
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  }

  showSuccessModal(msg) {
    this.setState(
      {
        ...this.state,
        modalIsValid: true,
        modalValidMsg: msg,
        modalSpinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            modalValidMsg: "",
            modalIsValid: false,
            isEdit: false,
            isAdd: false,
          });
        }, 1500);
      }
    );
  }

  showErrorModal(msg) {
    this.setState(
      {
        ...this.state,
        modalErrorMsg: msg,
        modalIsError: true,
        modalSpinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            modalErrorMsg: "",
            modalIsError: false,
            // isEdit: false,
            // isAdd: false,
          });
        }, 3500);
      }
    );
  }

  handleAddModelOpen = () => {
    this.setState({ ...this.state, isAdd: true });
  };

  handleAddModelClose = () => {
    this.setState({
      ...this.state,
      add: {
        name: "",
        type: "",
      },
      isAdd: false,
    });
  };

  handleEditModelOpen = (obj) => {
    this.setState({ ...this.state, edit: obj, isEdit: true });
  };

  handleEditModelClose = () => {
    this.setState({
      ...this.state,
      edit: {
        name: "",
        type: "",
      },
      isEdit: false,
    });
  };

  getRows = () => {
    var arr =
      this.state.success &&
      this.state.categoryList &&
      this.state.categoryList.map((item, index) => {
        item.id = index + 1;
        return item;
      });
    return arr;
  };

  handleAddChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, add: { ...this.state.add, [id]: value } });
  };

  handleEditChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, edit: { ...this.state.edit, [id]: value } });
  };

  handleAddCategory = () => {
    if (this.state.add.name.length !== 0 && this.state.add.type.length !== 0) {
      var today = new Date();
      var formData = {
        name: this.state.add.name,
        type: this.state.add.type,
        dateCreated: moment(today).format("YYYY-MM-DD HH:m:s"),
      };
      this.setState(
        {
          ...this.setState,
          modalSpinner: true,
        },
        () => {
          this.props.addCategory(formData);
        }
      );
    } else {
      this.setState(
        {
          ...this.state,
          // errors: {
          //   name:
          //     this.state.add.name.length === 0
          //       ? "Category name cannot be empty!"
          //       : "",
          //   type:
          //     this.state.add.name.length === 0
          //       ? "Category type cannot be empty!"
          //       : "",
          // },
          modalErrorMsg: "Category name and category type cannot be empty.",
          modalIsError: true,
          modalSpinner: false,
        },
        () => {
          setTimeout(() => {
            this.setState({
              modalErrorMsg: "",
              modalIsError: false,
              errors: {
                name: "",
                type: "",
              },
            });
          }, 2000);
        }
      );
    }
  };

  handleEditCategory = () => {
    if (
      this.state.edit.name.length !== 0 &&
      this.state.edit.type.length !== 0
    ) {
      var formData = {
        id: this.state.edit.Id,
        name: this.state.edit.name,
        type: this.state.edit.type,
        dateCreated: this.state.edit.DateCreated,
      };
      this.setState({ ...this.setState, modalSpinner: true }, () => {
        this.props.editCategory(formData);
      });
    } else {
      this.setState(
        {
          ...this.state,
          modalErrorMsg: "Category name and category type cannot be empty.",
          modalIsError: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              modalErrorMsg: "",
              modalIsError: false,
            });
          }, 2000);
        }
      );
    }
  };

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = (id) => {
    this.setState({ ...this.state, open: true, id: id });
  };

  handleDelete = () => {
    this.setState({ ...this.state, modalSpinner: true, spinner: true });
    var obj = {
      id: this.state.id,
    };
    this.props.deleteCategory(obj).then(() => {
      if (this.props.deleteState.success) {
        this.setState({
          ...this.state,
          modalSpinner: false,
          spinner: false,
          isLoading: true,
        });
      }
    });
  };

  handlePageSize = (value) => {
    this.setState({ ...this.state, pageSize: value });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 80 },
      {
        field: "name",
        headerName: "Name",
        width: 200,
        sortable: true,
      },
      {
        field: "type",
        headerName: "Type",
        sortable: true,
        width: 150,

        renderCell: (params) => (
          <p className="m-0" style={{ textTransform: "capitalize" }}>
            {params.row.type}
          </p>
        ),
      },
      {
        field: "DateCreated",
        headerName: "Date Created",
        width: 150,
        sortable: true,
        valueFormatter: (params) => {
          return moment(params.row.DateCreated).format("DD-MM-YYYY");
        },
      },
      {
        field: "action",
        headerName: "Action",
        sortable: true,
        width: 210,

        renderCell: (params) => (
          <Fragment>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={(e) => this.handleEditModelOpen(params.row)}
            >
              Update
            </button>
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleOpenModal(params.row.Id)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Categories and Tags
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12">
            <button
              type="button"
              className="btn primary_btn"
              onClick={(e) => this.handleAddModelOpen()}
            >
              Add Category
            </button>
          </div>
          <div className="col-12 mt-4">
            <h3>View All Categories</h3>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <div style={{ width: "100%" }}>
              {this.state.isLoading ? (
                <div className="row d-flex justify-content-center">
                  <div
                    className="spinner-border text-danger"
                    role="status"
                    style={{ width: "3rem", height: "3rem" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <DataGrid
                  rows={this.getRows() || []}
                  columns={columns}
                  disableColumnMenu
                  pageSize={this.state.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    this.handlePageSize(newPageSize)
                  }
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  disableSelectionOnClick={true}
                  autoHeight
                />
              )}
            </div>
          </div>
          {/* <div className="col-12">
            {this.state.spinner ? (
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.isError ? (
              <div
                className="alert alert-danger w-100 text-center"
                role="alert"
              >
                {this.state.errorMsg}
              </div>
            ) : null}
            {this.state.isValid ? (
              <div
                className="alert alert-success w-100 text-center"
                role="alert"
              >
                {this.state.validMsg}
              </div>
            ) : null}
          </div> */}
        </div>
        <ViewAllTags />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.isAdd}
          onClose={this.handleAddModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.isAdd}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h5>Add Category</h5>
                  </div>
                  <div className="col-md-6 col-sm-12 mt-3">
                    <label htmlFor="name">Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={this.state.add.name}
                      onChange={this.handleAddChange}
                      aria-describedby="helpId"
                      placeholder="Category Name"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-3">
                    <label htmlFor="type">Type *</label>
                    <select
                      className="form-control"
                      name=""
                      id="type"
                      value={this.state.add.type}
                      onChange={this.handleAddChange}
                    >
                      <option value="">Select...</option>
                      <option value={"sinhala"}>Sinhala</option>
                      <option value={"tamil"}>Tamil</option>
                      <option value={"english"}>English</option>
                    </select>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3 mx-0">
                    {this.state.modalSpinner && (
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}

                    {this.state.modalIsError ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-danger w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalErrorMsg}
                        </div>
                      </div>
                    ) : null}

                    {this.state.modalIsValid ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-success w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalValidMsg}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 row d-flex justify-content-end mt-3 mx-0">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleAddCategory()}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleAddModelClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.isEdit}
          onClose={this.handleEditModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.isEdit}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h5>Update Category</h5>
                  </div>
                  <div className="col-md-6 col-sm-12 mt-3">
                    <label htmlFor="name">Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={this.state.edit.name}
                      onChange={this.handleEditChange}
                      aria-describedby="helpId"
                      placeholder="Category Name"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-3">
                    <label htmlFor="type">Type *</label>
                    <select
                      className="form-control"
                      name=""
                      id="type"
                      value={this.state.edit.type}
                      onChange={this.handleEditChange}
                    >
                      <option value="">Select...</option>
                      <option value={"sinhala"}>Sinhala</option>
                      <option value={"tamil"}>Tamil</option>
                      <option value={"english"}>English</option>
                    </select>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3 mx-0">
                    {this.state.modalSpinner && (
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}

                    {this.state.modalIsError ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-danger w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalErrorMsg}
                        </div>
                      </div>
                    ) : null}

                    {this.state.modalIsValid ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-success w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalValidMsg}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 row d-flex justify-content-end mt-3 mx-0">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleEditCategory()}
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleEditModelClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 my-2">
                    <h4>Are you sure that you want to delete this category?</h4>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3 mx-0">
                    {this.state.modalSpinner && (
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}

                    {this.state.modalIsError ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-danger w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalErrorMsg}
                        </div>
                      </div>
                    ) : null}

                    {this.state.modalIsValid ? (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <div
                          className="alert alert-success w-100 text-center"
                          role="alert"
                        >
                          {this.state.modalValidMsg}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 row d-flex justify-content-end mx-0">
                    <button
                      type="button"
                      className="btn primary_btn mr-2 model-btn-size"
                      onClick={(e) => this.handleDelete()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleCloseModal()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryListState: state.categoryStore.categoryList,
    editState: state.categoryStore.editcategory,
    addState: state.categoryStore.category,
    deleteState: state.categoryStore.deletecategory,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  refreshToken: () => dispatch(refreshToken()),
  getAllCategory: () => dispatch(getAllCategory()),
  addCategory: (data) => dispatch(addCategory(data)),
  editCategory: (data) => dispatch(editCategory(data)),
  deleteCategory: (id) => dispatch(deleteCategory(id)),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewAllCategory)
);
