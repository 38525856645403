import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_GET_ALL_FACT_CHECK,
  ACTION_ADD_FACT_CHECK,
  ACTION_GET_FACT_CHECK_BY_ID,
  ACTION_EDIT_FACT_CHECK,
  ACTION_DELETE_FACT_CHECK,
} from "../../constants/actions";

const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const getAllFactCheck = (from, to) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_ALL_FACT_CHECK,
    data: {},
    status: false,
    token: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getAllFactCheck(from, to, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        token: res.data.status,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const addFactCheck = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_ADD_FACT_CHECK,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.addFactCheck(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getFactCheckById = (id) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_FACT_CHECK_BY_ID,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.getFactCheckById(id, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const editFactCheck = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_EDIT_FACT_CHECK,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.editFactCheck(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const deleteFactCheck = (data) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_DELETE_FACT_CHECK,
    data: {},
    status: 0,
  };
  var accessToken = window.localStorage.getItem("accessToken");
  dispatch(actionData);
  await AppServices.deleteFactCheck(data, accessToken)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: data,
        status: res.data.status,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
