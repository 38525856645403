import React, { Fragment, PureComponent } from "react";
import {
  addLiveNews,
  getAllLiveNews,
} from "../../../store/actions/live-news-action";
import { refreshToken } from "../../../store/actions/app-state-actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactPlayer from "react-player/youtube";
import * as validator from "../validations";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
import { checkUserPermission } from "../../../store/actions/users-state-actions";

class AddLiveNews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      Title: "",
      Author: "",
      URL: "",
      Embedlink: "",
      Status: "1",
      IsSchedule: "0",
      ScheduleDateTime: "",
      errors: {
        Title: "",
        Author: "",
        URL: "",
        Embedlink: "",
      },
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      disable: false,
      isbtnDisable: false,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("4");
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (prevProps.newsState !== this.props.newsState) {
      if (this.props.newsState.success && this.props.newsState.status === 1) {
        this.showSuccess();
      } else if (this.props.newsState.status === -1) {
        this.props.refreshToken();
      } else if (this.props.newsState.isError) {
        this.showError("Failed to add live broadcast. Please try again.");
      }
    }
    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        var today = new Date();
        var userName = localStorage.getItem("name");
        var fromData = {
          title: this.state.Title,
          embedlink: this.state.Embedlink,
          url: this.state.URL,
          status: true,
          author: userName ? userName : "",
          dateCreated: moment(today).format("YYYY-MM-DD HH:m:s"),
        };
        this.props.addLiveNews(fromData);
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  isUrlValid = (url) => {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      // Do anything for being valid
      // if need to change the url to embed url then use below line
      // $('#ytplayerSide').attr('src', 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0');
      return true;
    } else {
      // Do anything for not being valid
      return false;
    }
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Live broadcast has been added successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.props.getAllLiveNews();
          this.setState({
            validMsg: "",
            isValid: false,
            isbtnDisable: false,
          });
          this.props.history.push("/livenews");
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
        disable: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
            disable: false,
            isbtnDisable: false,
          });
        }, 3500);
      }
    );
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "URL") {
      // var embedlink = value.split("/");
      // let lastElement = embedlink[embedlink.length - 1];

      // this.setState({
      //   ...this.state,
      //   [id]: value,
      //   Embedlink:
      //     "https://www.youtube.com/embed/" +
      //     lastElement.replace("watch?v=", ""),
      // });

      var embedlink = value.split("/");
      let lastElement = embedlink[embedlink.length - 1];
      var step1 = lastElement.split("=");

      if (step1[0] == "watch?v") {
        var step2 = step1[1].split("&");
        if (step2[0]) {
          this.setState({
            ...this.state,
            [id]: value,
            Embedlink: "https://www.youtube.com/embed/" + step2[0],
          });
        } else {
          this.setState({
            ...this.state,
            [id]: value,
            Embedlink:
              "https://www.youtube.com/embed/" +
              step1[0].replace("watch?v=", ""),
          });
        }
      } else {
        this.setState({
          ...this.state,
          [id]: value,
          Embedlink: "https://www.youtube.com/embed/" + lastElement,
        });
      }
    } else {
      this.setState({ ...this.state, [id]: value });
    }
  };

  handleAdd = () => {
    for (let [id, value] of Object.entries(this.state)) {
      this.validateInputAndSetState(id, value);
    }

    var isValid = validator.isErrorObjectEmpty(this.state.errors);

    if (isValid) {
      if (!this.isUrlValid(this.state.URL)) {
        this.showError("Invalid youtube url inserted!");
        return false;
      }

      if (!this.isUrlValid(this.state.Embedlink)) {
        this.showError("Invalid embedded link inserted!");
        return false;
      }

      var today = new Date();
      var userName = localStorage.getItem("name");

      if (this.state.IsSchedule === "1") {
        if (this.state.ScheduleDateTime !== "") {
          if (
            moment(this.state.ScheduleDateTime).format("YYYY-MM-DD HH:m:s") >=
            moment().format("YYYY-MM-DD HH:m:s")
          ) {
            var fromData = {
              title: this.state.Title,
              slug: this.state.Title.replace(/ /g, "-")
                .replace(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/g, "-")
                .toLowerCase(),
              embedlink: this.state.Embedlink,
              url: this.state.URL,
              status: this.state.Status,
              author: userName,
              isSchedule: this.state.IsSchedule,
              scheduleDateTime:
                this.state.IsSchedule == "1"
                  ? moment(this.state.ScheduleDateTime).format(
                      "YYYY-MM-DD HH:m:s"
                    )
                  : null,
              dateCreated: moment(today).format("YYYY-MM-DD HH:m:s"),
            };
            this.props.addLiveNews(fromData);
            this.setState({
              ...this.state,
              spinner: true,
              disable: true,
              isbtnDisable: false,
            });
          } else {
            this.showError("Please enter a valid DateTime.");
          }
        } else {
          this.showError("Schedule DateTime cannot be empty!");
        }
      } else {
        var fromData = {
          title: this.state.Title,
          slug: this.state.Title.replace(/ /g, "-")
            .replace(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/g, "-")
            .toLowerCase(),
          embedlink: this.state.Embedlink,
          url: this.state.URL,
          status: this.state.Status,
          author: userName,
          isSchedule: this.state.IsSchedule,
          scheduleDateTime:
            this.state.IsSchedule == "1"
              ? moment(this.state.ScheduleDateTime).format("YYYY-MM-DD HH:m:s")
              : null,
          dateCreated: moment(today).format("YYYY-MM-DD HH:m:s"),
        };
        this.props.addLiveNews(fromData);
        this.setState({
          ...this.state,
          spinner: true,
          disable: true,
          isbtnDisable: false,
        });
      }
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/livenews",
                    }}
                  >
                    Live Broadcast
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Add Live Broadcast
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-11">
            <h3>Add Live Broadcast</h3>
          </div>
          <div className="col-11">
            <div className="row">
              <div className="col-12 mb-3">
                <label htmlFor="Title">Broadcast Title *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Title"
                  aria-describedby="helpId"
                  placeholder="Broadcast Title"
                  value={this.state.Title}
                  onChange={this.handleChange}
                />
              </div>
              {/* <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Author">Author *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Author"
                  aria-describedby="helpId"
                  placeholder="Author"
                  value={this.state.Author}
                  onChange={this.handleChange}
                />
              </div> */}
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="URL">Youtube URL *</label>
                <input
                  type="text"
                  className="form-control"
                  id="URL"
                  aria-describedby="helpId"
                  placeholder="URL"
                  value={this.state.URL}
                  onChange={this.handleChange}
                />
              </div>
              {/* <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Embedlink">Youtube Embedded Link *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Embedlink"
                  aria-describedby="helpId"
                  placeholder="Link"
                  value={this.state.Embedlink}
                  onChange={this.handleChange}
                />
              </div> */}
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Status">Autoplay Video *</label>
                <select
                  className="form-control"
                  name=""
                  id="Status"
                  value={this.state.Status}
                  onChange={this.handleChange}
                >
                  <option value={"1"}>On</option>
                  <option value={"2"}>Off</option>
                </select>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="isSchedule">Schedule *</label>
                <select
                  className="form-control"
                  name=""
                  id="IsSchedule"
                  value={this.state.IsSchedule}
                  onChange={this.handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              {this.state.IsSchedule == "1" ? (
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="isSchedule">Schedule DateTime *</label>
                  <input
                    className="form-control"
                    id="ScheduleDateTime"
                    type="datetime-local"
                    min={moment().format("YYYY-MM-DD HH:MM")}
                    value={this.state.ScheduleDateTime}
                    onChange={this.handleChange}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center mt-4 mb-4">
                {this.state.spinner ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.isError ? (
                  <div
                    className="alert alert-danger w-100 text-center"
                    role="alert"
                  >
                    {this.state.errorMsg}
                  </div>
                ) : null}
                {this.state.isValid ? (
                  <div
                    className="alert alert-success w-100 text-center"
                    role="alert"
                  >
                    {this.state.validMsg}
                  </div>
                ) : null}
              </div>

              <div className="col-12 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn primary_btn add-btn-size"
                  onClick={(e) => this.handleAdd()}
                  disabled={this.state.disable}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                {this.state.URL.length !== 0 &&
                this.isUrlValid(this.state.URL) ? (
                  <ReactPlayer
                    url={this.state.URL}
                    controls
                    width="100%"
                    height="480px"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsState: state.liveNewsStore.liveNews,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addLiveNews: (data) => dispatch(addLiveNews(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
  getAllLiveNews: () => dispatch(getAllLiveNews()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddLiveNews)
);
