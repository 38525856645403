import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./index.css";
import {
  addFactCheck,
  getAllFactCheck,
} from "../../../store/actions/fact-check-action";
import { searchFactCheckArticle } from "../../../store/actions/fact-check-article-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import * as validator from "../validations";
import moment from "moment";
import { Link } from "react-router-dom";
// import MarkdownIt from "markdown-it";
// import MdEditor, { Plugins } from "react-markdown-editor-lite";
// import style manually
// import "react-markdown-editor-lite/lib/index.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageCompression from "browser-image-compression";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import {
  getAllGalleryImages,
  saveGalleryImage,
} from "../../../store/actions/gallery-state-actions";
import Pagination from "react-responsive-pagination";
import { WEB_BASE_URL, API_BASE_URL } from "../../../../src/constants/api";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";

class AddFactCheck extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      factCheck: {
        Author: "Gillian Maloney",
        Title: "",
        Description: "",
        Origin: "",
        Verification: "",
        parentArticle: "",
        Source: "",
        DateCreated: "",
        Status: "1",
        Thumbnail: "",
        ThumbnailImage: "",
        IsSchedule: "0",
        ScheduleDateTime: "",
        Language: this.props.match.params.type,
      },
      errors: {
        Title: "",
        Source: "",
        Status: "",
        Description: "",
        Origin: "",
        Verification: "",
        DateCreated: "",
        Thumbnail: "",
      },
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      viewImage: "",
      articleImg: "",
      imgName: "",
      open: false,
      searchList: [],
      isSearching: false,
      parentId: 0,
      isbtnDisable: false,
      imageModal: false,
      blobFile: "",
      src: "",
      croppedImageUrl: "",
      cropper: null,
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      webWorker: {
        progress: null,
        inputSize: null,
        outputSize: null,
        inputUrl: null,
        outputUrl: null,
      },
      mainThread: {
        progress: null,
        inputSize: null,
        outputSize: null,
        inputUrl: null,
        outputUrl: null,
      },
      imageGalleryModal: false,
      addImageToGallery: false,
      modalGallerySpinner: true,
      galleryImage: [],
      galleryBlobFile: "",
      gallerySrc: "",
      galleryCroppedImageUrl: "",
      galleryCropper: null,
      galleryImgName: "",
      galleryFile: "",
      gallerySpinner: false,
      galleryResponseMessage: "",
      galleryImageShowResponse: false,
      galleryIsImageError: false,
      copyImageUrl: "",
      pageNumber: 1,
    };

    this.handleEditorChange1 = this.handleEditorChange1.bind(this);
    this.handleImageUpload1 = this.handleImageUpload1.bind(this);
    this.handleEditorChange2 = this.handleEditorChange2.bind(this);
    this.handleImageUpload2 = this.handleImageUpload2.bind(this);
    this.handleEditorChange3 = this.handleEditorChange3.bind(this);
    this.handleImageUpload3 = this.handleImageUpload3.bind(this);
    this.handleCloseImageModal = this.handleCloseImageModal.bind(this);
    this.handleSaveImageModal = this.handleSaveImageModal.bind(this);
    this.handleAddImageModal = this.handleAddImageModal.bind(this);
    this.handleSaveGalleryImageModal =
      this.handleSaveGalleryImageModal.bind(this);
    this.handleGalleryImageModal = this.handleGalleryImageModal.bind(this);
    this.handleCloseGalleryImageModal =
      this.handleCloseGalleryImageModal.bind(this);
    this.getImageUrl = this.getImageUrl.bind(this);
    this.getPageCount = this.getPageCount.bind(this);
    this.getSlice = this.getSlice.bind(this);
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.props.checkUserPermission("6");
    this.getAllGalleryImages();
    // var userName = localStorage.getItem("name");
    // if (userName) {
    //   this.setState({
    //     ...this.state,
    //     factCheck: {
    //       ...this.state.factCheck,
    //       Author: userName,
    //     },
    //   });
    // }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (prevProps.addState !== this.props.addState) {
      if (this.props.addState.success && this.props.addState.status === 1) {
        this.showSuccess();
      } else if (
        this.props.addState.success &&
        this.props.addState.status === -1
      ) {
        this.props.refreshToken();
      } else if (this.props.addState.isError) {
        this.showError("Failed to add new fact check. Please try again.");
      }
    }
    if (prevProps.searchState !== this.props.searchState) {
      if (
        this.props.searchState.success &&
        this.props.searchState.status === 1
      ) {
        this.setState({
          ...this.state,
          searchList: this.props.searchState.data,
          isSearching: false,
        });
      } else if (
        this.props.searchState.success &&
        this.props.searchState.status === -1
      ) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success && this.props.appState.status === 1) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.handleAdd();
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleCloseImageModal() {
    this.setState({
      imageModal: false,
      blobFile: "",
      src: "",
      croppedImageUrl: "",
      imgName: "",
      thumbnail: "",
    });
  }
  handleAddImageModal() {
    this.setState({ imageModal: true });
  }

  handleSaveImageModal() {
    this.setState({ imageModal: false });
    const { blobFile } = this.state;
    const newImage = new File([blobFile], blobFile.name, {
      type: blobFile.type,
    });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        viewImage: this.state.croppedImageUrl,
        blobFile: "",
        src: "",
        croppedImageUrl: "",
      });
    };
    reader.readAsDataURL(newImage);
  }

  handleEditorChange1(text) {
    this.setState({
      ...this.state,
      factCheck: {
        ...this.state.factCheck,
        Description: text,
      },
    });
  }

  handleImageUpload1(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (data) => {
        resolve(data.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  handleEditorChange2(text) {
    this.setState({
      ...this.state,
      factCheck: {
        ...this.state.factCheck,
        Origin: text,
      },
    });
  }

  handleImageUpload2(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (data) => {
        resolve(data.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  handleEditorChange3(text) {
    this.setState({
      ...this.state,
      factCheck: {
        ...this.state.factCheck,
        Verification: text,
      },
    });
  }

  handleImageUpload3(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (data) => {
        resolve(data.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      factCheck: { ...this.state.factCheck, [id]: value },
    });
  };

  handleAdd = () => {
    for (let [id, value] of Object.entries(this.state.factCheck)) {
      this.validateInputAndSetState(id, value);
    }

    const isValid = validator.isErrorObjectEmpty(this.state.errors);
    if (isValid) {
      var language =
        this.props.match.params.type === "sinhala"
          ? "Sinhala"
          : this.props.match.params.type === "tamil"
          ? "Tamil"
          : "English";

      if (this.state.factCheck.IsSchedule === "1") {
        if (this.state.factCheck.ScheduleDateTime !== "") {
          if (
            moment(this.state.scheduleDateTime).format("YYYY-MM-DD HH:m:s") >=
            moment().format("YYYY-MM-DD HH:m:s")
          ) {
            var today = new Date();
            var formData = {
              author: this.state.factCheck.Author,
              title: this.state.factCheck.Title,
              slug: this.state.factCheck.Title.replace(/ /g, "-")
                .replace(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/g, "-")
                .toLowerCase(),
              source: this.state.factCheck.Source,
              description: this.state.factCheck.Description,
              origin: this.state.factCheck.Origin,
              verification: this.state.factCheck.Verification,
              status: this.state.factCheck.Status,
              dateCreated: this.state.factCheck.DateCreated,
              thumbnail: this.state.viewImage,
              thumbnailImage: this.state.imgName,
              language: language,
              isSchedule: this.state.factCheck.IsSchedule,
              scheduleDateTime:
                this.state.factCheck.IsSchedule == "1"
                  ? moment(this.state.factCheck.ScheduleDateTime).format(
                      "YYYY-MM-DD HH:m:s"
                    )
                  : null,
            };

            this.props.addFactCheck(formData);
            this.setState({ ...this.state, spinner: true, isbtnDisable: true });
          } else {
            this.showError("Please enter a valid DateTime.");
          }
        } else {
          this.showError("Schedule DateTime cannot be empty!");
        }
      } else {
        var today = new Date();
        var formData = {
          author: this.state.factCheck.Author,
          title: this.state.factCheck.Title,
          slug: this.state.factCheck.Title.replace(/ /g, "-")
            .replace(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/g, "-")
            .toLowerCase(),
          source: this.state.factCheck.Source,
          description: this.state.factCheck.Description,
          origin: this.state.factCheck.Origin,
          verification: this.state.factCheck.Verification,
          status: this.state.factCheck.Status,
          dateCreated: this.state.factCheck.DateCreated,
          thumbnail: this.state.viewImage,
          thumbnailImage: this.state.imgName,
          language: language,
          isSchedule: this.state.factCheck.IsSchedule,
          scheduleDateTime:
            this.state.factCheck.IsSchedule == "1"
              ? moment(this.state.factCheck.ScheduleDateTime).format(
                  "YYYY-MM-DD HH:m:s"
                )
              : null,
        };

        this.props.addFactCheck(formData);
        this.setState({ ...this.state, spinner: true, isbtnDisable: true });
      }
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async getImage(file) {
    await this.toBase64(file).then((res) => {
      this.setState({
        ...this.state,
        articleImg: res,
      });
    });
  }

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  handleImage = (e) => {
    let image = e.target.files[0];
    if (image) {
      const file = image.type.split("/").pop().toLowerCase();
      if (file === "jpeg" || file === "jpg" || file === "png") {
        if (image.size <= 5242880) {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            this.setState({
              ...this.state,
              src: reader.result,
              imgName: e.target.files[0].name,
              thumbnail: e.target.files[0],
              factCheck: {
                ...this.state.factCheck,
                Thumbnail: e.target.files[0],
              },
            })
          );
          reader.readAsDataURL(e.target.files[0]);
          // this.getImage(e.target.files[0]).then(() => {
          //   this.setState({
          //     ...this.state,
          //     factCheck: {
          //       ...this.state.factCheck,
          //       Thumbnail: URL.createObjectURL(e.target.files[0]),
          //       ThumbnailImage: e.target.value,
          //     },
          //     imgName: e.target.files[0].name,
          //     viewImage: e.target.files[0],
          //   });
          // });
        } else {
          this.showError("The file is too large. Allowed maximum size is 5MB.");
        }
      } else {
        this.showError(
          "This file type is not allowed. Accepted file types are: JPEG, JPG or PNG."
        );
      }
    }
  };

  getCropData = () => {
    if (this.state.cropper !== undefined && this.state.cropper !== null) {
      this.setState(
        {
          ...this.state,
          croppedImageUrl: this.state.cropper.getCroppedCanvas().toDataURL(),
          // viewImage: this.state.cropper.getCroppedCanvas().toDataURL(),
        },
        () => {
          this.setBlobFile(this.state.croppedImageUrl);
        }
      );
    }
  };
  async compressImage(file, useWebWorker) {
    const targetName = useWebWorker ? "webWorker" : "mainThread";
    this.setState((prevState) => ({
      ...prevState,
      [targetName]: {
        ...prevState[targetName],
        inputSize: (file.size / 1024 / 1024).toFixed(2),
        inputUrl: URL.createObjectURL(file),
      },
    }));

    var options = {
      maxSizeMB: this.state.maxSizeMB,
      maxWidthOrHeight: this.state.maxWidthOrHeight,
      useWebWorker,
    };
    const output = await imageCompression(file, options);

    this.setState({ ...this.state, blobFile: output });
  }
  setBlobFile = (url) => {
    var min = 100;
    var max = 10000;
    var rand = min + Math.random() * (max - min);

    if (url !== "") {
      var startIndex = url.indexOf("base64,") + 7;
      var b64 = url.substr(startIndex);
      var byteCharacters = atob(b64);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], { type: "image/jpeg" });
      blob.name = "product-" + rand + ".jpeg";
      this.compressImage(blob);
      // this.setState({ ...this.state, blobFile: blob });
    }
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Fact check has been added successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.props.getAllFactCheck(0, 1000000);
          this.setState({
            validMsg: "",
            isValid: false,
            isbtnDisable: false,
          });

          this.props.history.push("/factcheck/all");
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
        isbtnDisable: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            ...this.state,
            errorMsg: "",
            isError: false,
            errors: {
              Title: "",
              Source: "",
              Status: "",
              Description: "",
              Origin: "",
              Verification: "",
            },
          });
        }, 3500);
      }
    );
  }

  handleCloseModal = () => {
    this.setState({ ...this.state, open: false });
  };

  handleOpenModal = () => {
    if (this.state.factCheck.parentArticle.length !== 0) {
      this.setState({ ...this.state, open: true, isSearching: true });
      this.props.searchFactCheckArticle(this.state.factCheck.parentArticle);
    }
  };

  onSelectArticle = (id, title) => {
    this.setState({
      ...this.state,
      factCheck: { ...this.state.factCheck, parentArticle: title },
      parentId: id,
      open: false,
    });
  };

  handleRemoveImage = () => {
    this.setState({
      ...this.state,
      factCheck: {
        ...this.state.factCheck,
        Thumbnail: "",
        ThumbnailImage: "",
      },
      articleImg: "",
      imgName: "",
      viewImage: "",
    });
  };

  getAllGalleryImages = () => {
    this.props.getAllGalleryImages().then(() => {
      if (this.props.galleryState.success) {
        this.setState({
          ...this.state,
          modalGallerySpinner: false,
          galleryImage: this.props.galleryState.data,
        });
      }
    });
  };
  handleCloseGalleryImageModal() {
    this.setState({
      ...this.state,
      imageGalleryModal: false,
      copyImageUrl: "",
      gallerySpinner: false,
      galleryResponseMessage: "",
      galleryIsImageError: false,
      addImageToGallery: false,
      galleryBlobFile: "",
      gallerySrc: "",
      galleryCroppedImageUrl: "",
      galleryCropper: null,
      galleryImgName: "",
      galleryFile: "",
      gallerySpinner: false,
      galleryResponseMessage: "",
      galleryImageShowResponse: false,
      galleryIsImageError: false,
    });
  }
  handleGalleryImageModal() {
    this.setState({ imageGalleryModal: true });
  }
  handleSaveGalleryImageModal() {
    // this.setState({ addImageToGallery: false });
    const { galleryBlobFile } = this.state;
    const newImage = new File([galleryBlobFile], galleryBlobFile.name, {
      type: galleryBlobFile.type,
    });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        // viewImage: this.state.galleryCroppedImageUrl,
        galleryBlobFile: "",
        // gallerySrc: "",
        // galleryCroppedImageUrl: "",
      });
    };
    reader.readAsDataURL(newImage);

    var image = {
      imageName: this.state.galleryImgName
        .replace(/ /g, "-")
        .replace(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/g, "-")
        .toLowerCase(),
      imageFile: this.state.galleryCroppedImageUrl,
      createdBy: 1,
      updatedBy: 1,
    };
    this.setState({
      ...this.state,
      gallerySpinner: true,
    });
    this.props.saveGalleryImage(image).then(() => {
      if (this.props.saveGalleryState.success) {
        this.setState(
          {
            ...this.state,
            gallerySpinner: false,
            galleryResponseMessage: this.props.saveGalleryState.message,
            galleryIsImageError: false,
            modalGallerySpinner: true,
          },
          () => {
            this.getAllGalleryImages();
            setTimeout(() => {
              this.setState({
                ...this.state,
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryIsImageError: false,
                addImageToGallery: false,
                galleryBlobFile: "",
                gallerySrc: "",
                galleryCroppedImageUrl: "",
                galleryCropper: null,
                galleryImgName: "",
                galleryFile: "",
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryImageShowResponse: false,
                galleryIsImageError: false,
              });
            }, 2000);
          }
        );
      } else {
        this.setState(
          {
            ...this.state,
            gallerySpinner: false,
            galleryResponseMessage: this.props.saveGalleryState.message,
            galleryIsImageError: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryIsImageError: false,
              });
            }, 2000);
          }
        );
      }
    });
  }

  handleGalleryImage = (e) => {
    let image = e.target.files[0];
    if (image) {
      const file = image.type.split("/").pop().toLowerCase();
      if (file === "jpeg" || file === "jpg" || file === "png") {
        if (image.size <= 5242880) {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            this.setState({
              ...this.state,
              gallerySrc: reader.result,
              galleryImgName: e.target.files[0].name,
              galleryFile: e.target.files[0],
            })
          );
          reader.readAsDataURL(e.target.files[0]);

          // this.setState({
          //   ...this.state,
          //   thumbnail: e.target.files[0],
          //   imgName: e.target.files[0].name,
          //   viewImage: URL.createObjectURL(e.target.files[0]),
          //   thumbnailImage: e.target.value,
          // });
        } else {
          this.setState(
            {
              ...this.state,
              gallerySpinner: false,
              galleryResponseMessage:
                "The file is too large. Allowed maximum size is 5MB.",
              galleryIsImageError: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  gallerySpinner: false,
                  galleryResponseMessage: "",
                  galleryIsImageError: false,
                });
              }, 2000);
            }
          );
        }
      } else {
        this.setState(
          {
            ...this.state,
            gallerySpinner: false,
            galleryResponseMessage:
              "This file type is not allowed. Accepted file types are: JPEG, JPG or PNG.",
            galleryIsImageError: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryIsImageError: false,
              });
            }, 2000);
          }
        );
      }
    }
  };

  getCropGalleryImageData = () => {
    if (
      this.state.galleryCropper !== undefined &&
      this.state.galleryCropper !== null
    ) {
      this.setState(
        {
          ...this.state,
          galleryCroppedImageUrl: this.state.galleryCropper
            .getCroppedCanvas()
            .toDataURL(),
          // viewImage: this.state.cropper.getCroppedCanvas().toDataURL(),
        },
        () => {
          this.setBlobFile(this.state.galleryCroppedImageUrl);
        }
      );
    }
  };

  getImageUrl(item) {
    if (item) {
      // document.execCommand("copy");
      this.setState({
        ...this.state,
        copyImageUrl: item,
      });
    }
  }
  handleCopyUrl() {
    navigator.clipboard.writeText(this.state.copyImageUrl);
    this.setState({
      ...this.state,
      copyImageUrl: "",
    });
    this.handleCloseGalleryImageModal();
  }

  pageChange = (event, value) => {
    this.setState({ ...this.state, pageNumber: value });
  };

  getPageCount(length) {
    var count = 0;

    count = Math.ceil(length / 6);

    return count;
  }

  getSlice(pageNumber) {
    var slice = 0;
    var count = this.getPageCount(this.state.galleryImage.length);

    if (count !== 0 && pageNumber !== 0) {
      slice = pageNumber * 6;
    }

    return slice;
  }
  render() {
    const {
      Title,
      Description,
      Origin,
      Verification,
      Source,
      Status,
      Thumbnail,
      ThumbnailImage,
    } = this.state.factCheck;

    // const mdParser = new MarkdownIt({
    //   html: true,
    //   xhtmlOut: true,
    //   breaks: true,
    //   highlight: true,
    //   langPrefix: true,
    //   linkify: true,
    //   quotes: true,
    //   typographer: true,
    // });

    // const plugins = [
    //   "header",
    //   "font-bold",
    //   "font-italic",
    //   "font-underline",
    //   "font-strikethrough",
    //   "list-unordered",
    //   "list-ordered",
    //   "block-quote",
    //   "block-wrap",
    //   "block-code-inline",
    //   "table",
    //   "image",
    //   "link",
    //   "clear",
    //   "logger",
    //   "mode-toggle",
    //   "full-screen",
    //   "tab-insert",
    // ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/factcheck/all",
                    }}
                  >
                    Fact Checks
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Add Fact Check
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-11">
            <h3>Add Fact Check</h3>
          </div>
          <div className="col-11">
            <div className="row">
              <div className="col-12">
                <label htmlFor="Title">Title *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Title"
                  aria-describedby="helpId"
                  placeholder="Title"
                  value={Title}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="Author">Source *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Source"
                  aria-describedby="helpId"
                  placeholder="Source"
                  value={Source}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="Status">Status *</label>
                <select
                  className="form-control"
                  id="Status"
                  value={Status}
                  onChange={this.handleChange}
                >
                  <option value="1">Verified</option>
                  <option value="2">In Progress</option>
                  <option value="3">False/ Misleading</option>
                </select>
              </div>
              <div className="col-12 col-sm-6  mt-3">
                <label htmlFor="IsSchedule">Schedule *</label>
                <select
                  className="form-control"
                  name=""
                  id="IsSchedule"
                  value={this.state.factCheck.IsSchedule}
                  onChange={this.handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              {this.state.factCheck.IsSchedule == "1" ? (
                <div className="col-12 col-sm-6  mt-3">
                  <label htmlFor="isSchedule">Schedule DateTime *</label>
                  <input
                    className="form-control"
                    id="ScheduleDateTime"
                    type="datetime-local"
                    min={moment().format("YYYY-MM-DD HH:MM")}
                    value={this.state.factCheck.ScheduleDateTime}
                    onChange={this.handleChange}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="Status">Author *</label>
                <select
                  className="form-control"
                  id="Author"
                  value={this.state.factCheck.Author}
                  onChange={this.handleChange}
                >
                  <option value="Gillian Maloney">Gillian Maloney</option>
                  <option value="Fact Check Desk">Fact Check Desk</option>
                </select>
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="Status">Date Created *</label>

                <input
                  className="form-control"
                  id="DateCreated"
                  type="date"
                  value={this.state.factCheck.DateCreated}
                  onChange={this.handleChange}
                />
              </div>

              <div className="col-12 mt-3">
                <label htmlFor="Description">Description *</label>
                <MDEditor
                  height={500}
                  minHeights={500}
                  value={this.state.factCheck.Description}
                  onChange={this.handleEditorChange1}
                  previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                  }}
                  style={{
                    whiteSpace: "pre-wrap",
                    backgroundColor: "white",
                    color: "black",
                  }}
                />
                {/* <MdEditor
                  style={{ height: "500px" }}
                  renderHTML={(text) => mdParser.render(text)}
                  onChange={this.handleEditorChange1}
                  onImageUpload={this.handleImageUpload1}
                  canView={{ fullScreen: false }}
                  linkUrl=""
                  plugins={plugins}
                /> */}
              </div>
              <div className="col-12 mt-3">
                <label htmlFor="Origin">Origin *</label>
                <MDEditor
                  height={500}
                  minHeights={500}
                  value={this.state.factCheck.Origin}
                  onChange={this.handleEditorChange2}
                  previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                  }}
                  style={{
                    whiteSpace: "pre-wrap",
                    backgroundColor: "white",
                    color: "black",
                  }}
                />
                {/* <MdEditor
                  style={{ height: "500px" }}
                  renderHTML={(text) => mdParser.render(text)}
                  onChange={this.handleEditorChange2}
                  onImageUpload={this.handleImageUpload2}
                  canView={{ fullScreen: false }}
                  plugins={plugins}
                /> */}
              </div>
              <div className="col-12 mt-3">
                <label htmlFor="Verification">Verification *</label>
                <MDEditor
                  height={500}
                  minHeights={500}
                  value={this.state.factCheck.Verification}
                  onChange={this.handleEditorChange3}
                  previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                  }}
                  style={{
                    whiteSpace: "pre-wrap",
                    backgroundColor: "white",
                    color: "black",
                  }}
                />
                {/* <MdEditor
                  style={{ height: "500px" }}
                  renderHTML={(text) => mdParser.render(text)}
                  onChange={this.handleEditorChange3}
                  onImageUpload={this.handleImageUpload3}
                  canView={{ fullScreen: false }}
                  plugins={plugins}
                /> */}
              </div>
              <div className="col-12 col-sm-6 mt-3">
                <label htmlFor="thumbnailImage">
                  Upload Image (Cover / Thumbnail) *
                </label>
                <button
                  type="button"
                  className="btn primary_btn ml-2 mt-2"
                  onClick={this.handleAddImageModal}
                  // disabled={this.state.imageList.length > 3}
                >
                  Image Upload
                </button>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="thumbnailImage">Image Gallery </label>
                <button
                  type="button"
                  className="btn primary_btn ml-2 mt-2"
                  onClick={this.handleGalleryImageModal}
                >
                  Images
                </button>
              </div>
              <div className="col-12 col-sm-6 mt-3">
                {this.state.viewImage.length !== 0 ? (
                  <img
                    className="post-img-prev"
                    src={this.state.viewImage}
                    alt="[Image Not Available]"
                  />
                ) : null}
              </div>
              <div className="col-12 col-sm-6 mt-3"></div>
              <div className="col-12 col-sm-6 mt-3 d-flex justify-content-end">
                {Thumbnail && Thumbnail.length !== 0 ? (
                  <button
                    type="button"
                    className="btn primary_btn"
                    onClick={(e) => this.handleRemoveImage()}
                  >
                    Remove Image
                  </button>
                ) : null}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12 d-flex justify-content-center mt-4 mb-4">
                {this.state.spinner ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.isError ? (
                  <div
                    className="alert alert-danger w-100 text-center"
                    role="alert"
                  >
                    {this.state.errorMsg}
                  </div>
                ) : null}
                {this.state.isValid ? (
                  <div
                    className="alert alert-success w-100 text-center"
                    role="alert"
                  >
                    {this.state.validMsg}
                  </div>
                ) : null}
              </div>

              <div className="col-12 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn primary_btn add-btn-size"
                  onClick={(e) => this.handleAdd()}
                  disabled={this.state.isbtnDisable}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999,
          }}
          // disableBackdropClick={true}
        >
          <Fade in={this.state.open}>
            <div className="card modal-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-3">
                    <h4>
                      Search Results for "{this.state.factCheck.parentArticle}"
                    </h4>
                  </div>
                  <div className="col-12">
                    <div className="search-list-container">
                      {this.state.isSearching ? (
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      ) : this.state.searchList.length === 0 ? (
                        <div className="search-list mb-3">
                          <p className="mb-2">Search result not found!</p>
                        </div>
                      ) : (
                        this.state.searchList &&
                        this.state.searchList.length !== 0 &&
                        this.state.searchList.map((item, index) => {
                          return (
                            <div
                              className="search-list mb-3"
                              onClick={(e) =>
                                this.onSelectArticle(item.Id, item.Title)
                              }
                            >
                              <p className="mb-2">{item.Title}</p>
                              <small>
                                {moment(item.DateCreated).format("DD-MM-YYYY")}
                              </small>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3">
                    <button
                      type="button"
                      className="btn btn-secondary model-btn-size"
                      onClick={(e) => this.handleCloseModal()}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        <Fragment>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.imageModal}
            onClose={this.handleCloseImageModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999999,
            }}
            disableBackdropClick={true}
          >
            <Fade in={this.state.imageModal}>
              <div className="card modal-card">
                <div className="card-body pb-0">
                  <div className="row mx-0">
                    <div className="col-10 p-0">
                      <h4> Image Upload</h4>
                    </div>
                    <div className="col-2 p-0" align="center">
                      <button
                        type="button"
                        className="close"
                        onClick={this.handleCloseImageModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>

                  <div
                    className="card-body"
                    style={{
                      overflowY: "auto",
                      overflowX: "auto",
                      height: `${this.state.src ? "460px" : "auto"}`,
                    }}
                  >
                    <div className="w-100 mx-0">
                      <input
                        className="w-100"
                        type="file"
                        accept="image/*"
                        //  value={this.state.thumbnailImage}
                        onChange={this.handleImage}
                      />
                      <p style={{ fontSize: "13px" }}>
                        Aspect ratio: 1:1
                        <small className="form-text text-secondary mb-1">
                          Max file size: 5 MB
                        </small>
                      </p>
                    </div>
                    <div className="row mt-3 mx-0">
                      <div className="col-md-6 p-1">
                        {this.state.src && (
                          <Cropper
                            style={{ height: 400, width: "100%" }}
                            zoomTo={0.1}
                            initialAspectRatio={16 / 16}
                            preview=".img-preview"
                            src={this.state.src}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            aspectRatio={16 / 16}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                              this.setState({
                                ...this.state,
                                cropper: instance,
                              });
                            }}
                            guides={true}
                          />
                        )}
                      </div>
                      <div className="col-md-6 p-1">
                        {this.state.croppedImageUrl && (
                          <img
                            alt="Crop"
                            style={{
                              maxWidth: "100%",
                              objectFit: "contain",
                            }}
                            src={this.state.croppedImageUrl}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 text-center">
                    {this.state.imageShowResponse && this.state.isImageError ? (
                      <div
                        className="alert alert-danger d-flex justify-content-center text-center mt-3 mb-3"
                        role="alert"
                      >
                        {this.state.responseMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="modal-footer p-0">
                    <div className="row d-flex justify-content-end mx-1 my-2">
                      <button
                        type="button"
                        className="btn btn-secondary mx-1"
                        onClick={this.handleCloseImageModal}
                      >
                        Close
                      </button>
                      {this.state.src && (
                        <button
                          type="button"
                          className="btn btn-success mx-1"
                          onClick={this.getCropData}
                        >
                          Crop
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-primary mx-1"
                        onClick={this.handleSaveImageModal}
                        disabled={this.state.croppedImageUrl === ""}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </Fragment>
        <Fragment>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.imageGalleryModal}
            onClose={this.handleCloseGalleryImageModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999999,
            }}
            disableBackdropClick={true}
          >
            <Fade in={this.state.imageGalleryModal}>
              <div className="card modal-card">
                <div className="card-body pb-0">
                  <div className="row mx-0">
                    <div className="col-10 p-0">
                      <h4> Gallery Management </h4>
                    </div>
                    <div className="col-2 p-0" align="center">
                      <button
                        type="button"
                        className="close"
                        onClick={this.handleCloseGalleryImageModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  {!this.state.addImageToGallery ? (
                    <Fragment>
                      <div
                        className="card-body"
                        style={{
                          overflowY: "auto",
                          overflowX: "auto",
                          height: `${this.state.src ? "460px" : "auto"}`,
                        }}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <a
                              className="btn btn-warning btn-sm lp-btn"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  addImageToGallery: true,
                                });
                              }}
                            >
                              Add Image
                            </a>
                          </div>

                          <div className="col-md-12  ">
                            {this.state.modalGallerySpinner && (
                              <div className="col-12 mb-2 d-flex justify-content-center">
                                <div
                                  className="spinner-border text-danger"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            )}
                            {!this.state.modalGallerySpinner &&
                              this.state.galleryImage.length <= 0 && (
                                <p class="d-flex justify-content-center my-3">
                                  There are no images.
                                </p>
                              )}

                            <div className="row mx-0">
                              {this.state.galleryImage.length > 0 &&
                                this.state.galleryImage
                                  .slice(
                                    this.getSlice(this.state.pageNumber) - 6,
                                    this.getSlice(this.state.pageNumber)
                                  )
                                  .map((item, index) => {
                                    return (
                                      <div className="col-md-2 img-col p-2">
                                        <div
                                          class="img-col-div"
                                          onClick={() =>
                                            this.getImageUrl(
                                              API_BASE_URL + item.path
                                            )
                                          }
                                        >
                                          <img
                                            src={API_BASE_URL + item.path}
                                            width="100%"
                                            height="100%"
                                            style={{ objectFit: "contain" }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                            </div>
                            <div className="row mx-0 d-flex justify-content-end">
                              <Pagination
                                current={this.state.pageNumber}
                                total={this.getPageCount(
                                  this.state.galleryImage.length
                                )}
                                onPageChange={(e) => {
                                  this.setState({
                                    ...this.state,
                                    pageNumber: e,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="row d-flex justify-content-center">
                              <div className="col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="copyImageUrl"
                                  value={this.state.copyImageUrl}
                                  disabled={true}
                                />
                              </div>
                              <div className="col-md-12 d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-warning mt-3"
                                  disabled={this.state.copyImageUrl === ""}
                                  onClick={() => this.handleCopyUrl()}
                                >
                                  Click me to copy current Url
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}

                  {this.state.addImageToGallery ? (
                    <Fragment>
                      <div
                        className="card-body"
                        style={{
                          overflowY: "auto",
                          overflowX: "auto",
                          height: `${this.state.src ? "460px" : "auto"}`,
                        }}
                      >
                        <div className="w-100 mx-0">
                          <input
                            className="w-100"
                            type="file"
                            accept="image/*"
                            //  value={this.state.thumbnailImage}
                            onChange={this.handleGalleryImage}
                          />
                          <p style={{ fontSize: "13px" }}>
                            Aspect ratio: 1:1
                            <small className="form-text text-secondary mb-1">
                              Max file size: 5 MB
                            </small>
                          </p>
                        </div>
                        <div className="row mt-3 mx-0">
                          <div className="col-md-6 p-1">
                            {this.state.gallerySrc && (
                              <Cropper
                                style={{ height: 400, width: "100%" }}
                                zoomTo={0.1}
                                // initialAspectRatio={16 / 16}
                                preview=".img-preview"
                                src={this.state.gallerySrc}
                                viewMode={1}
                                minCropBoxHeight={1024}
                                minCropBoxWidth={1024}
                                cropBoxResizable={false}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                // aspectRatio={16 / 16}
                                checkOrientation={false}
                                onInitialized={(instance) => {
                                  this.setState({
                                    ...this.state,
                                    galleryCropper: instance,
                                  });
                                }}
                                guides={true}
                              />
                            )}
                          </div>
                          <div
                            className="col-md-6 p-1"
                            style={{
                              overflow: "auto",
                              height: "auto",
                              maxHeight: "404px",
                            }}
                          >
                            {this.state.galleryCroppedImageUrl && (
                              <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                }}
                                src={this.state.galleryCroppedImageUrl}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 mt-3 text-center">
                        {this.state.gallerySpinner && (
                          <div className="col-12 mb-2 d-flex justify-content-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                        {!this.state.gallerySpinner &&
                        !this.state.galleryIsImageError &&
                        this.state.galleryResponseMessage ? (
                          <div
                            className="alert alert-success d-flex justify-content-center text-center mt-3 mb-3"
                            role="alert"
                          >
                            {this.state.galleryResponseMessage}
                          </div>
                        ) : (
                          ""
                        )}
                        {!this.state.gallerySpinner &&
                        this.state.galleryIsImageError ? (
                          <div
                            className="alert alert-danger d-flex justify-content-center text-center mt-3 mb-3"
                            role="alert"
                          >
                            {this.state.galleryResponseMessage}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="modal-footer p-0">
                        <div className="row d-flex justify-content-end mx-1 my-2">
                          <button
                            type="button"
                            className="btn btn-secondary mx-1"
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                addImageToGallery: false,
                              });
                            }}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary mx-1"
                            onClick={this.handleCloseGalleryImageModal}
                          >
                            Close
                          </button>
                          {this.state.gallerySrc && (
                            <button
                              type="button"
                              className="btn btn-success mx-1"
                              onClick={this.getCropGalleryImageData}
                            >
                              Crop
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn btn-primary mx-1"
                            onClick={this.handleSaveGalleryImageModal}
                            disabled={this.state.galleryCroppedImageUrl === ""}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Fade>
          </Modal>
        </Fragment>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addState: state.factCheckStore.factCheck,
    appState: state.appStore.refreshToken,
    sessionState: state.appStore.session,
    searchState: state.factCheckArticleStore.searchList,
    checkUserPermissionState: state.usersStore.checkUserPermission,
    galleryState: state.galleryStore.galleryImages,
    saveGalleryState: state.galleryStore.galleryImage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addFactCheck: (data) => dispatch(addFactCheck(data)),
  refreshToken: () => dispatch(refreshToken()),
  searchFactCheckArticle: (data) => dispatch(searchFactCheckArticle(data)),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
  getAllFactCheck: (from, to) => dispatch(getAllFactCheck(from, to)),
  getAllGalleryImages: () => dispatch(getAllGalleryImages()),
  saveGalleryImage: (data) => dispatch(saveGalleryImage(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddFactCheck)
);
