const checkMinLength = (value, length) => value.length === length;

// const checkMaxLength = (value, length) => value.length > length;

// const validEmailRegex = RegExp(
//   /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
// );
// const checkNumber = RegExp("[0-9]");

export const isErrorObjectEmpty = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => {
    if (val.length > 0) {
      valid = false;
    }
  });
  return valid;
};

export const validateInputs = (id, value, _errors) => {
  let errors = _errors;
  if (id === "Section") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Section cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Thumbnail") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Image cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  // if (id === "URL") {
  //   if (checkMinLength(value, 0)) {
  //     errors[id] = "URL cannot be empty!";
  //   } else {
  //     errors[id] = "";
  //   }
  // }

  if (id === "DateExpire") {
    if (value === "") {
      errors[id] = "Expire date cannot be empty!";
    } else {
      errors[id] = "";
    }
  }
  return errors;
};
