import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageCompression from "browser-image-compression";
// import { Link } from "react-router-dom";
import {
  getAllGalleryImages,
  saveGalleryImage,
} from "../../store/actions/gallery-state-actions";
import Pagination from "react-responsive-pagination";
import { checkIfJwtTokenValid } from "../../utils/jwt-token";
import { WEB_BASE_URL, API_BASE_URL } from "../../../src/constants/api";
class GalleryManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageGalleryModal: false,
      addImageToGallery: false,
      modalGallerySpinner: true,
      galleryImage: [],
      galleryBlobFile: "",
      gallerySrc: "",
      galleryCroppedImageUrl: "",
      galleryCropper: null,
      galleryImgName: "",
      galleryFile: "",
      gallerySpinner: false,
      galleryResponseMessage: "",
      galleryImageShowResponse: false,
      galleryIsImageError: false,
      copyImageUrl: "",
      pageNumber: 1,
    };
    this.handleSaveGalleryImageModal =
      this.handleSaveGalleryImageModal.bind(this);
    this.handleGalleryImageModal = this.handleGalleryImageModal.bind(this);
    this.handleCloseGalleryImageModal =
      this.handleCloseGalleryImageModal.bind(this);
    this.getImageUrl = this.getImageUrl.bind(this);
    this.getPageCount = this.getPageCount.bind(this);
    this.getSlice = this.getSlice.bind(this);
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    this.getAllGalleryImages();
  }

  getAllGalleryImages = () => {
    this.props.getAllGalleryImages().then(() => {
      if (this.props.galleryState.success) {
        this.setState({
          ...this.state,
          modalGallerySpinner: false,
          galleryImage: this.props.galleryState.data,
        });
      }
    });
  };

  async compressImage(file, useWebWorker) {
    const targetName = useWebWorker ? "webWorker" : "mainThread";
    this.setState((prevState) => ({
      ...prevState,
      [targetName]: {
        ...prevState[targetName],
        inputSize: (file.size / 1024 / 1024).toFixed(2),
        inputUrl: URL.createObjectURL(file),
      },
    }));

    var options = {
      maxSizeMB: this.state.maxSizeMB,
      maxWidthOrHeight: this.state.maxWidthOrHeight,
      useWebWorker,
    };
    const output = await imageCompression(file, options);

    this.setState({ ...this.state, blobFile: output });
  }
  setBlobFile = (url) => {
    var min = 100;
    var max = 10000;
    var rand = min + Math.random() * (max - min);

    if (url !== "") {
      var startIndex = url.indexOf("base64,") + 7;
      var b64 = url.substr(startIndex);
      var byteCharacters = atob(b64);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], { type: "image/jpeg" });
      blob.name = "product-" + rand + ".jpeg";
      this.compressImage(blob);
      // this.setState({ ...this.state, blobFile: blob });
    }
  };
  handleCloseGalleryImageModal() {
    this.setState({
      ...this.state,
      imageGalleryModal: false,
      copyImageUrl: "",
      gallerySpinner: false,
      galleryResponseMessage: "",
      galleryIsImageError: false,
      addImageToGallery: false,
      galleryBlobFile: "",
      gallerySrc: "",
      galleryCroppedImageUrl: "",
      galleryCropper: null,
      galleryImgName: "",
      galleryFile: "",
      gallerySpinner: false,
      galleryResponseMessage: "",
      galleryImageShowResponse: false,
      galleryIsImageError: false,
    });
  }
  handleGalleryImageModal() {
    this.setState({ imageGalleryModal: true });
  }
  handleSaveGalleryImageModal() {
    // this.setState({ addImageToGallery: false });
    const { galleryBlobFile } = this.state;
    const newImage = new File([galleryBlobFile], galleryBlobFile.name, {
      type: galleryBlobFile.type,
    });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        // viewImage: this.state.galleryCroppedImageUrl,
        galleryBlobFile: "",
        // gallerySrc: "",
        // galleryCroppedImageUrl: "",
      });
    };
    reader.readAsDataURL(newImage);

    var image = {
      imageName: this.state.galleryImgName
        .replace(/ /g, "-")
        .replace(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/g, "-")
        .toLowerCase(),
      imageFile: this.state.galleryCroppedImageUrl,
      createdBy: 1,
      updatedBy: 1,
    };
    this.setState({
      ...this.state,
      gallerySpinner: true,
    });
    this.props.saveGalleryImage(image).then(() => {
      if (this.props.saveGalleryState.success) {
        this.setState(
          {
            ...this.state,
            gallerySpinner: false,
            galleryResponseMessage: this.props.saveGalleryState.message,
            galleryIsImageError: false,
            modalGallerySpinner: true,
          },
          () => {
            this.getAllGalleryImages();
            this.handleCloseGalleryImageModal();
            setTimeout(() => {
              this.setState({
                ...this.state,
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryIsImageError: false,
                addImageToGallery: false,
                galleryBlobFile: "",
                gallerySrc: "",
                galleryCroppedImageUrl: "",
                galleryCropper: null,
                galleryImgName: "",
                galleryFile: "",
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryImageShowResponse: false,
                galleryIsImageError: false,
              });
            }, 2000);
          }
        );
      } else {
        this.setState(
          {
            ...this.state,
            gallerySpinner: false,
            galleryResponseMessage: this.props.saveGalleryState.message,
            galleryIsImageError: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryIsImageError: false,
              });
            }, 2000);
          }
        );
      }
    });
  }

  handleGalleryImage = (e) => {
    let image = e.target.files[0];
    if (image) {
      const file = image.type.split("/").pop().toLowerCase();
      if (file === "jpeg" || file === "jpg" || file === "png") {
        if (image.size <= 5242880) {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            this.setState({
              ...this.state,
              gallerySrc: reader.result,
              galleryImgName: e.target.files[0].name,
              galleryFile: e.target.files[0],
            })
          );
          reader.readAsDataURL(e.target.files[0]);

          // this.setState({
          //   ...this.state,
          //   thumbnail: e.target.files[0],
          //   imgName: e.target.files[0].name,
          //   viewImage: URL.createObjectURL(e.target.files[0]),
          //   thumbnailImage: e.target.value,
          // });
        } else {
          this.setState(
            {
              ...this.state,
              gallerySpinner: false,
              galleryResponseMessage:
                "The file is too large. Allowed maximum size is 5MB.",
              galleryIsImageError: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  gallerySpinner: false,
                  galleryResponseMessage: "",
                  galleryIsImageError: false,
                });
              }, 2000);
            }
          );
        }
      } else {
        this.setState(
          {
            ...this.state,
            gallerySpinner: false,
            galleryResponseMessage:
              "This file type is not allowed. Accepted file types are: JPEG, JPG or PNG.",
            galleryIsImageError: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                ...this.state,
                gallerySpinner: false,
                galleryResponseMessage: "",
                galleryIsImageError: false,
              });
            }, 2000);
          }
        );
      }
    }
  };

  getCropGalleryImageData = () => {
    if (
      this.state.galleryCropper !== undefined &&
      this.state.galleryCropper !== null
    ) {
      this.setState(
        {
          ...this.state,
          galleryCroppedImageUrl: this.state.galleryCropper
            .getCroppedCanvas()
            .toDataURL(),
          // viewImage: this.state.cropper.getCroppedCanvas().toDataURL(),
        },
        () => {
          this.setBlobFile(this.state.galleryCroppedImageUrl);
        }
      );
    }
  };

  getImageUrl(item) {
    if (item) {
      // document.execCommand("copy");
      this.setState({
        ...this.state,
        copyImageUrl: item,
      });
    }
  }
  handleCopyUrl() {
    navigator.clipboard.writeText(this.state.copyImageUrl);
    this.setState({
      ...this.state,
      copyImageUrl: "",
    });
    this.handleCloseGalleryImageModal();
  }

  pageChange = (event, value) => {
    this.setState({ ...this.state, pageNumber: value });
  };

  getPageCount(length) {
    var count = 0;

    count = Math.ceil(length / 24);

    return count;
  }

  getSlice(pageNumber) {
    var slice = 0;
    var count = this.getPageCount(this.state.galleryImage.length);

    if (count !== 0 && pageNumber !== 0) {
      slice = pageNumber * 24;
    }

    return slice;
  }
  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Gallery Management
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12 col-sm-4 mb-3 mb-sm-0">
            <button
              type="button"
              className="btn primary_btn ml-2 mt-2"
              onClick={this.handleGalleryImageModal}
            >
              Add Images
            </button>
          </div>

          <div className="col-12 col-sm-12 ">
            <div className="col-md-12  ">
              {this.state.modalGallerySpinner && (
                <div className="col-12 mb-2 d-flex justify-content-center">
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {!this.state.modalGallerySpinner &&
                this.state.galleryImage.length <= 0 && (
                  <p class="d-flex justify-content-center my-3">
                    There are no images.
                  </p>
                )}

              <div className="row mx-0">
                {this.state.galleryImage.length > 0 &&
                  this.state.galleryImage
                    .slice(
                      this.getSlice(this.state.pageNumber) - 24,
                      this.getSlice(this.state.pageNumber)
                    )
                    .map((item, index) => {
                      return (
                        <div className="col-md-2 img-col p-2">
                          <div
                            class="img-col-div"
                            onClick={() =>
                              this.getImageUrl(API_BASE_URL + item.path)
                            }
                          >
                            <img
                              src={API_BASE_URL + item.path}
                              width="100%"
                              height="100%"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      );
                    })}
              </div>
              <div className="row mx-0 d-flex justify-content-end">
                <Pagination
                  current={this.state.pageNumber}
                  total={this.getPageCount(this.state.galleryImage.length)}
                  onPageChange={(e) => {
                    this.setState({
                      ...this.state,
                      pageNumber: e,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Fragment>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.imageGalleryModal}
            onClose={this.handleCloseGalleryImageModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999999,
            }}
            disableBackdropClick={true}
          >
            <Fade in={this.state.imageGalleryModal}>
              <div className="card modal-card">
                <div className="card-body pb-0">
                  <div className="row mx-0">
                    <div className="col-10 p-0">
                      <h4> Gallery Management </h4>
                    </div>
                    <div className="col-2 p-0" align="center">
                      <button
                        type="button"
                        className="close"
                        onClick={this.handleCloseGalleryImageModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>

                  <Fragment>
                    <div
                      className="card-body"
                      style={{
                        overflowY: "auto",
                        overflowX: "auto",
                        height: `${this.state.src ? "460px" : "auto"}`,
                      }}
                    >
                      <div className="w-100 mx-0">
                        <input
                          className="w-100"
                          type="file"
                          accept="image/*"
                          //  value={this.state.thumbnailImage}
                          onChange={this.handleGalleryImage}
                        />
                        <p style={{ fontSize: "13px" }}>
                          Aspect ratio: 1:1
                          <small className="form-text text-secondary mb-1">
                            Max file size: 5 MB
                          </small>
                        </p>
                      </div>
                      <div className="row mt-3 mx-0">
                        <div className="col-md-6 p-1">
                          {this.state.gallerySrc && (
                            <Cropper
                              style={{ height: 400, width: "100%" }}
                              zoomTo={0.1}
                              // initialAspectRatio={16 / 16}
                              preview=".img-preview"
                              src={this.state.gallerySrc}
                              viewMode={1}
                              minCropBoxHeight={1024}
                              minCropBoxWidth={1024}
                              cropBoxResizable={false}
                              background={false}
                              responsive={true}
                              autoCropArea={1}
                              // aspectRatio={16 / 16}
                              checkOrientation={false}
                              onInitialized={(instance) => {
                                this.setState({
                                  ...this.state,
                                  galleryCropper: instance,
                                });
                              }}
                              guides={true}
                            />
                          )}
                        </div>
                        <div
                          className="col-md-6 p-1"
                          style={{
                            overflow: "auto",
                            height: "auto",
                            maxHeight: "404px",
                          }}
                        >
                          {this.state.galleryCroppedImageUrl && (
                            <img
                              alt="Crop"
                              style={{
                                maxWidth: "100%",
                                objectFit: "contain",
                              }}
                              src={this.state.galleryCroppedImageUrl}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3 text-center">
                      {this.state.gallerySpinner && (
                        <div className="col-12 mb-2 d-flex justify-content-center">
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                      {!this.state.gallerySpinner &&
                      !this.state.galleryIsImageError &&
                      this.state.galleryResponseMessage ? (
                        <div
                          className="alert alert-success d-flex justify-content-center text-center mt-3 mb-3"
                          role="alert"
                        >
                          {this.state.galleryResponseMessage}
                        </div>
                      ) : (
                        ""
                      )}
                      {!this.state.gallerySpinner &&
                      this.state.galleryIsImageError ? (
                        <div
                          className="alert alert-danger d-flex justify-content-center text-center mt-3 mb-3"
                          role="alert"
                        >
                          {this.state.galleryResponseMessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="modal-footer p-0">
                      <div className="row d-flex justify-content-end mx-1 my-2">
                        <button
                          type="button"
                          className="btn btn-secondary mx-1"
                          onClick={this.handleCloseGalleryImageModal}
                        >
                          Close
                        </button>
                        {this.state.gallerySrc && (
                          <button
                            type="button"
                            className="btn btn-success mx-1"
                            onClick={this.getCropGalleryImageData}
                          >
                            Crop
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-primary mx-1"
                          onClick={this.handleSaveGalleryImageModal}
                          disabled={this.state.galleryCroppedImageUrl === ""}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Fragment>
                </div>
              </div>
            </Fade>
          </Modal>
        </Fragment>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    galleryState: state.galleryStore.galleryImages,
    saveGalleryState: state.galleryStore.galleryImage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAllGalleryImages: () => dispatch(getAllGalleryImages()),
  saveGalleryImage: (data) => dispatch(saveGalleryImage(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GalleryManagement);
