import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addInfo } from "../../../store/actions/info-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
import { Link } from "react-router-dom";
import * as validator from "../validations";
import moment from "moment";
class AddInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        title: "",
        subtitle: "",
        thumbnail: "",
        thumbnailImage: "",
        option01: "",
        value01: "",
        option02: "",
        value02: "",
        option03: "",
        value03: "",
        option04: "",
        value04: "",
      },
      errors: {
        title: "",
        subtitle: "",
        thumbnail: "",
        option01: "",
        value01: "",
        option02: "",
        value02: "",
        option03: "",
        value03: "",
        option04: "",
        value04: "",
      },
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      viewImage: "",
      articleImg: "",
      imgName: "",
      isbtnDisable: false,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addState !== this.props.addState) {
      if (this.props.addState.success && this.props.addState.status === 1) {
        this.showSuccess();
      } else if (
        this.props.addState.success &&
        this.props.addState.status === -1
      ) {
        this.props.refreshToken();
      } else if (
        !this.props.addState.isLoading &&
        this.props.addState.isError
      ) {
        this.showError("Failed to add new information. Please try again.");
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success && this.props.appState.status === 1) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.handleAdd();
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      data: { ...this.state.data, [id]: value },
    });
  };

  handleAdd = () => {
    for (let [id, value] of Object.entries(this.state.data)) {
      this.validateInputAndSetState(id, value);
    }

    const isValid = validator.isErrorObjectEmpty(this.state.errors);
    if (isValid) {
      var today = new Date();
      var fromData = {
        title: this.state.data.title,
        subtitle: this.state.data.subtitle,
        option01: this.state.data.option01,
        option02: this.state.data.option02,
        option03: this.state.data.option03,
        option04: this.state.data.option04,
        value01: this.state.data.value01,
        value02: this.state.data.value02,
        value03: this.state.data.value03,
        value04: this.state.data.value04,
        thumbnail: this.state.articleImg,
        thumbnailImage: this.state.imgName,
        dateCreated: moment(today).format("YYYY-MM-DD HH:m:s"),
      };
      this.props.addInfo(fromData);
      this.setState({ ...this.state, spinner: true, isbtnDisable: true });
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async getImage(file) {
    await this.toBase64(file).then((res) => {
      this.setState({
        ...this.state,
        articleImg: res,
      });
    });
  }

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  handleImage = (e) => {
    let image = e.target.files[0];
    if (image) {
      const file = image.type.split("/").pop().toLowerCase();
      if (file === "jpeg" || file === "jpg" || file === "png") {
        if (image.size <= 5242880) {
          this.getImage(e.target.files[0]).then(() => {
            this.setState({
              ...this.state,
              data: {
                ...this.state.data,
                thumbnail: URL.createObjectURL(e.target.files[0]),
                thumbnailImage: e.target.value,
              },
              imgName: e.target.files[0].name,
              viewImage: e.target.files[0],
            });
          });
        } else {
          this.showError("The file is too large. Allowed maximum size is 5MB.");
        }
      } else {
        this.showError(
          "This file type is not allowed. Accepted file types are: JPEG, JPG or PNG."
        );
      }
    }
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Information has been added successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
            isbtnDisable: false,
          });
          this.props.history.push("/info/all");
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
            isbtnDisable: false,
          });
        }, 3500);
      }
    );
  }

  render() {
    const {
      title,
      subtitle,
      option01,
      option02,
      option03,
      option04,
      value01,
      value02,
      value03,
      value04,
      thumbnail,
    } = this.state.data;
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/info/all",
                    }}
                  >
                    Info Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Add Information
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12 col-sm-11">
            <div className="row">
              <div className="col-12">
                <h3>Add Information</h3>
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="Title">Title ({title.length}/250) *</label>
                <textarea
                  type="text"
                  rows={3}
                  className="form-control"
                  id="title"
                  aria-describedby="helpId"
                  placeholder="Title"
                  value={title}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="subtitle">
                  Subtitle ({subtitle.length}/250) *
                </label>
                <textarea
                  type="text"
                  rows={3}
                  className="form-control"
                  id="subtitle"
                  aria-describedby="helpId"
                  placeholder="Subtitle"
                  value={subtitle}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="option01">Option 01 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="option01"
                  aria-describedby="helpId"
                  placeholder="Option 01"
                  value={option01}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="value01">Value 01 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="value01"
                  aria-describedby="helpId"
                  placeholder="Value 01"
                  value={value01}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="option02">Option 02 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="option02"
                  aria-describedby="helpId"
                  placeholder="Option 02"
                  value={option02}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="value02">Value 02 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="value02"
                  aria-describedby="helpId"
                  placeholder="Value 02"
                  value={value02}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="option03">Option 03 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="option03"
                  aria-describedby="helpId"
                  placeholder="Option 03"
                  value={option03}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="value03">Value 03 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="value03"
                  aria-describedby="helpId"
                  placeholder="Value 03"
                  value={value03}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="option04">Option 04 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="option04"
                  aria-describedby="helpId"
                  placeholder="Option 04"
                  value={option04}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-6 col-sm-3 mb-3">
                <label htmlFor="value04">Value 04 *</label>
                <input
                  type="text"
                  className="form-control"
                  id="value04"
                  aria-describedby="helpId"
                  placeholder="Value 04"
                  value={value04}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-12">
                <label htmlFor="value04">Upload Image *</label>
                <br />
                <input
                  type="file"
                  id="thumbnailImage"
                  className="form-control"
                  // value={this.state.data.ThumbnailImage}
                  onChange={this.handleImage}
                  aria-describedby="helpId"
                />
                <small id="helpId" className="form-text text-secondary ">
                  Max file size: 5 MB
                </small>
              </div>
              <div className="col-12 col-sm-6 mt-4">
                {thumbnail.length !== 0 ? (
                  <img
                    className="post-img-prev"
                    src={thumbnail}
                    alt="[Image Not Available]"
                  />
                ) : null}
              </div>
              <div className="col-12 row d-flex justify-content-end mt-3 mb-3 mx-0">
                <div className="col-12 d-flex justify-content-center mt-4 mb-4 p-0">
                  {this.state.spinner ? (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.isError ? (
                    <div className="row w-100">
                      <div
                        className="alert alert-danger w-100 text-center"
                        role="alert"
                      >
                        {this.state.errorMsg}
                      </div>
                    </div>
                  ) : null}
                  {this.state.isValid ? (
                    <div className="row w-100">
                      <div
                        className="alert alert-success text-center w-100"
                        role="alert"
                      >
                        {this.state.validMsg}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-12 d-flex justify-content-end p-0">
                  <button
                    type="button"
                    className="btn primary_btn add-btn-size"
                    onClick={(e) => this.handleAdd()}
                    disabled={this.state.isbtnDisable}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addState: state.infoStore.info,
    appState: state.appStore.refreshToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addInfo: (data) => dispatch(addInfo(data)),
  refreshToken: () => dispatch(refreshToken()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddInfo)
);
