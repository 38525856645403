import {
  ACTION_GET_ALL_FACT_CHECK,
  ACTION_ADD_FACT_CHECK,
  ACTION_GET_FACT_CHECK_BY_ID,
  ACTION_EDIT_FACT_CHECK,
  ACTION_DELETE_FACT_CHECK,
} from "../../constants/actions";

const initialState = {
  factCheckList: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    token: 0,
    status: false,
  },
  factCheck: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editFactCheck: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  deleteFactCheck: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};

export default function factCheckStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_FACT_CHECK: {
      _state = {
        ...state,
        factCheckList: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_ADD_FACT_CHECK: {
      _state = {
        ...state,
        factCheck: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
        factCheckList: {
          ...state.factCheckList,
          success: false,
          isLoading: false,
          isError: false,
        },
      };
      break;
    }

    case ACTION_GET_FACT_CHECK_BY_ID: {
      _state = {
        ...state,
        factCheck: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }

    case ACTION_EDIT_FACT_CHECK: {
      _state = {
        ...state,
        editFactCheck: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        factCheckList: {
          ...state.factCheckList,
          success: false,
          isLoading: false,
          isError: false,
        },
      };
      break;
    }

    case ACTION_DELETE_FACT_CHECK: {
      _state = {
        ...state,
        deleteFactCheck: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        factCheckList: {
          ...state.factCheckList,
          data: state.factCheckList.data.filter((x) => x.Id !== action.data),
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
