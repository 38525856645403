import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getUserById } from "../../../store/actions/users-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import moment from "moment";
import { Link } from "react-router-dom";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
class ViewUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        username: "",
        email: "",
        phone: "",
        status: "",
        dateCreated: "",
        firstName: "",
        lastName: "",
      },
      success: false,
    };
  }
  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    this.props.checkUserPermission("8");
    var user = this.props.usersState.data.filter(
      (x) => x.Id === this.props.match.params.id
    );
    if (user.length !== 0 && this.props.usersState.success) {
      this.setState({
        ...this.state,
        data: user[0],
        success: true,
      });
    } else {
      this.props.getUserById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (this.props.userState !== prevProps.userState) {
      if (this.props.userState.success && this.props.userState.status === 1) {
        this.setState({
          ...this.state,
          data: this.props.userState.data[0],
          success: true,
        });
      } else if (this.props.userState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getUserById(this.props.match.params.id);
      } else {
        // window.localStorage.removeItem("login");
        // this.props.history.push("/");
        // this.props.setStatus(false);
      }
    }
  }

  render() {
    const { username, email, phone, status, dateCreated, firstName, lastName } =
      this.state.data;
    return (
      <Fragment>
        {!this.state.success ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-sm-12 mb-3 mb-sm-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/",
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/users/all",
                      }}
                    >
                      Users
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active p-0"
                    aria-current="page"
                  >
                    View User
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-12 mb-3">
              <h3>View User Details</h3>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <p>
                <b>First Name: </b>
                {firstName}
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <p>
                <b>Last Name: </b>
                {lastName}
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <p>
                <b>Email: </b>
                {email}
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <p>
                <b>Phone Number: </b>
                {phone}
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <p>
                <b>Date Created: </b>
                {moment(dateCreated).format("DD-MM-YYYY")}
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <p>
                <b>Status: </b>
                {status === "0" ? "Inactive" : "Active"}
              </p>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usersState: state.usersStore.users,
    userState: state.usersStore.user,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserById: (data) => dispatch(getUserById(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewUser)
);
