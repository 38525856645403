import React, { PureComponent, Fragment } from "react";
import ReactPlayer from "react-player/youtube";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getLiveNewsById } from "../../../store/actions/live-news-action";
import { refreshToken } from "../../../store/actions/app-state-actions";
import moment from "moment";
import { Link } from "react-router-dom";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";

class ViewLiveNews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: { Title: "", Author: "", URL: "", DateCreated: "" },
      success: false,
    };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    this.props.checkUserPermission("4");
    var post = this.props.newsListState.data.filter(
      (x) => x.Slug === this.props.match.params.id
    );
    if (post.length !== 0 && this.props.newsListState.success) {
      this.setState({
        ...this.state,
        data: post[0],
        success: true,
      });
    } else {
      this.props.getLiveNewsById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (this.props.newsState !== prevProps.newsState) {
      if (this.props.newsState.success && this.props.newsState.status === 1) {
        this.setState({
          ...this.state,
          data: this.props.newsState.data[0],
          success: true,
        });
      } else if (this.props.newsState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getLiveNewsById(this.props.match.params.name);
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  isUrlValid = () => {
    var res = this.state.data.URL.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  };

  render() {
    const { Title, Author, DateCreated, URL, Embedlink, Status } =
      this.state.data;
    return (
      <Fragment>
        {!this.state.success ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-sm-12 mb-3 mb-sm-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/",
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/livenews",
                      }}
                    >
                      Live Broadcast
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active p-0"
                    aria-current="page"
                  >
                    View Live Broadcast
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-12">
              <h3>View Live Broadcast</h3>
            </div>
            <div className="col-md-12">
              <p className="text-wrap">
                <b>Broadcast Title:</b> {Title}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Author:</b> {Author}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Date Created:</b> {moment(DateCreated).format("DD-MM-YYYY")}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>URL:</b> <a href={URL}>{URL}</a>
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Autoplay Video:</b>{" "}
                {parseInt(Status) == 1 ? (
                  <span class="badge badge-success p-2">On</span>
                ) : null}
                {parseInt(Status) == 2 ? (
                  <span class="badge badge-danger p-2">Off</span>
                ) : null}
              </p>
            </div>
            <div className="col-md-11">
              <p className="mb-3">
                <b>Embedded Link</b>
              </p>
              {URL.length !== 0 && this.isUrlValid() ? (
                <ReactPlayer url={URL} controls width="100%" height="480px" />
              ) : null}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsListState: state.liveNewsStore.liveNewsList,
    newsState: state.liveNewsStore.liveNews,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLiveNewsById: (data) => dispatch(getLiveNewsById(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewLiveNews)
);
