const checkMinLength = (value, length) => value.length === length;

const checkMaxLength = (value, length) => value.length > length;

// const validEmailRegex = RegExp(
//   /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
// );
// const checkNumber = RegExp("[0-9]");

export const isErrorObjectEmpty = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => {
    if (val.length > 0) {
      valid = false;
    }
  });
  return valid;
};

export const validateInputs = (id, value, _errors) => {
  let errors = _errors;
  if (
    id === "username" ||
    id === "email" ||
    id === "phone" ||
    id === "password" ||
    id === "confirmPassword"
  ) {
    if (checkMinLength(value, 0)) {
      errors[id] = "Cannot be Empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "title") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Title cannot be empty!";
    } else if (checkMaxLength(value, 250)) {
      errors[id] = "Title should be less than or equal to 250 characters.";
    } else {
      errors[id] = "";
    }
  }

  if (id === "subtitle") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Subtitle cannot be empty!";
    } else if (checkMaxLength(value, 250)) {
      errors[id] = "Subtitle should be less than or equal to 250 characters.";
    } else {
      errors[id] = "";
    }
  }

  if (id === "thumbnail") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Image cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "option01") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Option 01 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "value01") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Value 01 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "option02") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Option 02 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "value02") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Value 02 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "option03") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Option 03 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "value03") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Value 03 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "option04") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Option 04 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "value04") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Value 04 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  // Edit

  if (id === "Title") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Title cannot be empty!";
    } else if (checkMaxLength(value, 250)) {
      errors[id] = "Title should be less than or equal to 250 characters.";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Subtitle") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Subtitle cannot be empty!";
    } else if (checkMaxLength(value, 250)) {
      errors[id] = "Subtitle should be less than or equal to 250 characters.";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Thumbnail") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Image cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Option01") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Option 01 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Value01") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Value 01 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Option02") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Option 02 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Value02") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Value 02 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Option03") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Option 03 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Value03") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Value 03 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Option04") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Option 04 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  if (id === "Value04") {
    if (checkMinLength(value, 0)) {
      errors[id] = "Value 04 cannot be empty!";
    } else {
      errors[id] = "";
    }
  }

  return errors;
};
