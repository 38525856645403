import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getInfoById } from "../../../store/actions/info-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import NoImg from "../../../img/no-img.jpg";
import { API_STORAGE_BASE_URL } from "../../../constants/api";
import { Link } from "react-router-dom";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";

class ViewInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Title: "",
        Subtitle: "",
        Option01: "",
        Option02: "",
        Option03: "",
        Option04: "",
        Value01: "",
        Value02: "",
        Value03: "",
        Value04: "",
        Thumbnail: "",
      },
      success: false,
    };
  }
  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    var post = this.props.infoListState.data.filter(
      (x) => x.Id === this.props.match.params.id
    );
    if (post.length !== 0 && this.props.infoListState.success) {
      this.setState({
        ...this.state,
        data: post[0],
        success: true,
      });
    } else {
      this.props.getInfoById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.infoListState !== prevProps.infoListState) {
      if (
        this.props.infoListState.success &&
        this.props.infoListState.status === 1
      ) {
        this.setState({
          ...this.state,
          data: this.props.infoListState.data[0],
          success: true,
        });
      } else if (this.props.infoListState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getInfoById(this.props.match.params.Id);
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  render() {
    const {
      Title,
      Subtitle,
      Option01,
      Option02,
      Option03,
      Option04,
      Value01,
      Value02,
      Value03,
      Value04,
      Thumbnail,
    } = this.state.data;
    return (
      <Fragment>
        {!this.state.success ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-sm-12 mb-3 mb-sm-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/",
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item p-0">
                    <Link
                      to={{
                        pathname: "/info/all",
                      }}
                    >
                      Info Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active p-0"
                    aria-current="page"
                  >
                    View Information
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-12 mb-3">
              <h3>View Information</h3>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Title: </b>
                {Title}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-wrap">
                <b>Subtitle: </b>
                {Subtitle}
              </p>
            </div>
            <div className="col-md-3">
              <p className="text-wrap">
                <b>Option 01: </b>
                {Option01}
              </p>
            </div>
            <div className="col-md-3">
              <p className="text-wrap">
                <b>Value 01: </b>
                {Value01}
              </p>
            </div>
            <div className="col-md-3">
              <p className="text-wrap">
                <b>Option 02: </b>
                {Option02}
              </p>
            </div>
            <div className="col-md-3">
              <p className="text-wrap">
                <b>Value 02: </b>
                {Value02}
              </p>
            </div>
            <div className="col-md-3">
              <p className="text-wrap">
                <b>Option 03: </b>
                {Option03}
              </p>
            </div>
            <div className="col-md-3">
              <p className="text-wrap">
                <b>Value 03: </b>
                {Value03}
              </p>
            </div>
            <div className="col-md-3">
              <p className="text-wrap">
                <b>Option 04: </b>
                {Option04}
              </p>
            </div>
            <div className="col-md-3">
              <p className="text-wrap">
                <b>Value 04: </b>
                {Value04}
              </p>
            </div>
            <div className="col-md-12">
              {this.state.success ? (
                <img
                  className="post-img-prev"
                  src={Thumbnail ? API_STORAGE_BASE_URL + Thumbnail : NoImg}
                  alt="Article"
                />
              ) : null}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    infoListState: state.infoStore.infos,
    infoState: state.infoStore.info,
    appState: state.appStore.refreshToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInfoById: (data) => dispatch(getInfoById(data)),
  refreshToken: () => dispatch(refreshToken()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewInfo)
);
