import {
  ACTION_GET_ALL_CATEGORY,
  ACTION_ADD_CATEGORY,
  ACTION_EDIT_CATEGORY,
  ACTION_DELETE_CATEGORY,
} from "../../constants/actions";

const initialState = {
  categoryList: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
    status: 0,
  },
  category: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  editcategory: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  deletecategory: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};

export default function categoryStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_ALL_CATEGORY: {
      _state = {
        ...state,
        categoryList: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_ADD_CATEGORY: {
      _state = {
        ...state,
        category: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
        categoryList: {
          ...state.categoryList,
          success: false,
          isLoading: false,
          isError: false,
        },
      };
      break;
    }

    case ACTION_EDIT_CATEGORY: {
      _state = {
        ...state,
        editcategory: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        categoryList: {
          ...state.categoryList,
          success: false,
          isLoading: false,
          isError: false,
        },
      };
      break;
    }

    case ACTION_DELETE_CATEGORY: {
      _state = {
        ...state,
        deletecategory: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          token: action.token,
          status: action.status,
        },
        categoryList: {
          ...state.categoryList,
          data: state.categoryList.data.filter((x) => x.Id !== action.data),
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
