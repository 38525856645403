import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  getPostById,
  getTamilPostById,
  getSinhalaPostById,
} from "../../../store/actions/post-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import Skeleton from "@material-ui/lab/Skeleton";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import NoImg from "../../../img/no-img.jpg";
import {
  API_STORAGE_BASE_URL,
  DEFUALT_IMAGE_PLACEHOLDER,
} from "../../../constants/api";
import "./index.css";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import MDEditor from "@uiw/react-md-editor";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";
class ViewPost extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { news: [], success: false };
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    this.props.checkUserPermission("1");
    if (this.props.checkUserPermissionState.data === "/access-denied") {
      this.props.history.push(this.props.checkUserPermissionState.data);
    }
    if (this.props.match.params.type === "english") {
      var post = this.props.englishPostState.data.filter(
        (x) => x.Slug === this.props.match.params.name.toString()
      );
      if (post.length !== 0 && this.props.englishPostState.success) {
        this.setState({
          ...this.state,
          news: post[0],
          success: true,
        });
      } else {
        this.props.getPostById(this.props.match.params.name);
      }
    }

    if (this.props.match.params.type === "sinhala") {
      var post = this.props.sinhalaPostsState.data.filter(
        (x) => x.Slug === this.props.match.params.name.toString()
      );
      if (post.length !== 0 && this.props.sinhalaPostsState.success) {
        this.setState({
          ...this.state,
          news: post[0],
          success: true,
        });
      } else {
        this.props.getSinhalaPostById(this.props.match.params.name);
      }
    }

    if (this.props.match.params.type === "tamil") {
      var post = this.props.tamilPostsState.data.filter(
        (x) => x.Slug === this.props.match.params.name.toString()
      );
      if (post.length !== 0 && this.props.tamilPostsState.success) {
        this.setState({
          ...this.state,
          news: post[0],
          success: true,
        });
      } else {
        this.props.getTamilPostById(this.props.match.params.name);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.name !== this.props.match.params.name) {
      this.setState({
        ...this.state,
        success: false,
      });
      var post = this.props.englishPostState.data.filter(
        (x) => x.Id === this.props.match.params.name.toString()
      );
      if (post.length !== 0 && this.props.englishPostState.success) {
        this.setState({
          ...this.state,
          news: post[0],
          success: true,
        });
      } else {
        this.props.getPostById(this.props.match.params.name);
      }
    }
    if (this.props.postState !== prevProps.postState) {
      if (this.props.postState.success && this.props.postState.status === 1) {
        this.setState({
          ...this.state,
          news: this.props.postState.data[0],
          success: true,
        });
      } else if (this.props.postState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );
        this.props.getPostById(this.props.match.params.name);
      } else {
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleRedirect = (name) => {
    this.props.history.push(`/tags/` + name);
  };

  addDefaultSrc(e) {
    e.target.src = DEFUALT_IMAGE_PLACEHOLDER;
  }

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/posts/all",
                    }}
                  >
                    Posts
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  View Post
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12 col-sm-12">
            {this.state.success ? (
              <p className="article-heading">{this.state.news.Title}</p>
            ) : (
              <Skeleton variant="rect" height={144} />
            )}
            <div className="row pl-3">
              {this.state.success ? (
                <Fragment>
                  <div className="col-sm-4 p-0 top-col">
                    <p>
                      <i
                        className="fa fa-clock-o fa-lg mr-2"
                        aria-hidden="true"
                      ></i>
                      {moment
                        .utc(this.state.news.DateCreated)
                        .local("si")
                        .startOf("seconds")
                        .fromNow()}
                    </p>
                  </div>
                </Fragment>
              ) : (
                <Skeleton
                  className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                  variant="rect"
                  height={30}
                  width={150}
                />
              )}

              {this.state.success ? (
                <Fragment>
                  <div className="col-sm-4 p-0 top-col">
                    <p className="text-wrap">
                      <i
                        className="fa fa-pencil fa-lg mr-2"
                        aria-hidden="true"
                      ></i>
                      {this.state.news.Author}
                    </p>
                  </div>
                </Fragment>
              ) : (
                <Skeleton
                  className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                  variant="rect"
                  height={30}
                  width={150}
                />
              )}
              {this.state.success ? (
                <Fragment>
                  <div className="col-sm-4 p-0 top-col">
                    <p className="text-wrap">
                      <i
                        className="fa fa-check-square fa-lg mr-2"
                        aria-hidden="true"
                      ></i>
                      {this.state.news.Source}
                    </p>
                  </div>
                </Fragment>
              ) : (
                <Skeleton
                  className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                  variant="rect"
                  height={30}
                  width={150}
                />
              )}
            </div>
            <div className="row">
              <div className="col-12">
                {this.state.success ? (
                  <p>
                    <b>Category: </b>
                    {this.state.news.Category}
                  </p>
                ) : (
                  <Skeleton
                    className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                    variant="rect"
                    height={30}
                    width={150}
                  />
                )}
              </div>
              <div className="col-12">
                {this.state.success ? (
                  <p>
                    <b>Featured: </b>
                    {this.state.news.IsFeatured === "0" ? "No" : "Yes"}
                  </p>
                ) : (
                  <Skeleton
                    className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                    variant="rect"
                    height={30}
                    width={150}
                  />
                )}
              </div>
              <div className="col-12">
                {this.state.success ? (
                  <p>
                    <b>Tags: </b>
                    {this.state.news.TagsList !== undefined &&
                    this.state.news.TagsList !== null &&
                    this.state.news.TagsList.length > 0
                      ? this.state.news.TagsList.join(", ")
                      : "-"}
                  </p>
                ) : (
                  <Skeleton
                    className="btn btn-sm tag-btn-list btn-light mr-3 mt-2 mb-2"
                    variant="rect"
                    height={30}
                    width={150}
                  />
                )}
              </div>
              <div className="col-md-12">
                {this.state.success ? (
                  <img
                    className="post-img-prev"
                    src={
                      this.state.news.Thumbnail
                        ? API_STORAGE_BASE_URL + this.state.news.Thumbnail
                        : NoImg
                    }
                    alt="[Image Not Available]"
                    onError={this.addDefaultSrc}
                  />
                ) : (
                  <Skeleton variant="rect" height={430} />
                )}
              </div>
              <div className="col-md-12 article-text text-dark">
                {this.state.success ? (
                  <Fragment>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.news.Article,
                      }}
                      style={{ lineHeight: "28px" }}
                      className="text-wrap"
                    ></div> */}
                    <MDEditor.Markdown
                      source={this.state.news.Article}
                      style={{
                        whiteSpace: "pre-wrap",
                        backgroundColor: "white",
                        color: "black",
                      }}
                    />
                    <br />
                  </Fragment>
                ) : (
                  <Skeleton variant="rect" height={284} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row my-2"></div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.postStore.viewPost,
    englishPostState: state.postStore.englishPosts,
    sinhalaPostsState: state.postStore.sinhalaPosts,
    tamilPostsState: state.postStore.tamilPosts,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostById: (slug) => dispatch(getPostById(slug)),
  getTamilPostById: (slug) => dispatch(getTamilPostById(slug)),
  getSinhalaPostById: (slug) => dispatch(getSinhalaPostById(slug)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewPost)
);
