import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Link } from "react-router-dom";
import {
  editEmployee,
  getEmployeeById,
} from "../../../store/actions/emp-state-actions";
import { refreshToken } from "../../../store/actions/app-state-actions";
import * as validator from "../validations";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import { checkUserPermission } from "../../../store/actions/users-state-actions";
import { checkIfJwtTokenValid } from "../../../utils/jwt-token";

class EditEmployee extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        userId: 0,
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        dateCreated: moment().format("YYYY-MM-DD"),
        role: "",
        language: "",
      },
      errors: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
      errorMsg: "",
      isError: false,
      validMsg: "",
      isValid: false,
      spinner: false,
      redirect: "",
      isbtnDisable: false,
      selectedLanguage: "",
      languagesArray: [],
    };

    this.checkLanguage = this.checkLanguage.bind(this);
    this.addLanguage = this.addLanguage.bind(this);
  }

  componentDidMount() {
    checkIfJwtTokenValid();
    window.scrollTo(0, 0);
    this.props.checkUserPermission("3");
    // var post = this.props.employeeState.success
    //   ? this.props.employeeState.data.filter(
    //       (x) => parseInt(x.userId) === parseInt(this.props.match.params.id)
    //     )
    //   : [];
    // if (post.length !== 0 && this.props.employeeState.success) {
    //   this.setState({
    //     ...this.state,
    //     data: post[0],
    //     languagesArray: post[0].language ? post[0].language.split(",") : [],
    //     success: true,
    //   });
    // } else {
    //   this.props.getEmployeeById(this.props.match.params.id);
    // }
    this.props.getEmployeeById(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkUserPermissionState !== prevProps.checkUserPermissionState
    ) {
      if (this.props.checkUserPermissionState.data === "/access-denied") {
        this.props.history.push(this.props.checkUserPermissionState.data);
      }
    }
    if (prevProps.editState !== this.props.editState) {
      if (this.props.editState.success && this.props.editState.status === 1) {
        this.showSuccess();
      } else if (
        this.props.editState.success &&
        this.props.editState.status === -1
      ) {
        this.props.refreshToken();
      }
      // else if (this.props.editState.isError) {
      //   this.showError("Failed to update employee. Please try again.");
      // }
      else if (
        this.props.editState.isError &&
        this.props.editState.status === 0
      ) {
        this.showError(this.props.editState.message);
      }
    }

    if (this.props.employeeState !== prevProps.employeeState) {
      if (
        this.props.employeeState.success &&
        this.props.employeeState.status === 1
      ) {
        this.setState({
          ...this.state,
          data: this.props.employeeState.data[0],
          languagesArray: this.props.employeeState.data[0].language
            ? this.props.employeeState.data[0].language.split(",")
            : [],
          success: true,
        });
      } else if (this.props.employeeState.status === -1) {
        this.props.refreshToken();
      }
    }

    if (prevProps.appState !== this.props.appState) {
      if (this.props.appState.success) {
        window.localStorage.setItem(
          "accessToken",
          this.props.appState.data.jwt
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.appState.data.refreshToken
        );

        if (this.props.employeeState.status === -1) {
          this.props.getEmployeeById(this.props.match.params.id);
        }

        if (this.props.editState.status === -1) {
          this.handleEdit();
        }
      } else {
        window.localStorage.removeItem("login");
        this.props.history.push("/");
        this.props.setStatus(false);
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      data: { ...this.state.data, [id]: value },
      languagesArray: id == "role" ? [] : this.state.languagesArray,
    });
  };

  isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  hasSpecialChar = (s) => {
    var format = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);
    if (format.test(s)) {
      return false;
    } else {
      return true;
    }
  };

  hasWhiteSpace = (s) => {
    var reWhiteSpace = new RegExp(/\s/);

    // Check for white space
    if (reWhiteSpace.test(s)) {
      //alert("Please Check Your Fields For Spaces");
      return false;
    }

    return true;
  };

  handleEdit = () => {
    for (let [id, value] of Object.entries(this.state.data)) {
      this.validateInputAndSetState(id, value);
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    var isValid = validator.isErrorObjectEmpty(this.state.errors);

    if (isValid && this.state.data.phone.replace(/ /g, "").length !== 10) {
      this.showError("Phone number must have 10 digits!");
      isValid = false;
      return false;
    } else if (isValid && !this.isNumeric(this.state.data.phone)) {
      this.showError("Phone number contains non-numeric values!");
      isValid = false;
      return false;
    } else if (isValid) {
      if (
        !this.hasWhiteSpace(this.state.data.username) ||
        !this.hasSpecialChar(this.state.data.username)
      ) {
        this.showError(
          "Username cannot contain white spaces or special characters!"
        );
        return false;
      } else if (!pattern.test(this.state.data.email)) {
        this.showError("Invalid email address!");
        return false;
      } else if (
        this.state.data.role === "Editor" &&
        this.state.languagesArray.length === 0
      ) {
        this.showError("Languages cannot be empty!");
        return false;
      } else {
        this.setState(
          {
            ...this.state,
            data: {
              ...this.state.data,
              language: this.state.languagesArray.join(","),
            },
          },
          () => {
            this.props.editEmployee(this.state.data);
            this.setState({ ...this.state, spinner: true, isbtnDisable: true });
          }
        );
      }
    } else {
      for (let [id, value] of Object.entries(this.state.errors)) {
        if (value.length !== 0) {
          this.showError(value);
          return false;
        }
      }
    }
  };

  validateInputAndSetState = (id, value) => {
    const errors = validator.validateInputs(id, value, this.state.errors);
    this.setState({ ...this.state, errors, [id]: value });
  };

  showSuccess() {
    this.setState(
      {
        ...this.state,
        validMsg: "Employee has been updated successfully.",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
            redirect: "/employees/all",
            isbtnDisable: false,
          });
          // this.props.history.push("/employees/all");
        }, 1500);
      }
    );
  }

  showError(msg) {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
            isbtnDisable: false,
          });
        }, 3500);
      }
    );
  }

  addLanguage(val) {
    if (this.state.languagesArray.length > 0) {
      var isExist = this.state.languagesArray.some((a) => a === val);
      if (!isExist) {
        this.setState({
          ...this.state,
          languagesArray: this.state.languagesArray.concat(val),
          selectedLanguage: "",
        });
      }
    } else {
      this.setState({
        ...this.state,
        languagesArray: this.state.languagesArray.concat(val),
        selectedLanguage: "",
      });
    }
  }

  removeLanguage = (val) => {
    if (val) {
      this.setState({
        ...this.state,
        languagesArray: this.state.languagesArray.filter((a) => a !== val),
        selectedLanguage: "",
      });
    }
  };

  checkLanguage(val) {
    var isExist = false;

    isExist = this.state.languagesArray.some((a) => a === val);

    return isExist;
  }

  render() {
    const { username, firstName, lastName, email, phone, role } =
      this.state.data;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-sm-12 mb-3 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item p-0">
                  <Link
                    to={{
                      pathname: "/employees/all",
                    }}
                  >
                    Employees
                  </Link>
                </li>
                <li className="breadcrumb-item active p-0" aria-current="page">
                  Update Employee
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <h3>Update Employee</h3>
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="username">Username *</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  aria-describedby="helpId"
                  placeholder="Username"
                  value={username}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="firstName">First Name *</label>
                <input
                  type="search"
                  autocomplete="false"
                  className="form-control"
                  id="firstName"
                  aria-describedby="helpId"
                  placeholder="First Name"
                  value={firstName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="lastName">Last Name *</label>
                <input
                  type="search"
                  autocomplete="false"
                  className="form-control"
                  id="lastName"
                  aria-describedby="helpId"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="email">Email *</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  aria-describedby="helpId"
                  placeholder="Email"
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="phone">Phone Number *</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  aria-describedby="helpId"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-3">
                <label htmlFor="phone">User Role *</label>
                <select
                  className="form-control"
                  id="role"
                  value={role}
                  onChange={this.handleChange}
                >
                  <option value="Editor">Editor</option>
                  <option value="Admin">Admin</option>
                  <option value="Super Admin">Super Admin</option>
                  <option value="Fact Check">Fact Check</option>
                </select>
              </div>
              {role === "Editor" ? (
                <div className="col-md-6 col-sm-12 mt-3">
                  <label htmlFor="phone">Languages *</label>
                  <select
                    className="form-control"
                    id="language"
                    value={this.state.selectedLanguage}
                    onChange={(e) => this.addLanguage(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select Languages
                    </option>
                    <option
                      value="Sinhala"
                      disabled={this.checkLanguage("Sinhala")}
                    >
                      Sinhala
                    </option>
                    <option
                      value="English"
                      disabled={this.checkLanguage("English")}
                    >
                      English
                    </option>
                    <option
                      value="Tamil"
                      disabled={this.checkLanguage("Tamil")}
                    >
                      Tamil
                    </option>
                  </select>
                </div>
              ) : null}
              {this.state.languagesArray.length > 0 && role === "Editor" ? (
                <div className="col-md-12 col-sm-12 mt-3">
                  {this.state.languagesArray.map((l, i) => (
                    <Chip
                      className="m-1"
                      label={l}
                      onDelete={(e) => this.removeLanguage(l)}
                      key={i}
                      style={{
                        backgroundColor: "#cd3235",
                        color: "white",
                      }}
                    />
                  ))}
                </div>
              ) : null}
            </div>

            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-center mt-4 mb-4">
                {this.state.spinner ? (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.isError ? (
                  <div
                    className="alert alert-danger w-100 text-center"
                    role="alert"
                  >
                    {this.state.errorMsg}
                  </div>
                ) : null}
                {this.state.isValid ? (
                  <div
                    className="alert alert-success w-100 text-center"
                    role="alert"
                  >
                    {this.state.validMsg}
                  </div>
                ) : null}
              </div>

              <div className="col-12 d-flex justify-content-end mx-0">
                <button
                  type="button"
                  className="btn primary_btn add-btn-size"
                  onClick={(e) => this.handleEdit()}
                  disabled={this.state.isbtnDisable}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employeesState: state.employeeStore.employees,
    employeeState: state.employeeStore.employee,
    editState: state.employeeStore.editEmployee,
    appState: state.appStore.refreshToken,
    checkUserPermissionState: state.usersStore.checkUserPermission,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editEmployee: (data) => dispatch(editEmployee(data)),
  getEmployeeById: (data) => dispatch(getEmployeeById(data)),
  refreshToken: () => dispatch(refreshToken()),
  checkUserPermission: (id) => dispatch(checkUserPermission(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditEmployee)
);
